
import {computed, defineComponent, useContext} from '@nuxtjs/composition-api'
import {useUserWithParams} from '@scayle/storefront-nuxt2'

import Modal from '../ui/modal/Modal.vue'
import Icon from '../ui/icons/Icon.vue'
import Signin from '~/pages/signin/index.vue'
import {useIsPubliclyOpenedPage} from '~/composables/useIsPubliclyOpenedPage'

export default defineComponent({
  name: 'AuthGuard',
  components: {
    Signin,
    Modal,
    Icon
},
  setup() {
    useUserWithParams({})
    const {$currentShop, $config, store} = useContext()
    const {isPubliclyOpenedPage} = useIsPubliclyOpenedPage()

    const isLoggedIn = computed(() => !!store.state.accessToken)

    const showErrorModal = computed(() => {
      return false
    });

    return {
      isLoggedIn,
      allowAnonymous: computed(() => $currentShop.allowAnonymous && $config?.shopSystem !== 'production' ),
      showErrorModal,
      isPubliclyOpenedPage
    }
  }
})
