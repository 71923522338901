export type PaymentMethod =
  | 'giftcard'
  | 'creditcard'
  | 'invoice'
  | 'cashondelivery'
  | 'ratepaydirectdebit'
  | 'computopsofortueberweisung'
  | 'computoppayu'
  | 'computoppayucee'
  | 'computopbancontact'
  | 'computoppostfinance'
  | 'computopideal'
  | 'computoppaydirekt'
  | 'adyenbancontact'
  | 'adyenideal'
  | 'vendoramazon'
  | 'vendormytoys'
  | 'vendorotto'
  | 'vendorzalando'
  | 'vendorbol'
  | 'klarnapaynow'
  | 'klarnainstallments'
  | 'klarnapaynowdirectbanktransfer'
  | 'klarnapaynowdirectdebit'
  | 'paypalinstant'
  | 'invoicingvendor'
  | 'paypal'
  | 'paypal_instant'
  | 'ratepay_invoice'
  | 'ratepay_directdebit'
  | 'sk_cod'
  | 'hu_cod'
  | 'ro_cod'
  | 'ee_cod'
  | 'si_cod'
  | 'lv_cod'
  | 'lt_cod'
  | 'computop_creditcard'
  | 'computop_yapital'
  | 'computop_sofortueberweisung'
  | 'computop_creditcard_visa'
  | 'computop_creditcard_mastercard'
  | 'computop_creditcard_discover'
  | 'computop_paydirekt'
  | 'computop_creditcard_diners_club_instant'
  | 'computop_payu_twisto'
  | 'adyen_ideal'
  | 'dhl_cod'
  | 'ppl_cod'
  | 'epay_giftcard'
  | 'accounting'
  | 'accounting_e2e'
  | 'klarna_paylater'
  | 'klarna_installments'
  | 'adyen_afterpay_invoice'
  | 'computop_bancontact'
  | 'hr_cod'
  | 'bg_cod'
  | 'computop_payu'
  | 'computop_postfinance'
  | 'computop_creditcard_dinersclub'
  | 'pt_cod'
  | 'gr_cod'
  | 'sa_cod'
  | 'klarna_paynow_direct_bank_transfer'
  | 'adyenMBWay'
  | 'adyen_mbway'
  | 'cz_cod'
  | 'adyen_creditcard'
  | 'it_cod'
  | 'computop_applepay'
  | 'applePay'
  | 'apple_pay'
  | 'computop_googlepay'
  | 'googlePay'
  | 'google_pay'

export enum PaymentMethods {
  giftCard = 'giftcard',
  creditCard = 'creditcard',
  invoice = 'invoice',
  cashOnDelivery = 'cashondelivery',
  ratePayDirectDebit = 'ratepaydirectdebit',
  computopSofortUeberweisung = 'computopsofortueberweisung',
  computopPayU = 'computoppayu',
  computopPayUCee = 'computoppayucee',
  computopBanContact = 'computopbancontact',
  computopPostFinance = 'computoppostfinance',
  computopIdeal = 'computopideal',
  computopPayDirekt = 'computoppaydirekt',
  adyenBanContact = 'adyenbancontact',
  adyenIdeal = 'adyenideal',
  vendorAmazon = 'vendoramazon',
  vendorMyToys = 'vendormytoys',
  vendoRotto = 'vendorotto',
  vendorZalando = 'vendorzalando',
  vendorBol = 'vendorbol',
  klarnaPayNow = 'klarnapaynow',
  klarnaInstallments = 'klarnainstallments',
  klarnaPayNowDirectBankTransfer = 'klarnapaynowdirectbanktransfer',
  klarnaPayNowDirectDebit = 'klarnapaynowdirectdebit',
  paypalInstant = 'paypalinstant',
  invoicingVendor = 'invoicingvendor',
  paypal = 'paypal',
  paypalInstant_ = 'paypal_instant',
  ratepayInvoice = 'ratepay_invoice',
  ratepayDirectDebit = 'ratepay_directdebit',
  skCod = 'sk_cod',
  huCod = 'hu_cod',
  roCod = 'ro_cod',
  eeCod = 'ee_cod',
  siCod = 'si_cod',
  lvCod = 'lv_cod',
  ltCod = 'lt_cod',
  computopCreditCard = 'computop_creditcard',
  computopYapital = 'computop_yapital',
  computopSofortUeberweisung_ = 'computop_sofortueberweisung',
  computopCreditcardVisa = 'computop_creditcard_visa',
  computopCreditcardMastercard = 'computop_creditcard_mastercard',
  computopCreditcardDiscover = 'computop_creditcard_discover',
  computopPaydirekt = 'computop_paydirekt',
  computopCreditcardDinersClubInstant = 'computop_creditcard_diners_club_instant',
  computopPayuTwisto = 'computop_payu_twisto',
  adyenIdeal_ = 'adyen_ideal',
  dhlCod = 'dhl_cod',
  plCod = 'pl_cod',
  epayGiftcard = 'epay_giftcard',
  accounting = 'accounting',
  accounting_e2e = 'accounting_e2e',
  klarnaPaylater = 'klarna_paylater',
  klarnaInstallments_ = 'klarna_installments',
  adyenAfterpayInvoice = 'adyen_afterpay_invoice',
  computopBancontact = 'computop_bancontact',
  hrCod = 'hr_cod',
  bgCod = 'bg_cod',
  computopPayu = 'computop_payu',
  computopPostfinance = 'computop_postfinance',
  computopCreditcardDinersclub = 'computop_creditcard_dinersclub',
  ptCod = 'pt_cod',
  grCod = 'gr_cod',
  saCod = 'sa_cod',
  klarnaPaynowDirectBankTransfer = 'klarna_paynow_direct_bank_transfer',
  adyenMBWay = 'adyenMBWay',
  adyenMbway = 'adyen_mbway',
  czCod = 'cz_cod',
  adyenCreditcard = 'adyen_creditcard',
  itCod = 'it_cod',
  cyCod = 'cy_cod',
  computopApplepay = 'computop_applepay',
  applePay = 'applePay',
  applePay_ = 'apple_pay',
  computopGooglepay = 'computop_googlepay',
  googlePay = 'googlePay',
  googlePay_ = 'google_pay',
}

export enum IBANValidations {
  IBAN_COUNTRY_MISMATCH = 'IBAN_COUNTRY_MISMATCH',
  INVALID_FORMAT = 'INVALID_FORMAT',
  STRING_TOO_LONG = 'STRING_TOO_LONG',
  STRING_TOO_SHORT = 'STRING_TOO_SHORT',
}
