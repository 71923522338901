// International Sizes - I(11)n Sizes

import type {VariantWithSizes} from "./product";

const sizeMap: Record<string, number> = {
  unknown: 0,
  XXXS: 1,
  'XXXS-XXS': 2,
  XXS: 3,
  'XXS-XS': 4,
  XS: 5,
  'XS-S': 6,
  S: 10,
  'S-M': 11,
  M: 22,
  'M-L': 23,
  L: 34,
  'L-XL': 50,
  XL: 100,
  'XL-XXL': 101,
  XXL: 110,
  'XXL-XXXL': 112,
  'XXL-3XL': 113,
  'XXXL': 115,
  '3XL': 118,
  'XXXL-4XL': 125,
  '3XL-4XL': 126,
  '4XL': 130,
  '5XL': 140,
  '6XL': 150,
  '7XL': 160,
  '8XL': 170
}

/**
 * size - 25
 * size - 25-26
 */
const getSize = (size: string) => {
  const [main, secondary = 0] = size.split('-').map(subPart => {
    const subPartNum = parseFloat(subPart)
    return isNaN(subPartNum) ? 0 : subPartNum
  })
  // 25-26 => 25 < 25,26 < 26
  return main + secondary * 0.01
}


export const compareI11nSizes = (sizeA: string | undefined, sizeB: string | undefined) => {
  const relativeSizeA = sizeMap[sizeA?.toUpperCase() || 'unknown'] || getSize(sizeA || '')
  const relativeSizeB = sizeMap[sizeB?.toUpperCase() || 'unknown'] || getSize(sizeB || '')

  return relativeSizeA - relativeSizeB
}

export const sortI11nSizes = (
  valueA: VariantWithSizes,
  valueB: VariantWithSizes
) => {

  return compareI11nSizes(valueA.shopSize?.label, valueB.shopSize?.label)
    || compareI11nSizes(valueA.vendorSize?.label, valueB.vendorSize?.label)
    || compareI11nSizes(valueA.length, valueB.length)
}
