export type CodPaymentProvider = `cod-${string}`
export type CardPaymentProvider = 'visa' | 'mastercard'
export type DigitalPaymentProvider = 'applepay' | 'googlepay' | 'klarna' | 'paypal'

const CardPaymentProviders: CardPaymentProvider[] = ['visa', 'mastercard']
const ContactlessPaymentProviders: DigitalPaymentProvider[] = ['applepay', 'googlepay']

export type PaymentProviders = CardPaymentProvider | CodPaymentProvider | DigitalPaymentProvider | 'giftcard' | 'payu'

export const dePaymentProviders: PaymentProviders[] = [...CardPaymentProviders, ...ContactlessPaymentProviders, 'paypal', 'klarna'];

export const czPaymentProviders: PaymentProviders[] = [...CardPaymentProviders, ...ContactlessPaymentProviders, 'paypal', 'cod-cz'];

export const atPaymentProviders: PaymentProviders[] = [...CardPaymentProviders, ...ContactlessPaymentProviders, 'paypal', 'klarna'];

export const skPaymentProviders: PaymentProviders[] = [...CardPaymentProviders, ...ContactlessPaymentProviders, 'paypal', 'cod-sk'];

export const slPaymentProviders: PaymentProviders[] = [...CardPaymentProviders, ...ContactlessPaymentProviders, 'paypal', 'cod-sl'];

export const roPaymentProviders: PaymentProviders[] = [...CardPaymentProviders, ...ContactlessPaymentProviders, 'cod-ro'];

export const huPaymentProviders: PaymentProviders[] = [...CardPaymentProviders, ...ContactlessPaymentProviders, 'paypal', 'cod-hu'];

export const hrPaymentProviders: PaymentProviders[] = [...CardPaymentProviders, ...ContactlessPaymentProviders, 'cod-hr'];

export const bgPaymentProviders: PaymentProviders[] = [...CardPaymentProviders, ...ContactlessPaymentProviders, 'cod-bg'];

export const grPaymentProviders: PaymentProviders[] = [...CardPaymentProviders, ...ContactlessPaymentProviders, 'cod-gr', 'paypal'];

export const ltPaymentProviders: PaymentProviders[] = [...CardPaymentProviders, ...ContactlessPaymentProviders, 'cod-lt', 'paypal'];

export const lvPaymentProviders: PaymentProviders[] = [...CardPaymentProviders, ...ContactlessPaymentProviders, 'cod-lv', 'paypal'];

export const plPaymentProviders: PaymentProviders[] = [...CardPaymentProviders, ...ContactlessPaymentProviders, 'cod-pl', 'paypal', 'payu'];
