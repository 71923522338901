import {Block, Mark} from './enum';

export const ComponentType = 'blok';
// eslint-disable-next-line no-redeclare
export type ComponentType = typeof ComponentType;

export const TextType = 'text';
// eslint-disable-next-line no-redeclare
export type TextType = typeof TextType;

export interface NodeAttributes {}

export interface Node {
  readonly type: Block | Mark | ComponentType | TextType;
  attrs?: NodeAttributes;
}

export interface MarkNode extends Node {}

export interface TextNode extends Node {
  type: TextType;
  text: string;
  marks?: MarkNode[];
}

interface ComponentBodyCover {
  [key: string]: any;
}

export interface ComponentBody extends ComponentBodyCover {
  _uid: string;
  component: string;
}

export interface ComponentAttributes {
  id: string;
  body: ComponentBody[];
}

export interface ComponentNode extends Node {
  type: ComponentType;
  attrs: ComponentAttributes;
}

export interface BlockNode extends Node {}

export interface BlockNodeWithContent extends BlockNode {
  content: BlockNode[];
}

// Heading
export type HeadingLevels = 1 | 2 | 3 | 4 | 5 | 6;

export interface HeadingAttributes extends NodeAttributes {
  level: HeadingLevels;
}

export interface HeadingNode extends BlockNodeWithContent {
  type: Block.HEADING;
  attrs: HeadingAttributes;
  content: TextNode[];
}

// Paragraph
export interface ParagraphNode extends BlockNodeWithContent {
  type: Block.PARAGRAPH;
  content: TextNode[];
}

// List Item
export interface ListItemNode extends BlockNodeWithContent {
  type: Block.LIST_ITEM;
  content: ParagraphNode[];
}

// Lists
export interface ListAttributes extends NodeAttributes {}

export interface ListNode extends BlockNodeWithContent {
  type: Block.OL_LIST | Block.UL_LIST;
  attrs?: ListAttributes;
  content: ListItemNode[];
}

// Ordered List
export interface OrderedListAttributes extends ListAttributes {
  order: number;
}

export interface OrderedListNode extends ListNode {
  type: Block.OL_LIST;
  attrs: OrderedListAttributes;
}

// Unordered List (Bullet List)
export interface UnorderedListNode extends ListNode {
  type: Block.UL_LIST;
}

// Quote
export interface QuoteNode extends BlockNodeWithContent {
  type: Block.QUOTE;
  content: ParagraphNode[];
}

// Code
export interface CodeAttributes extends NodeAttributes {
  class: string;
}

export interface CodeBlockNode extends BlockNodeWithContent {
  type: Block.CODE;
  attrs: CodeAttributes;
  content: TextNode[];
}

// Horizontal Rule
export interface HrNode extends BlockNode {
  type: Block.HR;
}

// Break
export interface BrNode extends BlockNode {
  type: Block.BR;
}

// Image
export interface ImageAttributes extends NodeAttributes {
  alt: string;
  src: string;
  title: string | null;
}

export interface ImageNode extends BlockNode {
  type: Block.IMAGE;
  attrs: ImageAttributes;
}

export type VoidBlockNodes = HrNode | BrNode | ImageNode;

// Bold
export interface BoldNode extends MarkNode {
  type: Mark.BOLD;
}

// Strong
export interface StrongNode extends MarkNode {
  type: Mark.STRONG;
}

// Strike
export interface StrikeNode extends MarkNode {
  type: Mark.STRIKE;
}

// Underline
export interface UnderlineNode extends MarkNode {
  type: Mark.UNDERLINE;
}

// Italic
export interface ItalicNode extends MarkNode {
  type: Mark.ITALIC;
}

// Code
export interface CodeNode extends MarkNode {
  type: Mark.CODE;
}

// Link
export type LinkTarget = '_self' | '_blank' | null;
export type LinkType = 'url' | 'story' | 'asset' | 'email';

export interface LinkAttributes {
  href: string;
  uuid: string | null;
  target: LinkTarget;
  linktype: LinkType;
  anchor: string | null
}

export interface AnchorAttributes {
  id: string
}

export interface LinkNode extends MarkNode {
  type: Mark.LINK;
  attrs: LinkAttributes;
}

export interface StyledAttributes {
  class: string;
}

export interface StyledNode extends MarkNode {
  type: Mark.STYLED;
  attrs: StyledAttributes;
}

export type TopLevelNode =
  | HeadingNode
  | ParagraphNode
  | OrderedListNode
  | UnorderedListNode
  | QuoteNode
  | CodeBlockNode
  | HrNode
  | ImageNode
  | ComponentNode;

// Document
export interface DocumentNode extends BlockNodeWithContent {
  type: Block.DOCUMENT;
  content: TopLevelNode[];
}
