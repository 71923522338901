import {computed, unref, useRoute, useStore} from '@nuxtjs/composition-api'
import {getContentName} from '~/helpers/tracking'

export default function useCommonTrackingData() {
  const route = useRoute()
  const store = useStore()

  return computed(() => {
    return ({
      content_name: getContentName(unref(route)),
      page_type: store.getters.pageType,
      page_type_id: store.getters.pageId
    })
  })
}
