import {PaymentMethods} from '~/@types/payment'
import {Locale} from '~/helpers/locale'

export const HEADER_HEIGHT = 56
export const ADDRESS_BAR_HEIGHT = 120

export const COD_PAYMENT_METHODS = [
  PaymentMethods.cashOnDelivery,
  PaymentMethods.skCod,
  PaymentMethods.huCod,
  PaymentMethods.roCod,
  PaymentMethods.eeCod,
  PaymentMethods.siCod,
  PaymentMethods.lvCod,
  PaymentMethods.ltCod,
  PaymentMethods.dhlCod,
  PaymentMethods.plCod,
  PaymentMethods.hrCod,
  PaymentMethods.bgCod,
  PaymentMethods.ptCod,
  PaymentMethods.grCod,
  PaymentMethods.saCod,
  PaymentMethods.czCod,
  PaymentMethods.itCod,
  PaymentMethods.cyCod
]

export const COD_LOCALES = [
  Locale.cs_CZ,
  Locale.sk_SK,
  Locale.sl_SI,
  Locale.ro_RO,
  Locale.hu_HU,
  Locale.hr_HR,
  Locale.lv_LV,
  Locale.lt_LT,
  Locale.bg_BG,
  Locale.el_GR,
  Locale.pl_PL
]

export const PICK_AND_RETURN_LOCALES = [
  Locale.ro_RO,
  Locale.hu_HU,
  Locale.hr_HR
]

export const GOOGLE_PAY_PAYMENT_METHODS = [
  PaymentMethods.computopGooglepay,
  PaymentMethods.googlePay,
  PaymentMethods.googlePay_
]

export const APPLE_PAY_PAYMENT_METHODS = [
  PaymentMethods.computopApplepay,
  PaymentMethods.applePay,
  PaymentMethods.applePay_
]

export const GIFTCARD_PAYMENT_METHODS = [
  PaymentMethods.giftCard,
  PaymentMethods.epayGiftcard
]

export const PAYU_PAYMENT_METHODS = [
  PaymentMethods.computopPayU,
  PaymentMethods.computopPayUCee,
  PaymentMethods.computopPayuTwisto,
  PaymentMethods.computopPayu
]

export const TOAST_COOKIE_NAME = 'ayo-toasts'
