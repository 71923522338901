export * from './type'
export {domains} from './domains'
export {type APP_KEYS, appKeys} from './app-keys'
export { type AVAILABLE_SHOP_IDS} from './availableShopIds'

import countries from './config'

export const SHOP_IDS = countries.map(c => c.shopId)

export const locales = countries.map(country => {
  return {
    parsePages: false,
    code: country.code,
    domain: country.domain,
    file: `${country.locale}.json`
    // TODO: Adding more properties here will expose them via the composable - consider as an alternative to `useContext` shopId
  }
});

export default countries
