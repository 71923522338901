import {Context} from "@nuxt/types";
import {datadogLogs} from "@datadog/browser-logs";
import {decryptRC4} from "~/helpers/encryption";
import type {MonitorHandler} from "~/@types/log";
import {getDatadogEnvironment} from "~/modules/log/util";

export const initDatadogLogs = (context: Context) => {
  if (!context.$config.DD_LOGS_TOKEN_SECURE || !context.env.PRIVATE_ENCRYPT_SIGN_KEY) {
    return;
  }

  datadogLogs.init({
    clientToken: decryptRC4(context.$config.DD_LOGS_TOKEN_SECURE, context.env.PRIVATE_ENCRYPT_SIGN_KEY),
    site: 'datadoghq.eu',
    service: 'ay-outlet-web',
    env: getDatadogEnvironment(context.$config.shopSystem),
    version: context.$config.clientVersion,
    // TODO: revert back sample rate once security is done tracking and stopping the attack - 07.11.2024
    sessionSampleRate: 100, // context.$config.isProduction ? +context.env.DD_LOGS_SESSIONS_RECORDED : 100,
    forwardErrorsToLogs: true
  })
}

export const logCriticalEvents: MonitorHandler = (context, eventName, eventOptions) => {
  if (!context.$config.DD_LOGS_TOKEN_SECURE || !context.env.PRIVATE_ENCRYPT_SIGN_KEY) {
    return;
  }

  try {
    datadogLogs.logger?.log?.(eventName, {
      ...eventOptions,
      action: eventName
    })
  }
  catch (e) {
    console.error('datadog logs sending failed', e)
  }
}
