import {NuxtAppOptions} from '@nuxt/types'
import {defineNuxtPlugin} from '@nuxtjs/composition-api'
import {Route} from 'vue-router'
import {mapTrackingDataForEvent} from '~/composables/tracking/helpers'
import {getContentName} from '~/helpers/tracking'

export default defineNuxtPlugin(({store, app}: NuxtAppOptions) => {
  app?.router?.afterEach((to: Route, from: Route) => {
    setTimeout(() => {
      if (from?.path === to?.path) {
        if (
          from?.name?.includes('deals-category-id') &&
          to?.name?.includes('deals-category-id')
        ) {
          return
        }

        if (
          from?.name?.includes('all-products-category') &&
          to?.name?.includes('all-products-category')
        ) {
          return
        }
      }

      app.$gtm.push(
        mapTrackingDataForEvent('content_view', {
          content_name: getContentName(to),
          title: (typeof document !== 'undefined' && document.title) || '',
          page_type: store?.state.pageType,
          page_type_id: store?.state.pageId,
          click_origin: from?.fullPath,
          content_client_group: store?.getters.getContentClientGroup
        })
      )
    }, 100)
  })
})
