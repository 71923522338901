import {useRoute} from '@nuxtjs/composition-api'
import {PageViewData} from '~/composables/tracking/types'

type Route = ReturnType<typeof useRoute>['value']

export const getContentName = (route: Route) => {
  if (!route) {
    return ''
  }

  if (route.name?.includes('all-products-category')) {
    return route.fullPath.replace('/all-products/', '/allProducts/')
  }

  return route.fullPath
}

export const getCustomerType = <T = 'guest' | 'existing' | 'new'>(
  user: any,
  defaultType = 'guest' as T
) => {
  if (!user) {
    return defaultType
  }

  const now = new Date()
  const userCreatedAt = new Date(user?.createdAt)

  if (isNaN(userCreatedAt.getTime())) {
    return defaultType
  }

  const msBetweenDates = Math.abs(userCreatedAt.getTime() - now.getTime())
  const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000)

  if (hoursBetweenDates < 24) {
    return 'new'
  }

  return 'existing'
}

export const getErrorCode = (error: any): number => {
  return error !== null &&
    typeof error === 'object' &&
    'response' in error &&
    error.response?.status
    ? error?.response.status
    : 500
}

export const getContentClientGroup = (
  id: string | undefined
): PageViewData['content_client_group'] => {
  switch (id) {
    case 'f':
      return 'female'
    case 'm':
      return 'male'
    case 'k':
      return 'kids'
  }
  return 'none'
}
