import vue from 'vue'
import VueSlider from 'vue-slider-component/dist-css/vue-slider-component.umd.min.js'
import ContentPage from '~/components/cms/layout/ContentPage.vue'
import PageContent from '~/components/layout/PageContent'
import VueButton from '~/components/ui/buttons/Button'

vue.component('VueSlider', VueSlider)
vue.component('PageContent', PageContent)
vue.component('ContentPage', ContentPage)
vue.component('VueButton', VueButton)
