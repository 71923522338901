import {v4} from 'uuid'

export interface IToast {
  id: string
  content: {
    title?: string
    subtitle?: string
  }
  isAutoClose?: boolean
  delayInMs?: number
  type: 'success' | 'error' | 'info'
}

export type IToastWithoutId = Omit<IToast, 'id'>

export const state = () => ({
  toasts: [] as Array<IToast>
})

type ToastsState = ReturnType<typeof state>

export const getters = {
  toasts: (state: ToastsState) => state.toasts,

  getToastByIndex: (state: ToastsState) => (index: number) => state.toasts[index],

  getLastToastId: (state: ToastsState) => state.toasts[state.toasts.length - 1]?.id || null,

  findToastById:
    (state: ToastsState) =>
      (id: string) => state.toasts.find(toast => toast.id === id),

  findToastByContent:
    (state: ToastsState) =>
      (predicate: string) =>
        state.toasts.find(toast =>
          toast.content.title?.includes(predicate) || toast.content.subtitle?.includes(predicate)
        )
}
export const mutations = {
  SET_TOASTS(state: ToastsState, toasts: Array<IToastWithoutId>) {
    state.toasts = state.toasts.concat(toasts.map(toast => ({...toast, id: v4()})))
  },
  ENQUEUE_TOAST(state: ToastsState, toast: IToastWithoutId) {
    state.toasts.push({
      ...toast,
      id: v4()
    })
  },
  DEQUEUE_TOAST(state: ToastsState) {
    state.toasts.shift()
  },
  REMOVE_TOAST_BY_ID(state: ToastsState, id: string) {
    state.toasts = state.toasts.filter(toast => toast.id !== id)
  },
  REMOVE_TOAST_BY_INDEX(state: ToastsState, index: number) {
    state.toasts = state.toasts.filter((toast, i) => i !== index)
  },
  CLEAR_TOASTS(state: ToastsState) {
    state.toasts = []
  }
}
export const actions = {
  setToasts(context: any, toasts: Array<IToastWithoutId>) {
    context.commit('SET_TOASTS', toasts)
  },
  enqueueToast(context: any, toast: IToastWithoutId) {
    return context.commit('ENQUEUE_TOAST', toast)
  },
  dequeueToast(context: any) {
    context.commit('DEQUEUE_TOAST')
  },
  removeToastById(context: any, id: string) {
    context.commit('REMOVE_TOAST_BY_ID', id)
  },
  removeToastByIndex(context: any, index: number) {
    context.commit('REMOVE_TOAST_BY_INDEX', index)
  },
  clearToasts(context: any) {
    context.commit('CLEAR_TOASTS')
  }
}
