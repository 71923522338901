
import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'Tooltip',
  props: {
    text: {
      type: String as PropType<string>,
      required: true,
    },
    position: {
      type: String as PropType<
        'top-left' | 'top' | 'bottom' | 'left' | 'right'
      >,
      default: 'bottom',
    },
    width: {
      type: String as PropType<string>,
      default: '',
    },
  },
  setup(props) {
    const tooltipPosition = computed(() => {
      let pos = ''
      switch (props.position) {
        case 'top-left':
          pos = 'bottom-[calc(100%+4px)] right-[calc(100%+4px)]'
          break
        case 'top':
          pos = 'bottom-[calc(100%+4px)] left-1/2 -translate-x-1/2'
          break
        case 'bottom':
          pos = 'top-[calc(100%+4px)] left-1/2 -translate-x-1/2'
          break
        case 'left':
          pos = 'bottom-0 right-[calc(100%+4px)]'
          break
        case 'right':
          pos = 'bottom-0 left-[calc(100%+4px)]'
          break
        default:
          pos = 'top-[calc(100%+4px)] left-1/2 -translate-x-1/2'
      }
      return pos
    })
    return {
      tooltipPosition,
    }
  },
})
