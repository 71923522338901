import Vue from 'vue'
import { createImage} from '~image'
import NuxtImg from '~image/components/nuxt-img.vue'
import NuxtPicture from '~image/components/nuxt-picture.vue'

import * as staticRuntime$70b9 from '/build/apps/aboutyou-outlet-v1/node_modules/.pnpm/@nuxt+image@0.7.1/node_modules/@nuxt/image/dist/runtime/providers/static.js'
import * as storyblokRuntime$027d from '/build/apps/aboutyou-outlet-v1/node_modules/.pnpm/@nuxt+image@0.7.1/node_modules/@nuxt/image/dist/runtime/providers/storyblok.js'
import * as aboutyouRuntime$e39c from '/build/apps/aboutyou-outlet-v1/node_modules/.pnpm/@scayle+storefront-nuxt2@7.26.5_@aboutyou+backbone@15.8.0_@nuxt+image@0.7.1_@nuxtjs+compositi_3jgvbhuy52obo7ltcpzkgnuqm4/node_modules/@scayle/storefront-nuxt2/dist/imageProviders/aboutyou/index.js'

const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "aboutyou",
  "domains": [],
  "alias": {}
}

imageOptions.providers = {
  ['static']: { provider: staticRuntime$70b9, defaults: {} },
  ['storyblok']: { provider: storyblokRuntime$027d, defaults: {"baseURL":"https://a.storyblok.com"} },
  ['aboutyou']: { provider: aboutyouRuntime$e39c, defaults: {"baseURL":"https://cdn.aboutstatic.com/"} }
}

Vue.component(NuxtImg.name, NuxtImg)
Vue.component(NuxtPicture.name, NuxtPicture)
Vue.component('NImg', NuxtImg)
Vue.component('NPicture', NuxtPicture)

export default function (nuxtContext, inject) {
  const $img = createImage(imageOptions, nuxtContext)

  if (process.static && process.server) {
    nuxtContext.beforeNuxtRender(({ nuxtState }) => {
      const ssrData = nuxtState.data[0] || {}
      ssrData._img = nuxtState._img || {}
    })
  }

  inject('img', $img)
}
