export const domains = {
  de: process.env.DOMAIN_DE_DE!,
  at: process.env.DOMAIN_DE_AT!,
  cz: process.env.DOMAIN_CZ!,
  sk: process.env.DOMAIN_SK!,
  hu: process.env.DOMAIN_HU!,
  ro: process.env.DOMAIN_RO!,
  sl: process.env.DOMAIN_SI!,
  si: process.env.DOMAIN_SI!,
  hr: process.env.DOMAIN_HR!,
  bg: process.env.DOMAIN_BG!,
  el: process.env.DOMAIN_GR!,
  pl: process.env.DOMAIN_PL!,
  lt: process.env.DOMAIN_LT!,
  lv: process.env.DOMAIN_LV!,
}
