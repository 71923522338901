
import {defineComponent, PropType, ref} from '@nuxtjs/composition-api'
import {Blok} from "~/@types/cms/blok";
import {useCmsMarginsMixin} from "~/composables/cms/useCmsMarginsMixin";

export default defineComponent({
  props: {
    blok: {
      type: Object as PropType<Blok & {entries: any}>,
      default: () => {}
    },
    hasLinkList: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const selectedIndex = ref(-1)

    const handleChangeAccordion = (newOpenIndex: number) => {
      selectedIndex.value = newOpenIndex
    }

    const {marginClasses} = useCmsMarginsMixin(props.blok)

    return {
      selectedIndex,
      handleChangeAccordion,
      marginClasses
    }
  },
})
