
import {
  computed,
  defineComponent,
  useContext,
  watch,
} from '@nuxtjs/composition-api'
import useHeaderScrollLinks from '~/composables/ui/useHeaderScrollLinks'

export default defineComponent({
  name: 'HeaderScrollLinks',
  setup() {
    const { i18n, route } = useContext()
    const { activeTab, resetActiveTab } = useHeaderScrollLinks()

    const category = computed(() => route.value.params.category)

    watch(category, (newCategory, oldCategory) => {
      if (newCategory !== oldCategory) {
        resetActiveTab()
      }
    })

    const scrollLinks = [
      {
        label: i18n.t('navigation.current'),
        anchor: 'current',
      },
      {
        label: i18n.t('navigation.upcoming'),
        anchor: 'upcoming',
      },
    ]

    return {
      scrollLinks,
      activeTab,
    }
  },
})
