
import {
  defineComponent,
  onMounted,
  useContext,
  useRoute,
  computed,
  watch,
  useStore,
  onBeforeMount,
ref,
unref
} from '@nuxtjs/composition-api'
import {onFetchAsync, useRpc, useUserWithParams} from '@scayle/storefront-nuxt2'
import HeaderLoggedOut from '~/components/layout/headers/HeaderLoggedOut.vue'
import Footer from '~/components/layout/footers/Footer.vue'
import addOneTrustScript from '~/helpers/onetrust'
import {useCmsStories} from '~/composables/useCms'
import Banner from '~/components/ui/Banner.vue'
import FadeInTransition from '~/components/ui/transitions/FadeInTransition.vue'
import {isNowBetween} from '~/helpers/date'
import useTrackingEvents from '~/composables/tracking/useTrackingEvents'
import {useLayoutMeta} from "~/composables/useLayoutMeta";
import {getCustomerType} from '~/helpers/tracking'
import {sha256Email} from "~/composables/tracking/helpers";
import {useBasket} from "~/composables/useBasket";
import {SHOP_CATEGORY} from '~/@types/category'
import AppBanner from '~/components/layout/headers/AppBanner.vue'
import HeaderLoggedIn from '~/components/layout/headers/HeaderLoggedIn.vue'
import Toasts from '~/components/ui/toast/Toasts.vue'
import {useHeaderOffset} from '~/composables/ui/useHeaderOffset'
import {useIsPage} from '~/composables/useIsPage'
import { useConcatenatedExperiments } from '~/composables/optimizely/useConcatenatedExperiments'
import { useAbTestExperimentEvent } from '~/composables/tracking/events'

export default defineComponent({
  name: 'PublicLayout',
  components: {
    HeaderLoggedOut,
    Footer,
    Banner,
    FadeInTransition,
    AppBanner,
    HeaderLoggedIn,
    Toasts,
  },

  setup() {
    const {$config, i18n} = useContext()
    const {isLive, domains, oneTrustIds} = $config
    const store = useStore()
    const {user, isLoggedIn} = useUserWithParams({})
    const {fetch: loadUser} = useRpc('getUser', 'useUser')
    const {trackShopInit, track, trackBasket} = useTrackingEvents()
    const {data: basketData} = useBasket()
    const {data: bannerData, fetchByFolder} = useCmsStories('banners')
    const route = useRoute()
    const concatenatedExperiments = useConcatenatedExperiments();
    const {trackAbTestAssignments} = useAbTestExperimentEvent()
    const {isLandingPage, isServicePage} = useIsPage()
    const userHasScrolled = ref<boolean>(false);

    const hideHeaderAndFooter = computed(() => (store as any).state.hideHeaderAndFooter)

    const scrollHandler = () => {
      userHasScrolled.value = window.scrollY > 0
    }

    useHeaderOffset()
    useLayoutMeta()

    const bannerForActiveLocale = computed(() => {
      return (bannerData.value || []).find((banner: any) =>
        banner.content.countries.includes(i18n.locale)
      )
    })

    const showBanner = computed(() => {
      if (!bannerForActiveLocale.value) return false

      const bannerStart = bannerForActiveLocale.value.content.start_date_time;
      const bannerEnd = bannerForActiveLocale.value.content.end_date_time

      return isNowBetween(bannerStart, bannerEnd)
    })

    const showHeaders = computed(() => {
      return userHasScrolled.value || !isLandingPage.value
    })

    onFetchAsync(async () => {
      await Promise.all([
        loadUser(),
        fetchByFolder({
          folder: 'banners',
          callingComponent: 'public.vue (Layout)',
          route: route.value.fullPath
        })
      ])
    })

    const setPageId = () => {
      // Custom Landing Pages have different `pageId` logic,
      if(!route.value.name?.startsWith('all___')) {
        store.dispatch('setPageId', route?.value.params)
      }
    }

    onBeforeMount(() => {
      setPageId()
      const hasPendingRedirect = (store.state as any).redirectBackToApp

      if(!hasPendingRedirect) {
        const host = window.location.host
        addOneTrustScript(isLive, host, oneTrustIds, domains, i18n.locale)
      }

      trackShopInit((route.value.params.category ?? 'other') as SHOP_CATEGORY)

      trackAbTestAssignments()

      track('customer_data', {
        customer_id: String(user?.value?.id || ''),
        customer_type: getCustomerType(user.value),
        login: !!user?.value,
        method: user?.value ? 'email' : 'none',
        eh: sha256Email(user?.value?.email),
        abtest: unref(concatenatedExperiments)
      })

      trackBasket(basketData.value?.items ?? [])

      window.removeEventListener('scroll', scrollHandler)
    })

    onMounted(() => {
      window.addEventListener('scroll', scrollHandler, {passive: true})
      scrollHandler()
    })

    watch(route, () => {
      setPageId()
    })

    return {
      hideHeaderAndFooter,
      bannerForActiveLocale,
      showBanner,
      isLoggedIn,
      setPageId,
      isLandingPage,
      userHasScrolled,
      showHeaders
    }
  },
  head: {}
})
