
import Vue from 'vue';
import {useRenderer} from '../composables/useRenderer';
import {Options} from '..';
import {Node} from '~/@types/rich-text-renderer-types';

export default Vue.extend({
  functional: true,
  props: {
    document: {
      type: [Object as () => Node, Array as () => Node[]],
      required: true
    },
    options: {
      type: Object as () => Options,
      default: () => ({})
    }
  },
  render(h, {props}) {
    const options = {
      ...Vue.prototype.$richTextRenderer,
      ...props.options
    };
    const {renderDocument} = useRenderer(options);
    return renderDocument(props.document);
  }
});
