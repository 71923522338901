
import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { BannerData } from '~/@types/cms/bannerData'
import Icon from '~/components/ui/icons/Icon.vue'
import Tooltip from '~/components/ui/tooltips/Tooltip.vue'

export default defineComponent({
  name: 'Banner',
  components: {
    Icon,
    Tooltip,
  },
  props: {
    bannerData: {
      type: Object as PropType<BannerData>,
      required: true,
    },
  },
  computed: {
    additionalStyles(): string {
      return `background-color: ${this.backgroundColorHexCode}`
    },
    backgroundColorHexCode(): string {
      return this.bannerData.content.background_color.color
    },
    hasLegalText(): boolean {
      return this.bannerData.content.legal_text.length > 0
    },
    legalText(): string {
      return this.bannerData.content?.legal_text
    },
  },
})
