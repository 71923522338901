import {
  getBadgeLabel,
  getFirstAttributeValue,
  getLowestPrice,
  getPrice,
  getProductColors,
  getSizeFromSpecificVariant,
  getSizeFromVariant,
  getVariant,
  isInStock,
  ProductImageType,
  Variant,
  Product,
  Price,
  Attributes,
  CentAmount
} from '@scayle/storefront-nuxt2'
import {sortI11nSizes} from './i11n_sizes'
import {totalAppliedReductions} from './reductions'

export interface Size {
  variantId: number
  label: string
  cupSize: string
  length: string
  value?: string
  isAvailable: boolean
  quantity: number
}

export interface VariantWithSizes {
  variantId: number
  price: Price
  id: number
  isAvailable: boolean
  quantity: number
  shopSize?: {
    label: string
    value?: string
  }
  vendorSize?: {
    label: string
    value?: string
  }
  cupSize?: string
  length?: string
  hasSameSizes?: boolean
}

const getColorCodeForId = (id: number) => {
  const colorMap: Record<number, string> = {
    38919: '#f5deb3',
    38920: '#2196f3',
    38921: '#795548',
    38922: '#B79E6E',
    38923: '#ffeb3b',
    38924: '#ffd700',
    38925: '#9e9e9e',
    38926: '#4caf50',
    38927: '#9c27b0',
    38928: '#FE7D7E',
    38929: '#ff9800',
    38930: '#e91e63',
    38931: '#f44336',
    38932: '#000000',
    38933: '#CFD1D0',
    38934: '#f2f2f2',
    38935: '#ffffff',
    38875: '#ffb6c1',
    38807: '#3E3F41',
    38829: '#adff2f',
    38855: '#FFB385',
    38758: '#00ffff',
    38838: '#330033',
    38756: '#1AADEA',
    38810: '#8C92A0',
    38846: '#990033',
    38734: '#E1D1B2',
    38746: '#C1B2A1',
    38747: '#0000ff',
    38770: '#70778B',
    38765: '#6495ed',
    38884: '#DF1126',
    38882: '#8C0E1D',
    38771: '#663300',
    38786: '#544138',
    38781: '#9E8369',
    38847: '#531D65',
    38787: '#B79E6E',
    38886: '#AE394A',
    38737: '#D3AB85',
    38782: '#CA9C70',
    38741: '#FCEFCE',
    38739: '#F8F2D7',
    38776: '#B54D16',
    38897: '#D2353C',
    38735: '#EFE4CB',
    38792: '#FFC421',
    38762: '#008b8b',
    38845: '#660033',
    38745: '#C0B49B',
    38769: '#00008b',
    38785: '#4c2002',
    38801: '#fbd621',
    38817: '#6C6D6A',
    38835: '#006400',
    38851: '#9400d3',
    38864: '#ff8c00',
    38879: '#CC0066',
    38903: '#8b0000',
    38742: '#F2E6D2',
    38916: '#F0F0F0',
    38738: '#FEFDE5',
    38759: '#2631b2',
    38874: '#e485bb',
    38895: '#b22222',
    38840: '#d8bfd8',
    38867: '#ff00ff',
    38789: '#ffff00',
    38802: '#C59828',
    38803: '#ffd700',
    38794: '#FACD4E',
    38808: '#4C4D4F',
    38831: '#7cfc00',
    38805: '#b7b7b7',
    38818: '#807D84',
    38813: '#D2CFC7',
    38883: '#BA052E',
    38819: '#008000',
    38836: '#5B7A35',
    38744: '#FCF5E8',
    38768: '#add8e6',
    38784: '#A66827',
    38800: '#ffffe0',
    38816: '#E1E1DF',
    38834: '#98fb98',
    38850: '#CCCCFF',
    38863: '#FFB573',
    38878: '#ff69b4',
    38902: '#F74848',
    38871: '#F3466A',
    38764: '#87ceeb',
    38793: '#EEBF6E',
    38859: '#F55C26',
    38751: '#4b0082',
    38827: '#457950',
    38772: '#CF8625',
    38889: '#800000',
    38773: '#A64A2C',
    38820: '#bdb76b',
    38892: '#C62831',
    38740: '#E1D7C3',
    38828: '#9acd32',
    38898: '#F50D1A',
    38753: '#0000cd',
    38857: '#ff7f50',
    38788: '#BA6E4E',
    38860: '#fa8072',
    38843: '#CC99FF',
    38837: '#800080',
    38852: '#38172A',
    38821: '#32cd32',
    38795: '#E1D544',
    38870: '#CC3366',
    38856: '#EB7A35',
    38748: '#13255A',
    38841: '#C699AD',
    38899: '#F76663',
    38893: '#7C0001',
    38825: '#D4F3DD',
    38853: '#FE7D7E',
    38783: '#7A6A58',
    38754: '#191970',
    38915: '#ECEAE4',
    38749: '#000080',
    38766: '#25CAF8',
    38798: '#F5FD37',
    38832: '#00ff00',
    38848: '#F200FF',
    38861: '#FF7900',
    38876: '#FF00CC',
    38900: '#FF2700',
    38736: '#E7CCB6',
    38777: '#BE7817',
    38913: '#E8E2DB',
    38822: '#808000',
    38757: '#7B98BD',
    38854: '#ffa500',
    38865: '#D4591C',
    38891: '#ff4500',
    38844: '#da70d6',
    38890: '#B34018',
    38767: '#D4EBF8',
    38799: '#FFFFC1',
    38833: '#CEECBF',
    38849: '#e6e6fa',
    38862: '#FFC490',
    38877: '#FFE4E4',
    38901: '#FF9C9C',
    38912: '#EEEDE9',
    38823: '#008080',
    38858: '#ffdab9',
    38839: '#dda0dd',
    38866: '#ff1493',
    38880: '#EC5F87',
    38869: '#F979A2',
    38909: '#B7B0B0',
    38873: '#F3CCB8',
    38779: '#D8A36E',
    38894: '#dc143c',
    38752: '#708090',
    38812: '#B9B9B9',
    38872: '#FFD4CF',
    38868: '#ffc0cb',
    38804: '#EBBCA8',
    38775: '#AB3E1D',
    38885: '#B14224',
    38881: '#ff0000',
    38904: '#BA2345',
    38896: '#c71585',
    38760: '#4169e1',
    38888: '#A4050C',
    38796: '#E5B129',
    38743: '#F8F0E2',
    38755: '#3F5C9B',
    38815: '#9E9DA2',
    38826: '#2e8b57',
    38809: '#6E6663',
    38774: '#59362A',
    38905: '#000000',
    38907: '#252525',
    38790: '#DDB33C',
    38778: '#DCAC6A',
    38906: '#303030',
    38908: '#CFD1D0',
    38917: '#EBE9EF',
    38811: '#c0c0c0',
    38824: '#008b8b',
    38814: '#BEBEBE',
    38830: '#306C44',
    38763: '#b0c4de',
    38806: '#B5AC9E',
    38910: '#f3eeee',
    38750: '#40e0d0',
    38761: '#06129B',
    38780: '#935520',
    38842: '#483d8b',
    38887: '#7B0E2A',
    38911: '#ffffff',
    38918: '#D1D1D7',
    38914: '#F1E9DD',
    38791: '#FFE055',
    38797: '#F8F657'
  }

  if (colorMap[id] !== undefined) {
    return colorMap[id]
  }

  return '#ffffff'
}

const EMPTY_PRICE = {
  withTax: 0 as CentAmount,
  withoutTax: 0 as CentAmount,
  tax: {
    vat: {
      amount: 0 as CentAmount,
      rate: 0
    }
  },
  appliedReductions: [],
  currencyCode: 'EUR'
}

const getAllAvailableSizesFromVariants = (
  variants: Variant[],
  onlyActiveVariants: boolean = false
) => {
  let availableVariants = variants.map(variant => {
    const shopSize = getFirstAttributeValue(variant.attributes, 'shopSize')
    const vendorSize = getFirstAttributeValue(variant.attributes, 'vendorSize')
    const cupSize = getFirstAttributeValue(variant.attributes, 'cupsize')
    const length = getFirstAttributeValue(variant.attributes, 'length')
    const quantity = variant.stock.quantity
    const price = variant.price
    const id = variant.id

    let variantWithSize = {
      variantId: variant.id,
      price,
      id,
      isAvailable: isInStock(variant),
      quantity
    } as VariantWithSizes

    if (shopSize) {
      variantWithSize = {...variantWithSize, ...{shopSize: {label: shopSize.label, value: shopSize.value} } }
    }

    if (vendorSize) {
      variantWithSize = {...variantWithSize, ...{vendorSize: {label: vendorSize.label, value: vendorSize.value} } }
    }

    if (cupSize) {
      variantWithSize = {...variantWithSize, ...{cupSize: cupSize.value} }
    }

    if (length) {
      variantWithSize = {...variantWithSize, ...{length: length.value} }
    }

    const hasSameSizes = shopSize?.label === vendorSize?.label // && shopSize?.value === vendorSize?.value


    return {...variantWithSize, hasSameSizes}
  })

  if (onlyActiveVariants) {
    availableVariants = availableVariants.filter(
      variant => variant.isAvailable
    )
  }

  return availableVariants.sort(sortI11nSizes)
}

const getSizeRuns = (attributes?: Attributes) => {
  const shopSizeRun = getFirstAttributeValue(attributes, 'shopSizeRun')
  const vendorSizeRun = getFirstAttributeValue(attributes, 'vendorSizeRun')
  return {
    shopSizeRun,
    vendorSizeRun
  }
}

const hasDifferentPrices = (product: Product, options: {includeUnavailable: boolean} = {includeUnavailable: true}) => {
  const prices = new Set()
  if (product.variants) {
    for(const variant of product.variants) {
      const price = getPrice(variant).withTax
      if(options.includeUnavailable || isInStock(variant)) {
        prices.add(price)
      }
    }
  }
  return prices.size > 1
}

const hasDifferentDiscounts = (product: Product, options: {includeUnavailable: boolean} = {includeUnavailable: true}) => {
  const prices = new Set()
  if (product.variants) {
    for(const variant of product.variants) {
      const finalReduction = totalAppliedReductions(getPrice(variant)).finalReduction

      if(options.includeUnavailable || isInStock(variant)) {
        prices.add(finalReduction)
      }
    }
  }
  return prices.size > 1
}

const getLowestPriceFromVariantsInStock = (product?: Product | null): Price => {
  if (!product?.variants) {
    return EMPTY_PRICE
  }

  const inStockVariants = product.variants.filter(variant => variant.stock.quantity !== 0 && !!variant.price)

  if (inStockVariants.length === 0) {
    return EMPTY_PRICE
  }

  if (inStockVariants.length === 1) {
    return inStockVariants[0].price
  }

  return getLowestPrice(inStockVariants)
}

export default {
  getBadgeLabel,
  getFirstAttributeValue,
  getLowestPriceFromVariantsInStock,
  ProductImageType,
  getColorCodeForId,
  getProductColors,
  getSizeFromVariant,
  getSizeFromSpecificVariant,
  getAllAvailableSizesFromVariants,
  hasDifferentPrices,
  hasDifferentDiscounts,
  getVariant,
  getSizeRuns
}
