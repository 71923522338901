import { useContext } from '@nuxtjs/composition-api'

export default () => {
  const { $device } = useContext()
  return {
    isDesktop: $device.isDesktop,
    isMobile: $device.isMobile,
    isTablet: $device.isTablet,
    isMobileOrTablet: $device.isMobileOrTablet,
    isDesktopOrTablet: $device.isDesktopOrTablet,
    isIos: $device.isIos,
    isWindows: $device.isWindows,
    isMacOS: $device.isMacOS,
    isAndroid: $device.isAndroid,
    isFirefox: $device.isFirefox,
    isEdge: $device.isEdge,
    isChrome: $device.isChrome,
    isSamsung: $device.isSamsung,
    isCrawler: $device.isCrawler,
  }
}
