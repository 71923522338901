import {LogEntry} from '@scayle/storefront-nuxt2'
const isProd = process.env.NODE_ENV === 'production'

export const applyLocalLogs = (logger: any, entry: LogEntry) => {
  if (isProd && entry.level === 'debug') return // ignore debug messages in production

  const data = prepareData(entry.data)
  const log = logger[entry.level] || logger.info
  const message = `[${entry.space}] ${entry.message}`
  const args = data ? [message, data] : [message]

  log.apply(log, args as any)
}

const prepareData = (data: any) => {
  if (!data) return data
  if (data.response) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {response, message, name, code, request, config, ...other} = data
    return {
      message,
      name,
      code,
      status: response?.status,
      statusText: response?.statusText,
      ...other
    }
  }
  return data
}
