import { CustomStoreConfig } from "../lib"
import { atPaymentProviders } from "../lib/paymentProviders"
import { domains } from "../lib/domains"
import { appKeys } from "../lib/app-keys"

const AT: CustomStoreConfig = {
  code: 'at',
  shopId: 5201,
  domain: domains.at,
  appKeys,
  locale: 'de-AT',
  currency: 'EUR',
  internationalPrefix: '43',
  paymentProviders: atPaymentProviders,
  orderPrefix: 'aoat',
  hidePhoneNumber: true,
  showAllProducts: false,
  allProductsHaveOwnStockAttribute: true,
  lowest30DaysPriceEnabled: true,
  showDeliveryEstimates: false,
  enableSovendus: true,
  sovendusSourceNumber: 7795,
  sovendusMediumNumber: 2
}

export default AT
