import { CustomStoreConfig } from "../lib"
import { roPaymentProviders } from "../lib/paymentProviders"
import { domains } from "../lib/domains"
import {appKeys} from "../lib/app-keys"

const RO: CustomStoreConfig = {
  code: 'ro',
  shopId: 5203,
  domain: domains.ro,
  appKeys,
  locale: 'ro-RO',
  currency: 'LEI',
  internationalPrefix: '40',
  paymentProviders: roPaymentProviders,
  orderPrefix: 'aoro',
  enableReturns: false,
  showAllProducts: false,
  allProductsHaveOwnStockAttribute: true,
  lowest30DaysPriceEnabled: true,
  showDeliveryEstimates: false
}

export default RO
