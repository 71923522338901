var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.showLegalText)?_c('i18n',{staticClass:"mx-auto px-4 text-xs leading-5 text-gray-600",class:{
      'hidden lg:block lg:px-0 lg:w-[540px]': !_vm.isModalMode,
      'px-0 w-auto': _vm.isModalMode
    },attrs:{"path":"login.legal_text","tag":"p"}},[_c('a',{staticClass:"inline-block cursor-pointer underline",attrs:{"place":"dataProtection","href":"/s/data-protection"}},[_vm._v(_vm._s(_vm.$t('login.data_protection'))+"\n    ")]),_vm._v(" "),_c('a',{staticClass:"inline-block cursor-pointer underline",attrs:{"place":"termsOfUse","href":"/s/terms-of-use"}},[_vm._v(_vm._s(_vm.$t('login.terms_of_use'))+"\n    ")])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"fixed bottom-0 left-0 right-0 bg-white py-4",class:{
      'lg:hidden': !_vm.isModalMode,
      'hidden': _vm.isModalMode
    }},[(_vm.showLegalText)?_c('i18n',{staticClass:"m-auto px-4 text-xs leading-5 text-gray-600 lg:w-[402px] lg:px-0",class:{
      'lg:w-[402px] lg:px-0': !_vm.isModalMode,
      'w-auto px-0': _vm.isModalMode
    },attrs:{"path":"login.legal_text","tag":"p"}},[_c('a',{staticClass:"inline-block cursor-pointer underline",attrs:{"place":"dataProtection","href":"/s/data-protection"}},[_vm._v(_vm._s(_vm.$t('login.data_protection'))+"\n      ")]),_vm._v(" "),_c('a',{staticClass:"inline-block cursor-pointer underline",attrs:{"place":"termsOfUse","href":"/s/terms-of-use"}},[_vm._v(_vm._s(_vm.$t('login.terms_of_use'))+"\n      ")])]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }