
import {defineComponent, useRoute, computed, onMounted, useContext} from '@nuxtjs/composition-api'
import AdvancedTabs from '~/components/ui/tabs/AdvancedTabs.vue'
import SignUpForm from '~/components/login/SignUpForm.vue'
import SignInForm from '~/components/login/SignInForm.vue'
import LastLoggedInForm from '~/components/login/LastLoggedInForm.vue'
import LoginFooter from '~/components/login/LoginFooter.vue'
import Modal from '~/components/ui/modal/Modal.vue'
import ForgottenPasswordForm from '~/components/login/ForgottenPasswordForm.vue'
import UpdatePasswordByHashForm from '~/components/login/UpdatePasswordByHashForm.vue'
import {useLastLoggedInUser} from '~/composables/useLastLoggedInUser'
import useDevice from '~/composables/ui/useDevice'
import {useHeaderOffset} from '~/composables/ui/useHeaderOffset'

export default defineComponent({
  components: {
    AdvancedTabs,
    SignUpForm,
    SignInForm,
    LastLoggedInForm,
    LoginFooter,
    Modal,
    ForgottenPasswordForm,
    UpdatePasswordByHashForm
},
  layout: 'public',
  meta: {
    pageType: 'accessPage'
  },
  setup() {
    const tabs = [
      {
        type: 'login',
        label: 'login_page.signin_form.title'
      },
      {
        type: 'register',
        label: 'login_page.signup_form.title'
      }
    ]
    const {store} = useContext()
    const route = useRoute()
    const {lastLoggedInUser} = useLastLoggedInUser()
    const {isMobile} = useDevice()
    const {headerOffset} = useHeaderOffset()

    const topOffset = computed(() => {
      // on desktop and tablet the login modal is aligned in the middle vertically
      if (!isMobile) {
        return 'auto'
      }

      return `${headerOffset.value}px`
    })

    const showErrorModal = computed(() => {
      return store.getters['auth/showErrorMessage']
    });

    onMounted(() => {
      if (showErrorModal) {
        store.dispatch('auth/hideErrorMessage')
      }
    })

    return {
      tabs,
      lastLoggedInUser,
      isMobile,
      topOffset,
      showForgottenPassword: computed(() => !!route.value.query['forgotten-password'] && !route.value.query.hash),
      showUpdatePasswordByHash: computed(() => !!route.value.query.hash)
    }
  }
})
