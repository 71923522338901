import {defineNuxtPlugin} from '@nuxtjs/composition-api'
import {Log, LogEntry} from '@scayle/storefront-nuxt2'
import {Context} from "@nuxt/types";
import {datadogRum} from '@datadog/browser-rum';
import {createLocalLogger} from "~/modules/log/loggers/local-logger";
import {initDatadogLogs} from "~/modules/log/loggers/datadog/datadog-logs";
import {applyDatadogRum, initDatadogRum} from "~/modules/log/loggers/datadog/datadog-rum";
import {applyLocalLogs} from "~/modules/log/log";
import {addClientSideErrorHandling} from "~/modules/log/util";
import cookieConsent from '~/helpers/cookieConsent';

const initClientSpecificLogs = (context: Context) => {
  const localLogger = createLocalLogger(context);

  let didInitialize = false;

  cookieConsent("dd", ["dd", "functional"], new Map())
    .optIn(() => {
      if(didInitialize) {
        return;
      }
      initDatadogLogs(context);
      initDatadogRum(context);

      didInitialize = true;
    })
    .optOut(() => {
      if(process.client) {
        datadogRum.stopSession()
      }
    })

  return {
    localLogger
  }
}

export default defineNuxtPlugin((context, inject) => {
  const {localLogger} = initClientSpecificLogs(context);

  const log = new Log({
    space: 'ayOutletWeb',
    handler: (entry: LogEntry) => {
      applyLocalLogs(localLogger, entry)
      applyDatadogRum(context, entry)
    }
  });

  inject('log', log)

  if (process.client) {
    addClientSideErrorHandling(log)
  }
})
