import {TrackingEvent, TrackingPayload} from '../types'
import {sha256Email} from "../helpers";

export interface SignTracking {
  content_name?: string;
  correlation_id?: string;
  customer_id?: number;
  eh?: string;
  event: TrackingEvent;
  method: 'none' | 'email' | 'facebook' | 'apple' | 'password_reset';
  page_type?: string;
  page_type_id?: string;
  status: 'successful' | 'error'
}

/**
 * User authentication happens in 2 steps, when iframe responds with 2 events sequentially:
 *  1. first event contains the tracking information, that might be missing the email hash but has all other fields;
 *  2. second event contains the actual user object with email, that we need to encrypt on our side.
 *
 *  After the send event is received and user is considered to be authenticated we push the event to dataLayer
 *  and clean the sharedRef instance, since we would need to reuse it in case of logout and new login
 *
 *  @example:
 *    1. iframe's event.data
 *      {
 * 	      "type":"tracking",
 * 	      "event":{
 * 		      "event":"login",
 * 		      "content_name":"/embedded/accountlogin",
 * 		      "page_type":"checkout:modal_login",
 * 		      "page_type_id":"",
 * 		      "method":"email",
 * 		      "status":"successful",
 * 		      "customer_id":67290848,
 * 		      "eh":""
 * 	      }
 *      }
 *    2. iframe's event.data
 *      {
 * 	      "action":"authenticated",
 * 	      "user":{
 * 		      "authentication":{
 * 			      "type":"password"
 * 		      },
 * 		      "createdAt":"2023-02-01T16:10:36+01:00",
 * 		      "email":"test01022023.1@aboutyou.com",
 * 		      "firstName":"Test",
 * 		      ...
 * 		      "updatedAt":"2023-02-01T16:15:10+01:00",
 * 		      "loginShopId":5200,
 * 	      },
 * 	      "accessToken":"SOME_TOKEN"
 *      }
 */
const useUserActionEvents = (
  track: (event: TrackingEvent, payload: TrackingPayload) => any
) => {
  return {
    trackAuthenticated: (tracking: SignTracking | null, email?: string) => {
      if (tracking === null) {
        return;
      }

      const eh = tracking.eh;
      let emailHash = '';

      if (typeof eh === 'string' && eh.length > 0) {
        emailHash = eh;
      }
      else {
        emailHash = sha256Email(email)
      }

      track(tracking.event, {
        method: tracking.method,
        status: tracking.status,
        customer_id: tracking.customer_id ? `${tracking.customer_id}` : undefined,
        eh: emailHash,
        // @ts-ignore
        content_name: tracking.content_name,
        page_type: tracking.page_type,
        page_type_id: tracking.page_type_id || ""
      })
    },
    trackLogout: (customerId: number, email?: string) => {
      track('logout', {
        customer_id: customerId,
        eh: sha256Email(email)
      })
    }
  }
}

export default useUserActionEvents
