
import {defineComponent, useContext} from '@nuxtjs/composition-api'
import SlideInFromTopTransition from '~/components/ui/transitions/SlideInFromTopTransition.vue'
import Icon from '~/components/ui/icons/Icon.vue'
import {useIsPubliclyOpenedPage} from '~/composables/useIsPubliclyOpenedPage'

export default defineComponent({
  name: 'SignInToast',
  components: {
    SlideInFromTopTransition,
    Icon
  },
  setup() {
    const {isPubliclyOpenedPage} = useIsPubliclyOpenedPage()
    const {store} = useContext()

    const showAuthenticationModal = () => {
      store.dispatch('auth/showLoginModal')
    }

    return {
      showAuthenticationModal,
      isPubliclyOpenedPage
    }
  }
})
