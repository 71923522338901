
import {computed, defineComponent, PropType} from '@nuxtjs/composition-api'
import Icon from '~/components/ui/icons/Icon.vue'

export default defineComponent({
  name: 'VueButton',
  components: {
    Icon
  },
  props: {
    type: {
      type: String as PropType<string>,
      default: 'primary',
      validator: (val: string) =>
        [
          'primary',
          'secondary',
          'tertiary',
          'ghost',
          'forbidden',
          'warning'
        ].includes(val)
    },
    noPadding: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    icon: {
      type: String as PropType<string>,
      default: ''
    },
    animateIcon: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    isFullWidth: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    loading: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    badge: {
      type: Number as PropType<number>,
      default: 0
    }
  },
  setup(props) {
    const isPrimary = computed(() => props.type === 'primary')
    const isSecondary = computed(() => props.type === 'secondary')
    const isTertiary = computed(() => props.type === 'tertiary')
    const isGhost = computed(() => props.type === 'ghost')
    const isForbidden = computed(() => props.type === 'forbidden')
    const isWarning = computed(() => props.type === 'warning')
    return {
      isPrimary,
      isSecondary,
      isTertiary,
      isGhost,
      isForbidden,
      isWarning,
      textColorClasses: computed(() => ({
        'text-white': isPrimary.value || isWarning,
        'text-primary-100': isSecondary.value,
        'text-primary': isTertiary.value || isGhost.value
      }))
    }
  }
})
