var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('button',{staticClass:"whitespace-no-wrap group inline-flex items-center justify-center gap-1 transition duration-100 ease-linear focus:outline-none disabled:opacity-30",class:{
    'px-3 py-2': !_vm.noPadding,
    'hover:text-primary-600 px-0 text-primary': _vm.isGhost,
    'rounded-sm border border-primary bg-primary font-semibold text-white hover:bg-primary-300 hover:drop-shadow active:bg-primary-400':
      _vm.isPrimary,
    'rounded-sm border border-secondary bg-white font-semibold text-primary hover:bg-secondary-100 hover:text-primary-400 hover:drop-shadow active:bg-secondary-400':
      _vm.isSecondary,
    'border border-primary bg-transparent font-semibold text-primary hover:bg-secondary-300 hover:text-primary-400':
      _vm.isTertiary,
    'cursor-not-allowed bg-gray-600 font-semibold text-white text-primary':
      _vm.isForbidden,
    'rounded-sm border border-red-450 bg-red-450 font-semibold text-white hover:drop-shadow':
      _vm.isWarning,
    'w-full': _vm.isFullWidth,
    'animate-pulse cursor-not-allowed': _vm.loading,
    'active:translate-y-[2px]': !_vm.disabled
  },attrs:{"disabled":_vm.disabled},on:{"click":[function($event){$event.preventDefault();return _vm.$emit('click')},function($event){$event.stopPropagation();return _vm.$emit('click:stop')}]}},[_vm._t("icon",function(){return [(_vm.icon)?_c('Icon',{staticClass:"inline-block h-5 w-5",class:[
        {
          'group-hover:animate-ping-small': _vm.animateIcon,
        },
        _vm.textColorClasses,
      ],attrs:{"icon":_vm.icon}}):_vm._e()]}),_vm._v(" "),_vm._t("default"),_vm._v(" "),_vm._t("badge",function(){return [_c('transition',{attrs:{"enter-class":"opacity-0","enter-to-class":"opacity-100","enter-active-class":"transition ease-linear duration-200","leave-active-class":"transition ease-linear duration-200","leave-class":"opacity-100","leave-to-class":"opacity-0"}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.badge),expression:"badge"}],class:_vm.textColorClasses},[_vm._v(" ("+_vm._s(_vm.badge)+") ")])])]},{"badge":_vm.badge})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }