
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  onUnmounted,
  useContext
} from '@nuxtjs/composition-api'
import {
  getFirstAttributeValue,
  toCurrency,
  Variant,
  Product,
} from '@scayle/storefront-nuxt2'
import DealCountdown from '../ui/deals/DealCountdown.vue'
import Icon from '~/components/ui/icons/Icon.vue'
import QuantityDropdown from '~/components/ui/dropdown/QuantityDropdown.vue'
import ProductReduction from '~/components/product/ProductReduction.vue'
import {totalAppliedReductions} from '~/helpers/reductions'
import {parseDateStringFromStoryblok} from '~/helpers/date'
import {useDealExpire} from '~/composables/useDealExpire'
import { type BasketItemPrice } from '@aboutyou/backbone'

export default defineComponent({
  name: 'BasketCardPopover',
  components: {
    Icon,
    QuantityDropdown,
    ProductReduction,
    DealCountdown
  },
  props: {
    // TODO: rename property to describe intention, like - size="large", or compact="true"
    isBasketPage: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    // TODO: use basketItem.product, to reduce props
    product: {
      type: Object as PropType<Product>,
      required: true
    },
    // TODO: use basketItem.variant, to reduce props
    variant: {
      type: Object as PropType<Variant | undefined>,
      required: true
    },
    name: {
      type: String as PropType<string>,
      required: true
    },
    title: {
      type: String as PropType<string>,
      required: true
    },
    color: {
      type: String as PropType<string>,
      required: true
    },
    price: {
      type: Object as PropType<BasketItemPrice>,
    },
    imageHash: {
      type: String as PropType<string>,
    },
    quantity: {
      type: Number as PropType<number>,
      default: 1
    },
    availableQuantity: {
      type: Number as PropType<number>,
      default: undefined
    },
    size: {
      type: String as PropType<string>,
      default: ''
    },
    isSoldOut: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    showSingleDiscount: {
      type: Boolean,
      default: true
    },
    deal: {
      type: Object as PropType<{
        id: string
        startDate: string
        endDate: string
        slug: string
      } | undefined>,
      default: () => undefined
    }
  },
  emits: ['click:delete', 'click:change-quantity', 'deal:expired'],
  setup(props, {emit}) {
    const {$helpers} = useContext()

    const cupSize = computed(
      () => getFirstAttributeValue(props.variant?.attributes, 'cupsize')?.label
    )

    const dealTimer = useDealExpire(props.deal, {
      onExpire: () => {
        emit('deal:expired')
      }
    })

    onMounted(() => {
      dealTimer.startTimer()
    })

    onUnmounted(() => {
      dealTimer.dispose()
    })

    return {
      toCurrency,
      isLive: dealTimer.isLive,
      parsedDeal: computed(() => {
        if (props.deal) {
          const parsedStart = parseDateStringFromStoryblok(
            props.deal?.startDate
          )
          const parsedEnd = parseDateStringFromStoryblok(props.deal?.endDate)

          return parsedStart && parsedEnd
            ? {
                startDate: new Date(parsedStart),
                endDate: new Date(parsedEnd)
              }
            : null
        }
        return null
      }),
      prices: computed(() => totalAppliedReductions(props.price)),
      cupSize,
      link: computed(() => {
        return $helpers.route.getProductDetailRoute(props.product)
      })
    }
  }
})
