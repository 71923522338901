
import { defineComponent, useContext } from '@nuxtjs/composition-api'
import Facebook from '~/assets/icons/ui/social/facebook.svg'
import Instagram from '~/assets/icons/ui/social/instagram.svg'
import Youtube from '~/assets/icons/ui/social/youtube.svg'
import Tiktok from '~/assets/icons/ui/social/tiktok.svg'
import Whatsup from '~/assets/icons/ui/social/whatsup-gray.svg'

export default defineComponent({
  name: 'SocialLinks',
  components: {
    Facebook,
    Instagram,
    Youtube,
    Tiktok,
    Whatsup
  },
  setup() {
    const { i18n, $currentShop } = useContext()
    const hasWhatsUpIcon = ['de-DE', 'de-AT'].includes($currentShop.locale)

    const socialMediaLinks = [
      {
        icon: 'Facebook',
        to: i18n.t('social_media.facebook'),
      },
      {
        icon: 'Instagram',
        to: i18n.t('social_media.instagram'),
      },
      {
        icon: 'Youtube',
        to: i18n.t('social_media.youtube'),
      },
      {
        icon: 'Tiktok',
        to: i18n.t('social_media.tiktok', ['@']),
      },
      ...(hasWhatsUpIcon ? [{
        icon: 'Whatsup',
        to: i18n.t('social_media.whatsup'),
      }] : [])
    ]

    return {
      socialMediaLinks,
    }
  },
})
