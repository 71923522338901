import Vue from 'vue';
import SbComponentFallback from '../elements/ComponentFallback.vue';

export interface ComponentResolvers {
  _fallback: typeof Vue;
  [key: string]: typeof Vue | undefined;
}

export const defaultComponentResolvers: ComponentResolvers = {
  // @ts-ignore
  _fallback: SbComponentFallback
};
