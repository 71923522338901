
import {defineComponent, ref} from '@nuxtjs/composition-api';
import {ComponentBody} from '~/@types/rich-text-renderer-types';
export default defineComponent({
  name: 'SbComponentFallback',
  props: {
    body: {
      type: Object as () => ComponentBody,
      required: true
    }
  },
  setup({body: {component} }) {
    const componentName = ref(component);
    return {
      componentName
    };
  }
});
