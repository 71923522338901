
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  PropType,
  watch,
  useContext
} from '@nuxtjs/composition-api'
import Icon from '~/components/ui/icons/Icon.vue'

export default defineComponent({
  name: 'DealCountdown',
  components: {
    Icon
  },
  props: {
    startDate: {
      type: Date as PropType<Date>,
      required: true
    },
    endDate: {
      type: Date as PropType<Date>,
      required: true
    },
    hideOnExpire: {
      type: Boolean,
      default: false
    },
    showIcon: {
      type: Boolean as PropType<boolean>,
      default: true
    },
    iconSize: {
      type: String,
      default: '4'
    },
    textSize: {
      type: String,
      default: 'sm'
    },
    noPadding: {
      type: Boolean,
      default: false
    },
    fontBold: {
      type: Boolean,
      default: true
    }
  },
  emits: ['countdown-reached'],
  setup(props, {emit}) {
    // TODO: use useDealExpire composable which is based on this one
    const currentDate = ref(new Date())
    const {i18n, $currentShop} = useContext()
    let interval: any

    const dateDifference = computed(() => {
      const timeDiff = props.endDate.getTime() - currentDate.value.getTime()

      if(timeDiff <= 0) {
        return null
      }

      return new Date(props.endDate.getTime() - currentDate.value.getTime())
    })

    const isLive = computed(() => {
      return (
        props.startDate.getTime() <= currentDate.value.getTime() &&
        props.endDate.getTime() >= currentDate.value.getTime()
      )
    })
    // FIXME: use remainingTime from useDealExpire composable as
    // it has a better time diff computation, and does not break
    // when the time expires.
    const remainingTime = computed(() => {

      if(!dateDifference.value) {
        return '00:00:00'
      }

      const showMonths = dateDifference.value.getUTCMonth() > 0
      const monthsPart = `${dateDifference.value.getUTCMonth()}${i18n.t(
        'deal_countdown.month'
      )} `

      const showDays = dateDifference.value.getUTCDate() > 1
      const daysPart = `${dateDifference.value.getUTCDate() - 1}${i18n.t(
        'deal_countdown.days'
      )} `

      return (
        `${showMonths ? monthsPart : ''}` +
        `${showDays ? daysPart : ''}` +
        dateDifference.value.getUTCHours() +
        i18n.t('deal_countdown.hours') +
        ' ' +
        dateDifference.value.getUTCMinutes() +
        i18n.t('deal_countdown.minutes') +
        ' ' +
        dateDifference.value.getUTCSeconds() +
        i18n.t('deal_countdown.seconds')
      )
    })

    watch(isLive, (isLiveNow: boolean, wasLiveBefore: boolean) => {
      emit('countdown-reached', {isLiveNow, wasLiveBefore})
    })

    const initInterval = () => {
      interval = setInterval(() => {
        currentDate.value = new Date()
      }, 1000)
    }

    onMounted(() => {
      initInterval()
      if(!isLive.value) {
        emit('countdown-reached', {isLiveNow: false, wasLiveBefore: false})
      }
    })

    onUnmounted(() => {
      clearInterval(interval)
    })

    return {isLive, remainingTime, locale: $currentShop.locale}
  }
})
