
import {defineComponent, PropType} from '@nuxtjs/composition-api'
import Logo from '~/components/logo/Logo.vue'
import HeaderLink from '~/components/ui/links/Link.vue'

export default defineComponent({
  name: 'HeaderLoggedOut',
  components: {
    Logo,
    HeaderLink
  },
  props: {
    isBannerActive: {
      type: Boolean as PropType<boolean>,
      required: true
    }
  }
})
