import {identity} from "./predicates";

export function getPageFromRoute(page: string | (string | null)[]) {
  const pageQuery = Array.isArray(page) ? page[0] : page
  const pageFromRoute = parseFloat(pageQuery || '1')

  return isNaN(pageFromRoute) || pageFromRoute < 0 ? null : pageFromRoute
}

export function replaceUrlTemplateParams(url: string, params: Array<{name: string, value: string}>, landingPage?: string) {
  let finalUrl = `${url}`;

  for(const param of params) {
    finalUrl = finalUrl.replaceAll(`{${param.name}}`, param.value);
  }

  if(landingPage) {
    finalUrl = finalUrl.replaceAll(`{url_hash}`, encodeURIComponent(landingPage.replace(/^\//, '')))
  }

  if(url.includes('{url_with_query}') && landingPage) {
    // Making sure new URL works with relative urls as well, the base parameter does not change the output
    const landingPageParsed = new URL(landingPage, 'https://aboutyou-outlet.de');
    finalUrl = finalUrl.replaceAll(`{url_with_query}`, `${landingPageParsed.pathname}`)

    const parsedFinalUrl = new URL(finalUrl);

    for(const pair of landingPageParsed.searchParams.entries()) {
      parsedFinalUrl.searchParams.append(pair[0], pair[1]);
    }

    finalUrl = parsedFinalUrl.toString();
  }

  // Remove unkwnown template parameters
  return finalUrl.replace(/(\{.+?\})/g, '')
}

export const getCurrentUrlWithLandingParams = (urlWithParams: string, targetUrl: string) => {
  const withParams = new URL(urlWithParams, 'https://aboutyou-outlet.de');
  const target = new URL(targetUrl, 'https://aboutyou-outlet.de');

  return [target.pathname, withParams.searchParams].filter(identity).join('?')
}
