export enum Locale {
  en_ZZ = 'en-ZZ',
  es_ZZ = 'es-ZZ',
  de_DE = 'de-DE',
  de_AT = 'de-AT',
  de_CH = 'de-CH',
  en_DE = 'en-DE',
  fr_CH = 'fr-CH',
  nl_NL = 'nl-NL',
  nl_BE = 'nl-BE',
  fr_BE = 'fr-BE',
  pl_PL = 'pl-PL',
  cs_CZ = 'cs-CZ',
  sk_SK = 'sk-SK',
  ro_RO = 'ro-RO',
  hu_HU = 'hu-HU',
  sl_SI = 'sl-SI',
  lv_LV = 'lv-LV',
  lt_LT = 'lt-LT',
  et_EE = 'et-EE',
  hr_HR = 'hr-HR',
  bg_BG = 'bg-BG',
  da_DK = 'da-DK',
  en_IE = 'en-IE',
  fr_FR = 'fr-FR',
  sv_SE = 'sv-SE',
  fi_FI = 'fi-FI',
  es_ES = 'es-ES',
  it_IT = 'it-IT',
  pt_PT = 'pt-PT',
  el_GR = 'el-GR',
  no_NO = 'no-NO',
  ar_SA = 'ar-SA',
  en_SA = 'en-SA',
  he_IL = 'he-IL',
  en_IL = 'en-IL',
  el_CY = 'el-CY',
}
