export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {},
  vueI18nLoader: false,
  locales: [{"parsePages":false,"code":"at","file":"de-AT.json"},{"parsePages":false,"code":"bg","file":"bg-BG.json"},{"parsePages":false,"code":"cz","file":"cs-CZ.json"},{"parsePages":false,"code":"de","file":"de-DE.json"},{"parsePages":false,"code":"el","file":"el-GR.json"},{"parsePages":false,"code":"hr","file":"hr-HR.json"},{"parsePages":false,"code":"hu","file":"hu-HU.json"},{"parsePages":false,"code":"lt","file":"lt-LT.json"},{"parsePages":false,"code":"lv","file":"lv-LV.json"},{"parsePages":false,"code":"pl","file":"pl-PL.json"},{"parsePages":false,"code":"ro","file":"ro-RO.json"},{"parsePages":false,"code":"si","file":"sl-SI.json"},{"parsePages":false,"code":"sk","file":"sk-SK.json"},{"code":"en","file":"en_origin.json"}],
  defaultLocale: "de",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "/build/packages/ayo-i18n/langs",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: true,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: false,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  compilation: {"strictMessage":false,"escapeHtml":true},
  normalizedLocales: [{"parsePages":false,"code":"at","file":"de-AT.json"},{"parsePages":false,"code":"bg","file":"bg-BG.json"},{"parsePages":false,"code":"cz","file":"cs-CZ.json"},{"parsePages":false,"code":"de","file":"de-DE.json"},{"parsePages":false,"code":"el","file":"el-GR.json"},{"parsePages":false,"code":"hr","file":"hr-HR.json"},{"parsePages":false,"code":"hu","file":"hu-HU.json"},{"parsePages":false,"code":"lt","file":"lt-LT.json"},{"parsePages":false,"code":"lv","file":"lv-LV.json"},{"parsePages":false,"code":"pl","file":"pl-PL.json"},{"parsePages":false,"code":"ro","file":"ro-RO.json"},{"parsePages":false,"code":"si","file":"sl-SI.json"},{"parsePages":false,"code":"sk","file":"sk-SK.json"},{"code":"en","file":"en_origin.json"}],
  localeCodes: ["at","bg","cz","de","el","hr","hu","lt","lv","pl","ro","si","sk","en"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "d",
  3: "e",
  4: "-",
  5: "A",
  6: "T",
  7: ".",
  8: "j",
  9: "s",
  10: "o",
  11: "n",
  12: "\"",
  13: ":",
  14: "\"",
  15: ".",
  16: ".",
  17: "/",
  18: ".",
  19: ".",
  20: "/",
  21: ".",
  22: ".",
  23: "/",
  24: "p",
  25: "a",
  26: "c",
  27: "k",
  28: "a",
  29: "g",
  30: "e",
  31: "s",
  32: "/",
  33: "a",
  34: "y",
  35: "o",
  36: "-",
  37: "i",
  38: "1",
  39: "8",
  40: "n",
  41: "/",
  42: "l",
  43: "a",
  44: "n",
  45: "g",
  46: "s",
  47: "/",
  48: "d",
  49: "e",
  50: "-",
  51: "A",
  52: "T",
  53: ".",
  54: "j",
  55: "s",
  56: "o",
  57: "n",
  58: "\"",
  59: ",",
  60: "\"",
  61: "b",
  62: "g",
  63: "-",
  64: "B",
  65: "G",
  66: ".",
  67: "j",
  68: "s",
  69: "o",
  70: "n",
  71: "\"",
  72: ":",
  73: "\"",
  74: ".",
  75: ".",
  76: "/",
  77: ".",
  78: ".",
  79: "/",
  80: ".",
  81: ".",
  82: "/",
  83: "p",
  84: "a",
  85: "c",
  86: "k",
  87: "a",
  88: "g",
  89: "e",
  90: "s",
  91: "/",
  92: "a",
  93: "y",
  94: "o",
  95: "-",
  96: "i",
  97: "1",
  98: "8",
  99: "n",
  100: "/",
  101: "l",
  102: "a",
  103: "n",
  104: "g",
  105: "s",
  106: "/",
  107: "b",
  108: "g",
  109: "-",
  110: "B",
  111: "G",
  112: ".",
  113: "j",
  114: "s",
  115: "o",
  116: "n",
  117: "\"",
  118: ",",
  119: "\"",
  120: "c",
  121: "s",
  122: "-",
  123: "C",
  124: "Z",
  125: ".",
  126: "j",
  127: "s",
  128: "o",
  129: "n",
  130: "\"",
  131: ":",
  132: "\"",
  133: ".",
  134: ".",
  135: "/",
  136: ".",
  137: ".",
  138: "/",
  139: ".",
  140: ".",
  141: "/",
  142: "p",
  143: "a",
  144: "c",
  145: "k",
  146: "a",
  147: "g",
  148: "e",
  149: "s",
  150: "/",
  151: "a",
  152: "y",
  153: "o",
  154: "-",
  155: "i",
  156: "1",
  157: "8",
  158: "n",
  159: "/",
  160: "l",
  161: "a",
  162: "n",
  163: "g",
  164: "s",
  165: "/",
  166: "c",
  167: "s",
  168: "-",
  169: "C",
  170: "Z",
  171: ".",
  172: "j",
  173: "s",
  174: "o",
  175: "n",
  176: "\"",
  177: ",",
  178: "\"",
  179: "d",
  180: "e",
  181: "-",
  182: "D",
  183: "E",
  184: ".",
  185: "j",
  186: "s",
  187: "o",
  188: "n",
  189: "\"",
  190: ":",
  191: "\"",
  192: ".",
  193: ".",
  194: "/",
  195: ".",
  196: ".",
  197: "/",
  198: ".",
  199: ".",
  200: "/",
  201: "p",
  202: "a",
  203: "c",
  204: "k",
  205: "a",
  206: "g",
  207: "e",
  208: "s",
  209: "/",
  210: "a",
  211: "y",
  212: "o",
  213: "-",
  214: "i",
  215: "1",
  216: "8",
  217: "n",
  218: "/",
  219: "l",
  220: "a",
  221: "n",
  222: "g",
  223: "s",
  224: "/",
  225: "d",
  226: "e",
  227: "-",
  228: "D",
  229: "E",
  230: ".",
  231: "j",
  232: "s",
  233: "o",
  234: "n",
  235: "\"",
  236: ",",
  237: "\"",
  238: "e",
  239: "l",
  240: "-",
  241: "G",
  242: "R",
  243: ".",
  244: "j",
  245: "s",
  246: "o",
  247: "n",
  248: "\"",
  249: ":",
  250: "\"",
  251: ".",
  252: ".",
  253: "/",
  254: ".",
  255: ".",
  256: "/",
  257: ".",
  258: ".",
  259: "/",
  260: "p",
  261: "a",
  262: "c",
  263: "k",
  264: "a",
  265: "g",
  266: "e",
  267: "s",
  268: "/",
  269: "a",
  270: "y",
  271: "o",
  272: "-",
  273: "i",
  274: "1",
  275: "8",
  276: "n",
  277: "/",
  278: "l",
  279: "a",
  280: "n",
  281: "g",
  282: "s",
  283: "/",
  284: "e",
  285: "l",
  286: "-",
  287: "G",
  288: "R",
  289: ".",
  290: "j",
  291: "s",
  292: "o",
  293: "n",
  294: "\"",
  295: ",",
  296: "\"",
  297: "h",
  298: "r",
  299: "-",
  300: "H",
  301: "R",
  302: ".",
  303: "j",
  304: "s",
  305: "o",
  306: "n",
  307: "\"",
  308: ":",
  309: "\"",
  310: ".",
  311: ".",
  312: "/",
  313: ".",
  314: ".",
  315: "/",
  316: ".",
  317: ".",
  318: "/",
  319: "p",
  320: "a",
  321: "c",
  322: "k",
  323: "a",
  324: "g",
  325: "e",
  326: "s",
  327: "/",
  328: "a",
  329: "y",
  330: "o",
  331: "-",
  332: "i",
  333: "1",
  334: "8",
  335: "n",
  336: "/",
  337: "l",
  338: "a",
  339: "n",
  340: "g",
  341: "s",
  342: "/",
  343: "h",
  344: "r",
  345: "-",
  346: "H",
  347: "R",
  348: ".",
  349: "j",
  350: "s",
  351: "o",
  352: "n",
  353: "\"",
  354: ",",
  355: "\"",
  356: "h",
  357: "u",
  358: "-",
  359: "H",
  360: "U",
  361: ".",
  362: "j",
  363: "s",
  364: "o",
  365: "n",
  366: "\"",
  367: ":",
  368: "\"",
  369: ".",
  370: ".",
  371: "/",
  372: ".",
  373: ".",
  374: "/",
  375: ".",
  376: ".",
  377: "/",
  378: "p",
  379: "a",
  380: "c",
  381: "k",
  382: "a",
  383: "g",
  384: "e",
  385: "s",
  386: "/",
  387: "a",
  388: "y",
  389: "o",
  390: "-",
  391: "i",
  392: "1",
  393: "8",
  394: "n",
  395: "/",
  396: "l",
  397: "a",
  398: "n",
  399: "g",
  400: "s",
  401: "/",
  402: "h",
  403: "u",
  404: "-",
  405: "H",
  406: "U",
  407: ".",
  408: "j",
  409: "s",
  410: "o",
  411: "n",
  412: "\"",
  413: ",",
  414: "\"",
  415: "l",
  416: "t",
  417: "-",
  418: "L",
  419: "T",
  420: ".",
  421: "j",
  422: "s",
  423: "o",
  424: "n",
  425: "\"",
  426: ":",
  427: "\"",
  428: ".",
  429: ".",
  430: "/",
  431: ".",
  432: ".",
  433: "/",
  434: ".",
  435: ".",
  436: "/",
  437: "p",
  438: "a",
  439: "c",
  440: "k",
  441: "a",
  442: "g",
  443: "e",
  444: "s",
  445: "/",
  446: "a",
  447: "y",
  448: "o",
  449: "-",
  450: "i",
  451: "1",
  452: "8",
  453: "n",
  454: "/",
  455: "l",
  456: "a",
  457: "n",
  458: "g",
  459: "s",
  460: "/",
  461: "l",
  462: "t",
  463: "-",
  464: "L",
  465: "T",
  466: ".",
  467: "j",
  468: "s",
  469: "o",
  470: "n",
  471: "\"",
  472: ",",
  473: "\"",
  474: "l",
  475: "v",
  476: "-",
  477: "L",
  478: "V",
  479: ".",
  480: "j",
  481: "s",
  482: "o",
  483: "n",
  484: "\"",
  485: ":",
  486: "\"",
  487: ".",
  488: ".",
  489: "/",
  490: ".",
  491: ".",
  492: "/",
  493: ".",
  494: ".",
  495: "/",
  496: "p",
  497: "a",
  498: "c",
  499: "k",
  500: "a",
  501: "g",
  502: "e",
  503: "s",
  504: "/",
  505: "a",
  506: "y",
  507: "o",
  508: "-",
  509: "i",
  510: "1",
  511: "8",
  512: "n",
  513: "/",
  514: "l",
  515: "a",
  516: "n",
  517: "g",
  518: "s",
  519: "/",
  520: "l",
  521: "v",
  522: "-",
  523: "L",
  524: "V",
  525: ".",
  526: "j",
  527: "s",
  528: "o",
  529: "n",
  530: "\"",
  531: ",",
  532: "\"",
  533: "p",
  534: "l",
  535: "-",
  536: "P",
  537: "L",
  538: ".",
  539: "j",
  540: "s",
  541: "o",
  542: "n",
  543: "\"",
  544: ":",
  545: "\"",
  546: ".",
  547: ".",
  548: "/",
  549: ".",
  550: ".",
  551: "/",
  552: ".",
  553: ".",
  554: "/",
  555: "p",
  556: "a",
  557: "c",
  558: "k",
  559: "a",
  560: "g",
  561: "e",
  562: "s",
  563: "/",
  564: "a",
  565: "y",
  566: "o",
  567: "-",
  568: "i",
  569: "1",
  570: "8",
  571: "n",
  572: "/",
  573: "l",
  574: "a",
  575: "n",
  576: "g",
  577: "s",
  578: "/",
  579: "p",
  580: "l",
  581: "-",
  582: "P",
  583: "L",
  584: ".",
  585: "j",
  586: "s",
  587: "o",
  588: "n",
  589: "\"",
  590: ",",
  591: "\"",
  592: "r",
  593: "o",
  594: "-",
  595: "R",
  596: "O",
  597: ".",
  598: "j",
  599: "s",
  600: "o",
  601: "n",
  602: "\"",
  603: ":",
  604: "\"",
  605: ".",
  606: ".",
  607: "/",
  608: ".",
  609: ".",
  610: "/",
  611: ".",
  612: ".",
  613: "/",
  614: "p",
  615: "a",
  616: "c",
  617: "k",
  618: "a",
  619: "g",
  620: "e",
  621: "s",
  622: "/",
  623: "a",
  624: "y",
  625: "o",
  626: "-",
  627: "i",
  628: "1",
  629: "8",
  630: "n",
  631: "/",
  632: "l",
  633: "a",
  634: "n",
  635: "g",
  636: "s",
  637: "/",
  638: "r",
  639: "o",
  640: "-",
  641: "R",
  642: "O",
  643: ".",
  644: "j",
  645: "s",
  646: "o",
  647: "n",
  648: "\"",
  649: ",",
  650: "\"",
  651: "s",
  652: "l",
  653: "-",
  654: "S",
  655: "I",
  656: ".",
  657: "j",
  658: "s",
  659: "o",
  660: "n",
  661: "\"",
  662: ":",
  663: "\"",
  664: ".",
  665: ".",
  666: "/",
  667: ".",
  668: ".",
  669: "/",
  670: ".",
  671: ".",
  672: "/",
  673: "p",
  674: "a",
  675: "c",
  676: "k",
  677: "a",
  678: "g",
  679: "e",
  680: "s",
  681: "/",
  682: "a",
  683: "y",
  684: "o",
  685: "-",
  686: "i",
  687: "1",
  688: "8",
  689: "n",
  690: "/",
  691: "l",
  692: "a",
  693: "n",
  694: "g",
  695: "s",
  696: "/",
  697: "s",
  698: "l",
  699: "-",
  700: "S",
  701: "I",
  702: ".",
  703: "j",
  704: "s",
  705: "o",
  706: "n",
  707: "\"",
  708: ",",
  709: "\"",
  710: "s",
  711: "k",
  712: "-",
  713: "S",
  714: "K",
  715: ".",
  716: "j",
  717: "s",
  718: "o",
  719: "n",
  720: "\"",
  721: ":",
  722: "\"",
  723: ".",
  724: ".",
  725: "/",
  726: ".",
  727: ".",
  728: "/",
  729: ".",
  730: ".",
  731: "/",
  732: "p",
  733: "a",
  734: "c",
  735: "k",
  736: "a",
  737: "g",
  738: "e",
  739: "s",
  740: "/",
  741: "a",
  742: "y",
  743: "o",
  744: "-",
  745: "i",
  746: "1",
  747: "8",
  748: "n",
  749: "/",
  750: "l",
  751: "a",
  752: "n",
  753: "g",
  754: "s",
  755: "/",
  756: "s",
  757: "k",
  758: "-",
  759: "S",
  760: "K",
  761: ".",
  762: "j",
  763: "s",
  764: "o",
  765: "n",
  766: "\"",
  767: ",",
  768: "\"",
  769: "e",
  770: "n",
  771: "_",
  772: "o",
  773: "r",
  774: "i",
  775: "g",
  776: "i",
  777: "n",
  778: ".",
  779: "j",
  780: "s",
  781: "o",
  782: "n",
  783: "\"",
  784: ":",
  785: "\"",
  786: ".",
  787: ".",
  788: "/",
  789: ".",
  790: ".",
  791: "/",
  792: ".",
  793: ".",
  794: "/",
  795: "p",
  796: "a",
  797: "c",
  798: "k",
  799: "a",
  800: "g",
  801: "e",
  802: "s",
  803: "/",
  804: "a",
  805: "y",
  806: "o",
  807: "-",
  808: "i",
  809: "1",
  810: "8",
  811: "n",
  812: "/",
  813: "l",
  814: "a",
  815: "n",
  816: "g",
  817: "s",
  818: "/",
  819: "e",
  820: "n",
  821: "_",
  822: "o",
  823: "r",
  824: "i",
  825: "g",
  826: "i",
  827: "n",
  828: ".",
  829: "j",
  830: "s",
  831: "o",
  832: "n",
  833: "\"",
  834: "}",
}

export const localeMessages = {
  'de-AT.json': () => import('../../../../packages/ayo-i18n/langs/de-AT.json' /* webpackChunkName: "lang-de-AT.json" */),
  'bg-BG.json': () => import('../../../../packages/ayo-i18n/langs/bg-BG.json' /* webpackChunkName: "lang-bg-BG.json" */),
  'cs-CZ.json': () => import('../../../../packages/ayo-i18n/langs/cs-CZ.json' /* webpackChunkName: "lang-cs-CZ.json" */),
  'de-DE.json': () => import('../../../../packages/ayo-i18n/langs/de-DE.json' /* webpackChunkName: "lang-de-DE.json" */),
  'el-GR.json': () => import('../../../../packages/ayo-i18n/langs/el-GR.json' /* webpackChunkName: "lang-el-GR.json" */),
  'hr-HR.json': () => import('../../../../packages/ayo-i18n/langs/hr-HR.json' /* webpackChunkName: "lang-hr-HR.json" */),
  'hu-HU.json': () => import('../../../../packages/ayo-i18n/langs/hu-HU.json' /* webpackChunkName: "lang-hu-HU.json" */),
  'lt-LT.json': () => import('../../../../packages/ayo-i18n/langs/lt-LT.json' /* webpackChunkName: "lang-lt-LT.json" */),
  'lv-LV.json': () => import('../../../../packages/ayo-i18n/langs/lv-LV.json' /* webpackChunkName: "lang-lv-LV.json" */),
  'pl-PL.json': () => import('../../../../packages/ayo-i18n/langs/pl-PL.json' /* webpackChunkName: "lang-pl-PL.json" */),
  'ro-RO.json': () => import('../../../../packages/ayo-i18n/langs/ro-RO.json' /* webpackChunkName: "lang-ro-RO.json" */),
  'sl-SI.json': () => import('../../../../packages/ayo-i18n/langs/sl-SI.json' /* webpackChunkName: "lang-sl-SI.json" */),
  'sk-SK.json': () => import('../../../../packages/ayo-i18n/langs/sk-SK.json' /* webpackChunkName: "lang-sk-SK.json" */),
  'en_origin.json': () => import('../../../../packages/ayo-i18n/langs/en_origin.json' /* webpackChunkName: "lang-en_origin.json" */),
}
