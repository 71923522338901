import { useContext } from '@nuxtjs/composition-api'
import type {TrackingEvent, TrackingPayload} from '../types'
import { mapTrackingDataForEvent } from '../helpers'
import { OptimizelyState } from '~/@types/ab-tests'

type ExperimentTrackingData =  {
  name: string
  variant: string
  experimentKey: string
}

const useAbTestExperimentEvent = () => {
  const {$gtm, store} = useContext()
  const track = (event: TrackingEvent, data: TrackingPayload) => $gtm.push(mapTrackingDataForEvent(event, data))

  return {
    trackExperimentActivation: ({name, variant, experimentKey}: ExperimentTrackingData) => {
      track('experiment_activation', {
        action: name,
        label: variant,
        name: experimentKey
      })
    },
    trackAbTestAssignments: () => {
      const {experiments} = store.state.experiments
      Object.values(experiments as OptimizelyState['experiments']).forEach(exp => {
        if(exp.variationKey && exp.ruleKey) {
          track('abtest', {
            action: exp.flagKey,
            label: exp.variationKey,
            name: exp.ruleKey
          })
        }
      })
      // track('abtest', {
      //   action: name,
      //   label: variant,
      //   name: experimentKey
      // })
    },
  }
}

export default useAbTestExperimentEvent
