import { CustomStoreConfig } from "../lib"
import { skPaymentProviders } from "../lib/paymentProviders"
import { domains } from "../lib/domains"
import {appKeys} from "../lib/app-keys"

const SK: CustomStoreConfig = {
  code: 'sk',
  shopId: 5205,
  domain: domains.sk,
  appKeys,
  locale: 'sk-SK',
  currency: 'EUR',
  internationalPrefix: '421',
  paymentProviders: skPaymentProviders,
  orderPrefix: 'aosk',
  showAllProducts: false,
  allProductsHaveOwnStockAttribute: true,
  lowest30DaysPriceEnabled: true,
  showDeliveryEstimates: false
}

export default SK
