export const state = () => ({
  userIsLoggedIn: false,
  user: null,
  showErrorMessage: false,
  showLoginModal: false
})
export type AuthState = ReturnType<typeof state>
export const getters = {
  isAuthenticated: (state: AuthState) => state.userIsLoggedIn,
  user: (state: AuthState) => state.user,
  showErrorMessage: (state: AuthState) => state.showErrorMessage,
  showLoginModal: (state: AuthState) => state.showLoginModal
}
export const mutations = {
  SET_USER(state: AuthState, user: any) {
    state.user = user
  },
  SET_USER_IS_LOGGED_IN(state: AuthState, loggedIn: boolean) {
    state.userIsLoggedIn = loggedIn
  },
  SET_SHOW_ERROR_MESSAGE(state: AuthState, showErrorMessage: boolean) {
    state.showErrorMessage = showErrorMessage
  },
  SET_SHOW_LOGIN_MODAL(state: AuthState, showLoginModal: boolean) {
    state.showLoginModal = showLoginModal
  }
}
export const actions = {
  setUser(context: any, user: any) {
    context.commit('SET_USER', user)
    context.commit('SET_USER_IS_LOGGED_IN', !!user)
  },
  showErrorMessage(context: any) {
    context.commit('SET_SHOW_ERROR_MESSAGE', true)
  },
  hideErrorMessage(context: any) {
    context.commit('SET_SHOW_ERROR_MESSAGE', false)
  },
  showLoginModal(context: any) {
    context.commit('SET_SHOW_LOGIN_MODAL', true)
  },
  hideLoginModal(context: any) {
    context.commit('SET_SHOW_LOGIN_MODAL', false)
  }
}
