export const state = () => ({
  dealImageHeight: 0,
})

export const getters = {
  getDealImageHeight: (state: any) => state.dealImageHeight,
}
export const mutations = {
  SET_DEAL_IMAGE_HEIGHT(state: any, height: number) {
    state.dealImageHeight = height
  },
}
export const actions = {
  setDealImageHeight(context: any, height: Number) {
    context.commit('SET_DEAL_IMAGE_HEIGHT', height)
  },
}
