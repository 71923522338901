import {PublicShopData} from '@scayle/storefront-nuxt2'
import {ComputedRef, computed, ref, unref, useContext} from '@nuxtjs/composition-api'
import {parseDateStringFromStoryblok} from '~/helpers/date'

const MS_IN_SEC = 1000;
const MS_IN_MIN = 60 * MS_IN_SEC;
const MS_IN_HOUR = 60 * MS_IN_MIN;
const MS_IN_DAY = 24 * MS_IN_HOUR;
const MS_IN_MONTH = 30 * MS_IN_DAY;

const unavailableDates = {
  startDate: new Date(0),
  endDate: new Date(0)
};

type MinDealData = {startDate: string, endDate: string} | undefined

export const useDealExpire = (deal: MinDealData | ComputedRef<MinDealData>, options?: {
  onExpire?: (currentConfig: PublicShopData) => void
  onLive?: (currentConfig: PublicShopData) => void,
  onNotFound?: (currentConfig: PublicShopData) => void
}) => {
  const {$currentShop, i18n} = useContext()
  let interval: number = -1;
  let expireTimeout: number = -1;
  let liveTimeout: number = -1;

  const currentDate = ref(new Date())

  const parsedDeal = computed(() => {
    const unrefDeal = unref(deal);

    if (unrefDeal) {
      const parsedStart = parseDateStringFromStoryblok(unrefDeal.startDate)
      const parsedEnd = parseDateStringFromStoryblok(unrefDeal.endDate)

      return parsedStart && parsedEnd
        ? {
            startDate: new Date(parsedStart),
            endDate: new Date(parsedEnd)
          }
        : unavailableDates
    }

    return unavailableDates
  })

  const dateDifference = computed(() => {
    const timeDiff = unref(parsedDeal).endDate.getTime() - currentDate.value.getTime()
    return Math.max(0, timeDiff);
  })

  const timeUntilDealStarts = computed(() => {
    const timeDiff = unref(parsedDeal).startDate.getTime() - currentDate.value.getTime();
    return Math.max(0, timeDiff);
  });

  const isLive = computed(() => {
    return (
      unref(parsedDeal).startDate.getTime() <= unref(currentDate).getTime() &&
      unref(parsedDeal).endDate.getTime() >= unref(currentDate).getTime()
    )
  })

  const remainingTime = computed(() => {
    if(!dateDifference.value) {
      return [
        `00 ` + i18n.t('deal_countdown.hours'),
        `00 ` + i18n.t('deal_countdown.minutes'),
        `00 ` + i18n.t('deal_countdown.seconds')
      ]
    }

    let remaining = dateDifference.value;

    const months = Math.floor(unref(dateDifference) / MS_IN_MONTH);
    remaining -= (months * MS_IN_MONTH)

    const days = Math.floor(remaining / MS_IN_DAY);
    remaining -= (days * MS_IN_DAY)

    const hours = Math.floor(remaining / MS_IN_HOUR);
    remaining -= hours * MS_IN_HOUR

    const minutes = Math.floor(remaining / MS_IN_MIN);
    remaining -= minutes * MS_IN_MIN

    const seconds = Math.floor(remaining / MS_IN_SEC);

    const remainingTimeLabel = [
      `${hours}`.padStart(2, '0') + i18n.t('deal_countdown.hours'),
      `${minutes}`.padStart(2, '0') + i18n.t('deal_countdown.minutes'),
      `${seconds}`.padStart(2, '0') + i18n.t('deal_countdown.seconds')
    ]

    if(months > 0) {
      remainingTimeLabel.unshift(`${days}`.padStart(2, '0') +  i18n.t('deal_countdown.days'))
      remainingTimeLabel.unshift(`${months}`.padStart(2, '0') +  i18n.t('deal_countdown.month'))
    }
    else if (days > 0) {
      remainingTimeLabel.unshift(`${days}`.padStart(2, '0') +  i18n.t('deal_countdown.days'))
    }

    return remainingTimeLabel;
  })

  const initInterval = () => {
    interval = window?.setInterval(() => {
      currentDate.value = new Date()
    }, 1000);

    expireTimeout = window?.setTimeout(() => {
      window?.clearInterval(interval)

      // difference has to be less than 1sec
      if(unref(dateDifference) < 1000) {
        options?.onExpire?.($currentShop)
      }

    }, unref(dateDifference))

    if(unref(timeUntilDealStarts) > 0) {
      liveTimeout = window?.setTimeout(() => {
        options?.onLive?.($currentShop);
      }, unref(timeUntilDealStarts));
    }

  }

  return {
    remainingTime,
    timeUntilDealStarts,
    isLive,
    dispose: () => {
      clearInterval(interval)
      clearTimeout(expireTimeout)
      clearTimeout(liveTimeout)
    },
    startTimer: () => {
      if(!unref(deal)) {
        return;
      }

      initInterval()
    },
    checkNotFound: () => {
      if(!unref(deal)) {
        options?.onNotFound?.($currentShop);
      }
    }
  }
}
