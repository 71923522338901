import { useContext } from '@nuxtjs/composition-api';
import {TrackExperimentOptions} from '~/@types/ab-tests'

export const useTrackExperimentEvent = () => {
  const {store} = useContext()

  return {
    /**
     * If any of the experiments for current user id were not activated
     * the event will not be reflected on the optimizely dashboard,
     * but still a HTTP request happens, which counts as an impression by Optimizely and costs money,
     * that's why we need to fire an event only if any of the experiments was activated for current user id.
     *
     * Since custom event and activation event share same user id,
     * Optimizely is smart enough to only the assign it to the running test.
     *
     * @param options contains custom event key one of the $optimizely.events, revenue, quantity or value.
     */
    trackExperimentEvent: async (options: TrackExperimentOptions) => {
      store.dispatch('experiments/trackEvent', options)
    }
  }
};
