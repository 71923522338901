export interface Attribute {
  type: 'attributes' | 'boolean'
  id: string | number
  key?: string
  values: Array<string | number>
}

// https://aboutyou360.backbone-api.aboutyou.cloud/v1/attributes/ayOutletPermanentAssortment?shopId=5200
export const PROD_OWNSTOCK_ATTRIBUTE_VALUES = {
  TRUE: 193509,
  FALSE: 193510
}
// https://aboutyou360.backbone-api.staging.aboutyou.cloud/v1/attributes/ayOutletPermanentAssortment?shopId=5200
export const DEV_OWNSTOCK_ATTRIBUTE_VALUES = {
  TRUE: 112675,
  FALSE: 112676
}

export const getOwnStockAttributeValues = (
  storyblokSpace: 'production' | 'staging'
) => {
  return storyblokSpace === 'production'
    ? PROD_OWNSTOCK_ATTRIBUTE_VALUES
    : DEV_OWNSTOCK_ATTRIBUTE_VALUES
}

export const AYO_ATTR_DEALS = 'ayOutletDeals'
export const AYO_ATTR_PERM_ASSORTMENT = 'ayOutletPermanentAssortment'
export const AYO_ATTR_PUBLIC = 'ayOutletPublic'
export const PROD_DETAILS_ATTRS = [
  'shoeMaterialStyle',
  'fastenerType',
  'materialStyle',
  'necklineStyle',
  'design',
  'extras',
  'material',
  'quantityPerPack'
]
export const PROD_SIZE_AND_FIT_ATTR = [
  'fitting',
  'upperLength',
  'sleeveLength',
  'shirtCut',
  'bottomLength',
  'trousersCut',
  'heelHeight'
]
export const PROD_ATTRS = [
  'colorDetail',
  'brand',
  'name',
  'brandLogo',
  'bottomLength',
  'careSymbol',
  'elasticity',
  'knitMaterialType',
  'sportType',
  'shopSizeRun',
  'vendorSizeRun',
  ...PROD_DETAILS_ATTRS,
  ...PROD_SIZE_AND_FIT_ATTR,
  AYO_ATTR_DEALS,
  AYO_ATTR_PERM_ASSORTMENT,
  AYO_ATTR_PUBLIC
]
export const BASKET_PRODUCT_ATTRIBUTES = [
  'brand',
  'colorDetail',
  'name',
  'color',
  AYO_ATTR_DEALS,
  AYO_ATTR_PERM_ASSORTMENT,
]
