
import {onFetchAsync} from '@scayle/storefront-nuxt2'
import {
  defineComponent,
  useContext,
  computed,
  unref
} from '@nuxtjs/composition-api'
import SocialLinks from '~/components/layout/footers/SocialLinks.vue'
import CustomLink from '~/components/ui/links/Link.vue'
import Icon from '~/components/ui/icons/Icon.vue'
import PaymentList from '~/components/layout/footers/PaymentList.vue'
import Logo from '~/components/logo/Logo.vue'
import References from '~/components/layout/footers/References.vue'
import {groupBy} from '~/helpers/array'
import {useCmsStories} from '~/composables/useCms'

declare global {
  interface Window {
    Optanon: any
  }
}

const TARGET_COLUMN_COUNT = 5

interface SeoPage {
  slug: string
  name: string
  groupName: string
}

export default defineComponent({
  name: 'PageFooter',
  components: {
    CustomLink,
    SocialLinks,
    Icon,
    PaymentList,
    Logo,
    References
  },
  setup() {
    const {i18n, route} = useContext()
    const {data: landingPages, fetchByFolder} = useCmsStories('landingPages')

    const footerLinks = [
      {
        headline: 'ABOUT YOU Outlet',
        links: [
          {
            icon: 'icons-ic-play',
            label: i18n.t('footer.about_us.label'),
            to: i18n.t('footer.about_us.link'),
            externalLink: true
          },
          {
            icon: 'icons-ic-play',
            label: i18n.t('footer.imprint'),
            to: '/s/imprint',
            externalLink: false
          },
          {
            icon: 'icons-ic-play',
            label: i18n.t('footer.jobs.label'),
            to: i18n.t('footer.jobs.link'),
            externalLink: true
          },
          {
            icon: 'icons-ic-play',
            label: i18n.t('footer.faq'),
            to: '/s/faq',
            externalLink: false
          }
        ]
      },
      {
        headline: i18n.t('footer.service'),
        links: [
          {
            icon: 'icons-ic-phone',
            label: i18n.t('footer.phone') + '****',
            to: 'tel:' + i18n.t('footer.phone'),
            externalLink: true
          },
          {
            icon: 'icons-ic-chat',
            label: i18n.t('footer.mail', ['@']),
            to: 'mailto:' + i18n.t('footer.mail', ['@']),
            externalLink: true
          }
        ]
      },
      {
        headline: i18n.t('footer.legal'),
        links: [
          {
            icon: 'icons-ic-play',
            label: i18n.t('footer.data_protection'),
            to: '/s/data-protection',
            externalLink: false
          },
          {
            icon: 'icons-ic-play',
            label: i18n.t('footer.consent'),
            to: '',
            externalLink: true
          },
          {
            icon: 'icons-ic-play',
            label: i18n.t('footer.terms_of_use'),
            to: '/s/terms-of-use',
            externalLink: false
          },
          {
            icon: 'icons-ic-play',
            label: i18n.t('footer.agb'),
            to: '/s/agb',
            externalLink: false
          }
        ]
      }
    ]

    const openOneTrust = () => {
      if (window.Optanon) {
        const Optanon = window.Optanon
        Optanon.ToggleInfoDisplay()
      }
    }
    const seoPageGroups = computed(() => {
      const seoPages: SeoPage[] = (unref(landingPages) || []).map(function (
        page: any
      ) {
        const slug =
          page.lang === 'default'
            ? page.full_slug.replace('seo/', '')
            : page.full_slug.replace(`${page.lang}/seo/`, '')

        return {
          slug,
          name: page.name,
          lang: page.lang,
          groupName: slug.startsWith('c/')
            ? i18n.t('footer.seo_pages.our_categories')
            : i18n.t('footer.seo_pages.our_brands')
        }
      })

      return groupBy(seoPages, page => page.groupName || '')
    })

    onFetchAsync(async () => {
      await fetchByFolder({
        folder: 'seo/',
        callingComponent: 'default.vue (Layout)',
        route: route.value.fullPath,
        cacheOptions: {
          ttl: 60
        },
        queryOptions: {
          'filter_query[landing][is]': 'true'
        }
      })
    })

    return {
      footerLinks,
      openOneTrust,
      seoPageGroups: computed(() => {
        return Object.entries(seoPageGroups.value).map(
          ([groupName, items]) => ({groupName, items})
        )
      }),
      fakeColumms: computed(() => {
        const seoPageGroupsCount = Object.keys(seoPageGroups.value).length
        return Array(Math.max(TARGET_COLUMN_COUNT - seoPageGroupsCount, 0)).map(
          (_, idx) => idx
        )
      })
    }
  }
})
