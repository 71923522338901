import {Context} from '@nuxt/types'
import {Inject} from '@nuxt/types/app'
import helpers from '~/helpers'

declare module 'vue/types/vue' {
  interface Vue {
    $helpers: typeof helpers
  }
}

export default (_ctx: Context, inject: Inject) => {
  inject('helpers', helpers)
}
