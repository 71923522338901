import { SortValue } from '@scayle/storefront-nuxt2'

export const state = () => ({
  selectedSort: {} as SortValue,
})
export type SortingState = ReturnType<typeof state>
export const getters = {
  selectedSort: (state: SortingState) => state.selectedSort,
}
export const mutations = {
  SET_SELECTED_SORT(state: SortingState, sort: SortValue) {
    state.selectedSort = sort
  }
}
export const actions = {
  setSelectedSortOption(context: any, sort: SortValue) {
    context.commit('SET_SELECTED_SORT', sort)
  }
}
