import { CustomStoreConfig } from "../lib/type"
import { grPaymentProviders } from '../lib/paymentProviders'
import { domains } from "../lib/domains"
import {appKeys} from "../lib/app-keys"

const GR: CustomStoreConfig = {
  // Using 'el' instead of 'gr' correctly resolves the uppercase character - removing accents
  code: 'el',
  shopId: 5212,
  domain: domains.el,
  appKeys,
  locale: 'el-GR',
  currency: 'EUR',
  internationalPrefix: '',
  paymentProviders: grPaymentProviders,
  orderPrefix: 'aogr',
  lowest30DaysPriceEnabled: true,
  allProductsHaveOwnStockAttribute: true,
  enableReturns: true,
  showDeliveryEstimates: false
}

export default GR
