import {useRpc} from '@scayle/storefront-nuxt2'
import {useContext} from '@nuxtjs/composition-api'
import {GetCmsContentOptions} from '~/rpcMethods'
import {STORYBLOK_CACHE_KEY} from '~/@types/cms/blok'

export const cmsCacheKeys = {
  deals: 'deals',
  appProducts: 'allProducts'
}

export const useCms = (key = 'useCms') => {
  const {$cache} = useContext()
  const {data, fetch: _fetchBySlug, fetching} = useRpc('getCmsContent', key)

  const fetchBySlug: typeof _fetchBySlug = async (params) => {
    try {
      const result = await _fetchBySlug(params)

      // TODO: Is this even used?
      $cache.addTags([
        STORYBLOK_CACHE_KEY,
        `${STORYBLOK_CACHE_KEY}:slug:${params?.slug}`,
        `${STORYBLOK_CACHE_KEY}:storyId:${data.value?.id}`
      ])

      return result;
    }
    catch (e) {
      data.value = null
    }
  }

  return {data, fetchBySlug, fetching}
}

export const useCmsStories = (key = 'useCmsStories') => {
  const {$cache} = useContext()
  const {data, fetch: _fetchByFolder, fetching} = useRpc('getStoriesByFolder', key)

  const fetchByFolder = async (params: GetCmsContentOptions) => {
    try {
      await _fetchByFolder(params)
      $cache.addTags([
        STORYBLOK_CACHE_KEY,
        `${STORYBLOK_CACHE_KEY}:folder:${params.folder}`
      ])
    }
    catch (error) {
      data.value = null
    }
  }

  return {data, fetchByFolder, fetching}
}
