import {
  BasketItem,
  Product,
  ProductListData,
  Variant
} from '@scayle/storefront-nuxt2'
import type {TrackingEvent, TrackingPayload} from '../types'
import { useContext } from '@nuxtjs/composition-api'
import { mapTrackingDataForEvent } from '../helpers'
import { useExperiment } from '~/composables/optimizely/useExperiment'

const hasProductAndVariant = (basketItem: BasketItem) =>
  basketItem.product && basketItem.variant

const collectBasketItems =
  (options: {listName: string; listId: string;}) =>
  (basketItem: BasketItem, index: number): ProductListData => {
    return {
      product: basketItem.product,
      list: {
        name: options.listName,
        id: options.listId,
        index: index + 1
      },
      quantity: basketItem.quantity
    }
  }

const useCheckoutEvents = () => {
  const {$gtm} = useContext()
  const track = (event: TrackingEvent, data: TrackingPayload) => $gtm.push(mapTrackingDataForEvent(event, data))
  const {trackExperimentEvent} = useExperiment()

  return {
    trackBeginCheckout: (
      items: BasketItem<Product, Variant>[] = [],
      listName: string,
      listId: string
    ) => {
      const basketItems = items
        .filter(hasProductAndVariant)
        .map(
          collectBasketItems({
            listName,
            listId
          })
        )
      track('begin_checkout', {
        items: basketItems
      })

      trackExperimentEvent({
        eventName: 'begin_checkout',
        quantity: basketItems.length
      })
    }
  }
}
export default useCheckoutEvents
