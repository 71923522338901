
import { defineComponent, PropType } from '@nuxtjs/composition-api'
import FadeInFromBottomTransition from '~/components/ui/transitions/FadeInFromBottomTransition.vue'

export default defineComponent({
  name: 'Popover',
  components: { FadeInFromBottomTransition },

  props: {
    isOpen: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
})
