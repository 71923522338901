import {Context} from "@nuxt/types";
import {datadogRum} from '@datadog/browser-rum'
import {LogEntry, ShopUser} from '@scayle/storefront-nuxt2'
import {cleanErrorData, enrichErrorData, getDatadogEnvironment, isUserInContext} from "~/modules/log/util";
import {decryptRC4} from "~/helpers/encryption";
import type {MonitorHandler} from "~/@types/log";
import {md5} from '@scayle/storefront-core/dist/utils/hash'
import {Locale} from '~/helpers/locale'

export const initDatadogRum = (context: Context) => {
  if (!context.$config.DD_RUM_TOKEN_SECURE || !context.$config.DD_RUM_APP_ID_SECURE || !context.env.PRIVATE_ENCRYPT_SIGN_KEY) {
    return;
  }

  const pageType = context.route.meta?.reduce(
    (pageType: string, meta: any) => meta.pageType || pageType,
    ''
  ) || ''

  const getSessionSampleRate = () => {
    return context.$config.isProduction ? +context.env.DD_RUM_SESSIONS_RECORDED : 100
  }

  datadogRum.init({
    applicationId: decryptRC4(context.$config.DD_RUM_APP_ID_SECURE, context.env.PRIVATE_ENCRYPT_SIGN_KEY),
    clientToken: decryptRC4(context.$config.DD_RUM_TOKEN_SECURE, context.env.PRIVATE_ENCRYPT_SIGN_KEY),
    site: 'datadoghq.eu',
    service:'ay-outlet-web',
    env: getDatadogEnvironment(context.$config.shopSystem),
    version: context.$config.clientVersion,
    sessionSampleRate: getSessionSampleRate(),
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    trackFrustrations: true,
    defaultPrivacyLevel: 'mask-user-input',
    // https://docs.datadoghq.com/real_user_monitoring/browser/modifying_data_and_context/?tab=npm#override-default-rum-view-names
    trackViewsManually: true
  });

  extendRumWithUser(context);

  datadogRum.startView({
    name: pageType,
    service: 'ay-outlet-web',
    version: context.$config.clientVersion
  })

  // datadogRum.startSessionReplayRecording(); we do not replay sessions, too expensive for now
}

/**
 * Add user to the RUM session in case we have it in context
 * this means plugin is initiated when user is logged in.
 */
export const extendRumWithUser = (context: Context) => {
  if (isUserInContext(context)) {
    const user: ShopUser = (context as any).$sharedRefsMap.get('useUser-data').value.user;

    datadogRum.setUser({
      id: String(user.id),
      referenceKey: user.referenceKey,
      isGuest: user.isGuest,
      emailHash: md5(user.email || '')
    })
  }
}

export const applyDatadogRum = (context: Context, entry: LogEntry) => {
  if (!process.client) {
    return;
  }

  if (!context.$config.DD_RUM_TOKEN_SECURE || !context.$config.DD_RUM_APP_ID_SECURE || !context.env.PRIVATE_ENCRYPT_SIGN_KEY) {
    return;
  }

  // just log errors to datadog
  // DD defines on its own whether it's a network or custom error
  if (['debug', 'info', 'warn'].includes(entry.level)) {
    return;
  }

  datadogRum.addError(entry.message, enrichErrorData(context, cleanErrorData(entry.data)));
}

// datadogRum.addAction
export const trackCustomEvent: MonitorHandler = (context, eventName, eventOptions) => {
  if (!context.$config.DD_RUM_TOKEN_SECURE || !context.$config.DD_RUM_APP_ID_SECURE || !context.env.PRIVATE_ENCRYPT_SIGN_KEY) {
    return;
  }

  try {
    datadogRum.addAction(eventName, eventOptions)
  }
  catch (e) {
    console.error('datadog rum add action failed', e)
  }
}

export const setCustomAttribute = (attributeName: string, attributeValue: string | number) => {
  if (!process.client) {
    return;
  }

  datadogRum.setUserProperty(attributeName, attributeValue)
}
