import {
  getAttributeValue,
  getAttributeValueTuples,
  getFirstAttributeValue,
} from '@scayle/storefront-nuxt2'

export default {
  getAttributeValue,
  getAttributeValueTuples,
  getFirstAttributeValue,
}
