
import Logo from '../logo/Logo.vue';
import {defineComponent} from '@nuxtjs/composition-api';
import {useIsPage } from '~/composables/useIsPage';
import {useAppRedirectLink} from './useAppRedirectLink'

export default defineComponent({
  components: {
    Logo,
  },
  emits: ['continueInTheBrowser'],
  setup() {
    const {isOsp} = useIsPage()
    const enableContinueInTheBrowser = true

    const redirectLink = useAppRedirectLink()

    return {
      isOsp,
      enableContinueInTheBrowser,
      redirectLink
    }
  }
})
