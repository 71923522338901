import {useContext} from '@nuxtjs/composition-api'
import type { TrackExperimentOptions } from '~/@types/ab-tests';
import { useAbTestExperimentEvent } from '../tracking/events';

export const useExperiment = () => {
  const {store} = useContext()
  const {trackExperimentActivation} = useAbTestExperimentEvent()

  return {
    /**
     * @see {@link https://docs.developers.optimizely.com/experimentation-data/docs/event-api-getting-started#activate-users}
     */
    activate: async (experimentKey: string) => {
      const experiment = await store.dispatch('experiments/activate', experimentKey)

      if(experiment){
        trackExperimentActivation({
          name: experiment.flagKey,
          variant: experiment.variableKey,
          experimentKey: experiment.ruleKey
        });
      }
    },
    trackExperimentEvent: async (options: TrackExperimentOptions) => {
      store.dispatch('experiments/trackEvent', options)
    },
  }
};
