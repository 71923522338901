const middleware = {}

middleware['active-deals-middleware'] = require('../middleware/active-deals-middleware.ts')
middleware['active-deals-middleware'] = middleware['active-deals-middleware'].default || middleware['active-deals-middleware']

middleware['log-on-route'] = require('../middleware/log-on-route.ts')
middleware['log-on-route'] = middleware['log-on-route'].default || middleware['log-on-route']

middleware['page-type'] = require('../middleware/page-type.ts')
middleware['page-type'] = middleware['page-type'].default || middleware['page-type']

middleware['product-page-middleware'] = require('../middleware/product-page-middleware.ts')
middleware['product-page-middleware'] = middleware['product-page-middleware'].default || middleware['product-page-middleware']

export default middleware
