var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"flex flex-row border border-gray-300 text-sm text-primary"},[_c('NuxtLink',{class:{
      'lg:py-4 lg:pl-4': _vm.isBasketPage
    },attrs:{"to":_vm.link}},[_c('div',{staticClass:"relative flex h-full w-36 flex-col justify-center bg-gray-200"},[_c('div',{staticClass:"absolute left-0 bottom-0 z-10 flex flex-col gap-1 w-full",attrs:{"data-cy":"basket-item"}},[(_vm.price && _vm.price.appliedReductions.length)?_c('ProductReduction',{staticClass:"text-2xs whitespace-nowrap",attrs:{"price":_vm.price,"size":"xs","show-final-reduction-only":_vm.showSingleDiscount}}):_vm._e(),_vm._v(" "),(_vm.parsedDeal && _vm.isLive)?_c('DealCountdown',{staticClass:"px-1 whitespace-nowrap",attrs:{"show-icon":false,"start-date":_vm.parsedDeal.startDate,"end-date":_vm.parsedDeal.endDate,"no-padding":true,"font-bold":false,"text-size":"2xs","icon-size":"2","data-cy":"countdown"}}):_vm._e(),_vm._v(" "),(_vm.availableQuantity <= 5 && _vm.isBasketPage && !_vm.isSoldOut)?_c('p',{staticClass:"w-full bg-gray-300 text-center text-2xs font-bold"},[_vm._v("\n          "+_vm._s(_vm.$t('basket_card.only'))+" "+_vm._s(_vm.availableQuantity)+"\n          "+_vm._s(_vm.$t('basket_card.available'))+"\n        ")]):_vm._e()],1),_vm._v(" "),_c('NuxtImg',{staticClass:"object-cover",class:{'opacity-50': _vm.isSoldOut},attrs:{"src":_vm.imageHash,"alt":_vm.name,"background":"F4F4F4","height":"200"}})],1)]),_vm._v(" "),_c('div',{staticClass:"flex w-full flex-col justify-between p-4"},[_c('div',{staticClass:"flex flex-row justify-between text-xs"},[_c('p',{staticClass:"font-semibold lg:pt-2 lg:text-sm"},[_c('span',{staticClass:"uppercase",class:{'text-gray-300': _vm.isSoldOut}},[_vm._v("\n          "+_vm._s(_vm.name)+"\n        ")]),_vm._v(" "),_c('br'),_vm._v(" "),_c('span',{class:{'text-gray-300': _vm.isSoldOut}},[_vm._v(_vm._s(_vm.title))])]),_vm._v(" "),(_vm.isBasketPage && !_vm.isSoldOut)?_c('div',[_c('QuantityDropdown',{attrs:{"quantity":_vm.quantity,"available-quantity":_vm.availableQuantity},on:{"click:change-quantity":quantity =>
              _vm.$emit('click:change-quantity', {quantity})}})],1):(_vm.isBasketPage && _vm.isSoldOut)?_c('div',[_c('p',{staticClass:"text-red-700"},[_vm._v(_vm._s(_vm.$t('global.sold_out')))])]):_c('div',{on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$emit('click:delete')}}},[_c('Icon',{attrs:{"icon":"ic-close","size":"4"}})],1)]),_vm._v(" "),_c('div',{staticClass:"mt-2 flex flex-row justify-between"},[_c('div',{staticClass:"flex flex-row gap-2 text-2xs font-medium"},[_c('div',{class:_vm.isSoldOut ? 'text-gray-300' : 'text-gray-600'},[_c('p',[_vm._v(_vm._s(_vm.$t('basket_card.size_label'))+":")]),_vm._v(" "),(_vm.variant && _vm.variant.attributes && _vm.variant.attributes.cupsize)?_c('p',[_vm._v("\n            "+_vm._s(_vm.$t('basket_card.cup_size_label'))+":\n          ")]):_vm._e(),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.$t('basket_card.color_label'))+":")]),_vm._v(" "),(!_vm.isBasketPage)?_c('p',[_vm._v(_vm._s(_vm.$t('basket_card.quantity_label'))+":")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"font-bold",class:_vm.isSoldOut ? 'text-gray-300' : 'text-gray-600'},[_c('p',[_vm._v(_vm._s(_vm.size))]),_vm._v(" "),(_vm.variant && _vm.variant.attributes && _vm.variant.attributes.cupsize)?_c('p',[_vm._v("\n            "+_vm._s(_vm.cupSize)+"\n          ")]):_vm._e(),_vm._v(" "),_c('p',{staticClass:"break-words max-w-[120px]"},[_vm._v(_vm._s(_vm.color))]),_vm._v(" "),(!_vm.isBasketPage)?_c('p',[_vm._v(_vm._s(_vm.quantity))]):_vm._e()])])]),_vm._v(" "),_c('div',{staticClass:"flex",class:{
        'mt-1 justify-between': _vm.isBasketPage,
        'justify-end': !_vm.isBasketPage
      }},[(_vm.isBasketPage)?_c('div',{staticClass:"mt-8 flex cursor-pointer",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$emit('click:delete')}}},[_c('Icon',{attrs:{"icon":"ic-close","size":"4"}}),_vm._v(" "),_c('p',{staticClass:"ml-2 text-xs font-bold"},[_vm._v("\n          "+_vm._s(_vm.$t('basket_card.remove_label'))+"\n        ")])],1):_vm._e(),_vm._v(" "),(!_vm.isSoldOut)?_c('div',{staticClass:"flex flex-col items-end justify-end"},[(!_vm.showSingleDiscount)?_vm._l((_vm.prices.oldPrices),function(oldPrice,index){return _c('div',{key:index,staticClass:"line-through text-xs pb-1"},[_vm._v("\n            "+_vm._s(_vm._f("formatCurrency")(_vm.toCurrency(oldPrice),_vm.$currentShop.locale))+"\n          ")])}):[(_vm.prices.originalPrice != _vm.prices.finalPrice)?_c('div',{staticClass:"line-through text-xs pb-1"},[_vm._v("\n            "+_vm._s(_vm._f("formatCurrency")(_vm.toCurrency(_vm.prices.originalPrice),_vm.$currentShop.locale))+"\n          ")]):_vm._e()],_vm._v(" "),_c('div',{staticClass:"bg-yellow px-2 py-1 rounded text-black text-md"},[_vm._v("\n          "+_vm._s(_vm._f("formatCurrency")(_vm.toCurrency(_vm.prices.finalPrice),_vm.$currentShop.locale))+"\n        ")])],2):_vm._e()])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }