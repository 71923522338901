
import {defineComponent, PropType, ref} from '@nuxtjs/composition-api'
import {useCmsMarginsMixin} from "~/composables/cms/useCmsMarginsMixin";
import {Blok} from "~/@types/cms/blok";

export default defineComponent({
  name: 'StoryblokImage',
  props: {
    blok: {
      type: Object as PropType<Blok>,
      default: () => {}
    },
    attrs: {
      type: Object as () => any,
      required: true
    }
  },
  setup(props) {
    const src = ref(props.attrs.src)
    const alt = ref(props.attrs.alt)
    const title = ref(props.attrs.title)
    const {marginClasses} = useCmsMarginsMixin(props.blok)
    return {
      src,
      alt,
      title,
      marginClasses
    }
  }
})
