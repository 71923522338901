import {useContext} from '@nuxtjs/composition-api'
import {TrackingEvent, TrackingPayload} from '../types'
import {version} from '../../../package.json'
import {useTrackingCurrency} from '../useTrackingCurrency'
import {SHOP_CATEGORY} from '~/@types/category'

const SHOP_CATEGORIES: Record<SHOP_CATEGORY, string> = {
  m: 'male',
  f: 'female',
  k: 'kids',
  other: 'other'
}

const useShopEvents = (
  track: (event: TrackingEvent, payload: TrackingPayload) => any
) => {
  const {
    $currentShop: {shopId, locale}
  } = useContext()
  const currency = useTrackingCurrency()

  return {
    trackShopInit: (shopCategory: SHOP_CATEGORY) =>
      track('shop_init', {
        shop_currency: currency,
        shop_id: String(shopId),
        shop_gender: SHOP_CATEGORIES[shopCategory],
        shop_version: version,
        locale: locale.replace(/_/g, '-'),
        landing_page: '/',
        referrer: process.client ? window.document.referrer : ''
      })
  }
}

export default useShopEvents
