<template>
  <div class="header-categories flex flex-row gap-4 px-4 md:justify-center">
    <span
      v-for="headerCategory in headerCategoryLinks"
      :key="headerCategory.name"
      @click='trackShopInit(headerCategory.shopGender)'
    >
      <Link
        :to="headerCategory.to"
        class="py-3"
        :type="textSize"
        :is-active="headerCategory.isActive"
      >
      {{ headerCategory.name }}
    </Link>
    </span>
  </div>
</template>

<script>
import {
  defineComponent,
  computed,
  useContext,
  useRoute,
  unref
} from '@nuxtjs/composition-api'
import Link from '~/components/ui/links/Link.vue'
import useBreakpoints from '~/composables/ui/useBreakpoints'
import useTrackingEvents from '~/composables/tracking/useTrackingEvents'

export default defineComponent({
  name: 'HeaderCategories',
  components: {
    Link
  },
  setup() {
    const {i18n} = useContext()
    const breakpoint = useBreakpoints()
    const route = useRoute()
    const {trackShopInit} = useTrackingEvents()
    const headerCategoryLinks = computed(() => {
      const category = unref(route).params.category
      return [
        {
          name: i18n.t('navigation.f'),
          to: '/deals/f',
          isActive: category === 'f',
          shopGender: 'f'
        },
        {
          name: i18n.t('navigation.m'),
          to: '/deals/m',
          isActive: category === 'm',
          shopGender: 'm'
        },
        {
          name: i18n.t('navigation.k'),
          to: '/deals/k',
          isActive: category === 'k',
          shopGender: 'k'
        }
      ]
    })
    return {
      headerCategoryLinks,
      trackShopInit,
      textSize: computed(() =>
        breakpoint.md.value ? 'normal-loud' : 'extra-normal-loud'
      )
    }
  }
})
</script>
