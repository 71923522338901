
import {
  computed,
  defineComponent,
  onBeforeMount,
  onMounted,
  onUnmounted,
  ref,
  unref,
  useContext,
  useRoute,
  useStore,
  watch
} from '@nuxtjs/composition-api'
import {onFetchAsync, useRpc, useUserWithParams} from '@scayle/storefront-nuxt2'
import HeaderLoggedIn from '~/components/layout/headers/HeaderLoggedIn.vue'
import HeaderLoggedOut from '~/components/layout/headers/HeaderLoggedOut.vue'
import PageFooter from '~/components/layout/footers/Footer.vue'
import useTrackingEvents from '~/composables/tracking/useTrackingEvents'
import addOneTrustScript from '~/helpers/onetrust'
import {useCmsStories} from '~/composables/useCms'
import Banner from '~/components/ui/Banner.vue'
import FadeInTransition from '~/components/ui/transitions/FadeInTransition.vue'
import {isNowBetween} from '~/helpers/date'
import AuthGuard from '~/components/auth/AuthGuard.vue'
import {useHeaderOffset} from '~/composables/ui/useHeaderOffset'
import {useBankAccount} from '~/composables/useBankAccount'
import {useLayoutMeta} from '~/composables/useLayoutMeta'
import {getCustomerType} from '~/helpers/tracking'
import {sha256Email} from '~/composables/tracking/helpers'
import {useBasket} from '~/composables/useBasket'
import {extendRumWithUser} from '~/modules/log/loggers/datadog/datadog-rum'
import AppBanner from '~/components/layout/headers/AppBanner.vue'
import {SHOP_CATEGORY} from '~/@types/category'
import Toasts from '~/components/ui/toast/Toasts.vue'
import {useToasts} from '~/composables/useToasts'
import AuthenticationModal from '~/components/auth/AuthenticationModal.vue'
import SignInToast from '~/components/ui/toast/SignInToast.vue'
import { useConcatenatedExperiments } from '~/composables/optimizely/useConcatenatedExperiments'
import { useAbTestExperimentEvent } from '~/composables/tracking/events'
import RedirectToApp from '~/components/deeplink/RedirectToApp.vue'
import { useIsPage } from '~/composables/useIsPage'

export default defineComponent({
  name: 'DefaultLayout',
  components: {
    AuthenticationModal,
    HeaderLoggedIn,
    HeaderLoggedOut,
    AuthGuard,
    PageFooter,
    Banner,
    FadeInTransition,
    AppBanner,
    SignInToast,
    Toasts,
    RedirectToApp
  },
  setup() {
    const {headerOffset} = useHeaderOffset()
    const {$config, $cookies, i18n, $initBraze, ...restContext} = useContext()
    const {isLive, domains, oneTrustIds} = $config
    const {trackShopInit, track} = useTrackingEvents()
    const {isLoggedIn, user} = useUserWithParams({})
    const {fetch: loadUser} = useRpc('getUser', 'useUser')
    const store = useStore()
    const {data: basketData, fetch: fetchBasket} = useBasket()
    const {data: bannerData, fetchByFolder} = useCmsStories('banners')
    const route = useRoute()
    const {fetchAndUpdateEligibilityStatus} = useBankAccount()
    const {trackAbTestAssignments} = useAbTestExperimentEvent()
    const concatenatedExperiments = useConcatenatedExperiments()
    const {setToastsFromCookies} = useToasts()
    const {isOsp, isCheckoutWithoutOsp} = useIsPage()

    const hideHeaderAndFooter = computed(() => (store.state as any).hideHeaderAndFooter)

    useLayoutMeta()
    const userHasScrolled = ref(false)

    const bannerForActiveLocale = computed(() => {
      if (!bannerData || !bannerData.value) return null
      return bannerData.value.find((banner: any) =>
        banner.content.countries.includes(i18n.locale)
      )
    })

    const bannerDates = computed(() => {
      return {
        start: bannerForActiveLocale.value.content.start_date_time,
        end: bannerForActiveLocale.value.content.end_date_time
      }
    })

    const showBanner = computed(() => bannerActive.value)

    const scrollHandler = () => {
      userHasScrolled.value = window.scrollY > 0
    }

    const bannerActive = computed(() => {
      if (!bannerForActiveLocale.value) return false
      return isNowBetween(bannerDates.value.start, bannerDates.value.end)
    })

    onFetchAsync(async () => {
      await Promise.all([
        loadUser(),
        fetchByFolder({
          folder: 'banners',
          callingComponent: 'default.vue (Layout)',
          route: route.value.fullPath,
          cacheOptions: {
            ttl: 0
          }
        })
      ])

      // we update cookie expiration date or gender in case it is set already on SSR
      if (user.value && $cookies.get('ayc-user')) {
        const userCookie = {
          gender: user.value.gender,
          isAuthenticated: true
        }

        $cookies.set('ayc-user', JSON.stringify(userCookie), {
          path: '/',
          sameSite: 'lax',
          secure: true,
          maxAge: 60 * 60 * 24 * 30 // 30 days
        })
      }
    })

    const setPageId = () => store.dispatch('setPageId', route?.value.params)

    onBeforeMount(() => {
      setPageId()
      const hasPendingRedirect = (store.state as any).redirectBackToApp
      // Hide consent prompt when embeded by the App, on /checkout/* pages
      if(!hasPendingRedirect && !unref(isCheckoutWithoutOsp)) {
        const host = window.location.host
        addOneTrustScript(isLive, host, oneTrustIds, domains, i18n.locale)
      }

      trackShopInit((route.value.params.category ?? 'other') as SHOP_CATEGORY)

      trackAbTestAssignments()

      if(user && user.value && user.value.id && user.value.createdAt) {
        track('customer_data', {
          customer_id: String(user.value?.id || ''),
          customer_type: getCustomerType(user.value),
          login: !!user.value,
          method: user.value ? 'email' : 'none',
          eh: sha256Email(user.value.email),
          abtest: unref(concatenatedExperiments)
        })
      }

    })

    onMounted(() => {
      fetchBasket()

      // do the initial request
      // and if user is not eligible for COD
      // set the appropriate flag before all pages are loaded
      if (isLoggedIn.value) {
        // @ts-ignore
        extendRumWithUser(restContext)
        fetchAndUpdateEligibilityStatus()
        $initBraze()
      }

      window.addEventListener('scroll', scrollHandler)
      scrollHandler()

      setToastsFromCookies()
    })

    onUnmounted(() => {
      window.removeEventListener('scroll', scrollHandler)
    })

    watch(route, () => {
      setPageId()

      // attempt to load OneTrust if it wasn't loaded yet
      if(!unref(isCheckoutWithoutOsp)) {
        const host = window.location.host
        addOneTrustScript(isLive, host, oneTrustIds, domains, i18n.locale)
      }
    })

    watch(isLoggedIn, () => {
      // user was logged out on initial request,
      // we should check if user has bank account
      if (isLoggedIn.value) {
        fetchAndUpdateEligibilityStatus()
        $initBraze()
      }
    })

    watch(user, (oldUser, newUser) => {
      if(user && user.value && user.value.id && user.value.createdAt && oldUser?.id !== newUser?.id) {
        track('customer_data', {
          customer_id: String(user.value?.id || ''),
          customer_type: getCustomerType(user.value, "existing"),
          login: !!user.value,
          method: user.value ? 'email' : 'none',
          eh: sha256Email(user.value.email),
          abtest: unref(concatenatedExperiments)
        })
      }
    })

    return {
      hideHeaderAndFooter,
      bannerForActiveLocale,
      showBanner,
      isLoggedIn,
      headerOffset
    }
  },
  head: {}
})
