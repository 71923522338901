
import {computed, defineComponent, useContext, useRoute} from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'References',
  setup() {
    const {i18n, $currentShop} = useContext()
    const route = useRoute()

    let now = new Date()
    const preBlackFriday = [
      new Date('Tue Nov 15 2022 08:00:00 GMT+0100'),
      new Date('Mon Nov 21 2022 08:00:00 GMT+0100')
    ]
    const blackFriday = [
      new Date('Thu Nov 24 2022 08:00:00 GMT+0100'),
      new Date('Mon Nov 28 2022 08:00:00 GMT+0100')
    ]
    const blackFridayCountries = ['de', 'at'].includes(i18n.locale)

    // Allows to debug the disclaimer message with ?bgDiscailerDebug={{timestamp}} parameter
    const debugDisclaimer = route.value.query.bfDisclaimerDebug
    if (debugDisclaimer && !Array.isArray(debugDisclaimer)) {
      now = new Date(debugDisclaimer)
    }

    return {
      enableLowestPrice: $currentShop.lowest30DaysPriceEnabled,
      hasDualCurrency: computed(() => {
        return !!$currentShop.secondCurrency
      }),
      showBlackFridayDisclaimer:
        debugDisclaimer ||
        (blackFridayCountries &&
          ((now >= preBlackFriday[0] && now < preBlackFriday[1]) ||
            (now >= blackFriday[0] && now < blackFriday[1])))
    }
  }
})
