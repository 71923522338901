
import { defineComponent, PropType } from '@nuxtjs/composition-api'
import LinkBadge from '~/components/ui/links/LinkBadge.vue'

export default defineComponent({
  name: 'Link',
  components: {
    LinkBadge,
  },
  props: {
    to: {
      type: [String, Object] as PropType<string | object>,
      required: true,
    },
    icon: {
      type: String as PropType<string>,
      default: '',
    },
    badge: {
      type: [Number, String] as PropType<number | string>,
      default: undefined,
    },
    onlyExactActive: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    highlightActive: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    hover: {
      type: String as PropType<string>,
      default: 'bold',
      validator: (val: string) => ['bold', 'underline'].includes(val),
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'default',
      validator: (val: string) =>
        [
          'default',
          'loud',
          'extra-loud',
          'whisper',
          'small',
          'normal',
          'normal-loud',
          'extra-normal-loud',
          'productListLink',
        ].includes(val),
    },
  },
})
