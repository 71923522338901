import {Context} from "@nuxt/types";
import {getFirstAttributeValue} from '@scayle/storefront-nuxt2'
import axios from "axios";
import {getGenderParamFromUser} from "~/helpers/user"
import {TOAST_COOKIE_NAME} from '~/helpers/constants'
import {AYO_ATTR_PUBLIC} from "~/@types/attributes";

const getProductIdFromPathOrParams = (route: Context['route']) => {
  if (route.path.includes('/p/co/co-')) {
    return route.path.replace('/p/co/co-', '');
  }

  return route.params.id;
}

const normalizeLabel = (str: string) =>
  str.replace(/ /g, '-').replace(/'/g, '').toLowerCase()

const redirectIfSoldOutProduct = (context: Context, data: any) => {
  const {isSoldOut} = data

  if (!isSoldOut) {
    return;
  }

  const user = context.app.$cookies.get('ayc-user')
  const infoMessage = {
    content: {
      title: 'infoMessages.product_sold_out'
    },
    type: 'info'
  }
  context.app.$cookies.set(
    TOAST_COOKIE_NAME,
    JSON.stringify(infoMessage)
  )

  context.redirect(
    `/deals/${getGenderParamFromUser(user)}`,
    context.$helpers.route.strapQueryDownToMarketingParams(
      context.route.query
    )
  )
}

/**
 * This is the special case, since COv3 has product URLs in form of /p/co-co-{productId}
 * we need to redirect to our product page or do DOP in case products are sold out
 * we can fetch product data with product id in the URL,
 * before redirecting to our product page.
 */
const redirectFromCheckoutV3ProductPage = (context: Context, data: any, productId: string) => {
  const isCheckoutProductPage = context.route.path.includes('/p/co/co-') ?? false

  if (!isCheckoutProductPage) {
    return;
  }

  const brand = data.attributes?.brand
    ? normalizeLabel(data.attributes.brand.values.label)
    : 'brand'
  const name = data.attributes?.name
    ? normalizeLabel(data.attributes.name.values.label)
    : 'name'

  context.redirect(
    `/p/${brand}/${name}/${productId}`,
    context.$helpers.route.strapQueryDownToMarketingParams(
      context.route.query
    )
  )
}

const redirectToDealsOverviewPage = (context: Context) => {
  const user = context.app.$cookies.get('ayc-user')
  const infoMessage = {
    content: {
      title: 'infoMessages.something_went_wrong'
    },
    type: 'error'
  }

  context.app.$cookies.set(TOAST_COOKIE_NAME, JSON.stringify(infoMessage))

  context.redirect(
    `/deals/${getGenderParamFromUser(user)}`,
    context.$helpers.route.strapQueryDownToMarketingParams(
      context.route.query
    )
  )
}

export default async (context: Context) => {
  const shopId = context.$currentShop.shopId
  const currentShopUrl = `https://${context.$currentShop.domain}`
  const productId = getProductIdFromPathOrParams(context.route)

  const {$openProducts} = context as any

  try {
    const withAttributes = ['brand', 'name', AYO_ATTR_PUBLIC].join('|')
    const {data} = await axios.get(`${currentShopUrl}/bapi/products/${productId}?with=attributes:key(${withAttributes})&shopId=${shopId}`)

    const isOpenedProduct = getFirstAttributeValue(data.attributes, AYO_ATTR_PUBLIC)?.value

    if (isOpenedProduct) {
      if (!$openProducts.has(`product-is-opened-${productId}`)) {
        $openProducts.set(`product-is-opened-${productId}`, true)
      }
    }
    // apparently product was opened before and it is not now
    else if ($openProducts.has(`product-is-opened-${productId}`)) {
      $openProducts.delete(`product-is-opened-${productId}`)
    }

    redirectIfSoldOutProduct(context, data);
    redirectFromCheckoutV3ProductPage(context, data, productId);
  }
  catch (error: any) {
    redirectToDealsOverviewPage(context);
  }
}
