
import {defineComponent, PropType} from '@nuxtjs/composition-api'
import FadeInTransition from '~/components/ui/transitions/FadeInTransition.vue'
import SlideInFromBottomTransition from '~/components/ui/transitions/SlideInFromBottomTransition.vue'

export default defineComponent({
  name: 'Modal',
  components: {
    FadeInTransition,
    SlideInFromBottomTransition
  },
  props: {
    isOpen: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    hasBackdrop: {
      type: Boolean as PropType<boolean>,
      default: true
    },
    removeBackdrop: {
      type: Boolean,
      default: false
    },
    backgroundColor: {
      type: String as PropType<string>,
      default: 'bg-white'
    },
    isCategoryModal: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    bodyClass: {
      type: String,
      default: 'lg:max-w-lg'
    }
  }
})
