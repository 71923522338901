var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"space-between mx-auto flex justify-around min-h-[calc(100vh-120px)] max-w-[720px] flex-col lg:min-h-[calc(100vh-364px)] md:pt-header"},[(_vm.lastLoggedInUser.email)?_c('LastLoggedInForm'):_c('AdvancedTabs',{staticClass:"px-2 pb-4",attrs:{"tabs":_vm.tabs,"tab-areas":['header']},scopedSlots:_vm._u([{key:"tabs-header",fn:function({activate, tab, isActive, isFirstTab, isLastTab}){return [_c('div',{staticClass:"border",class:{
          'border-gray-600': !isActive,
          'border-black bg-secondary-100': isActive,
          'rounded-t': isFirstTab,
          'rounded-b': isLastTab,
          'border-b-0': isFirstTab && !isActive,
          'border-t-0': isLastTab && !isActive,
        }},[_c('div',{staticClass:"cursor-pointer p-4 text-sm",class:{'font-bold': isActive},on:{"click":activate}},[_vm._v("\n          "+_vm._s(_vm.$t(tab.label))+"\n        ")]),_vm._v(" "),(isActive)?_c('div',{staticClass:"p-4"},[(tab.type === 'register')?_c('SignUpForm'):_vm._e(),_vm._v(" "),(tab.type === 'login')?_c('SignInForm'):_vm._e()],1):_vm._e()])]}}])}),_vm._v(" "),_c('LoginFooter',{attrs:{"show-legal-text":true,"is-modal-mode":_vm.isMobile}}),_vm._v(" "),_c('Modal',{attrs:{"is-open":_vm.showForgottenPassword,"body-class":"lg:max-w-[720px] lg:top-[-200px]","has-backdrop":true},on:{"close":function($event){return _vm.$router.push('/signin')}},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('ForgottenPasswordForm',{staticClass:"p-4 lg:p-8",on:{"success":function($event){return _vm.$router.push('/signin')},"error":function($event){return _vm.$router.push('/signin')}}})]},proxy:true}])}),_vm._v(" "),_c('Modal',{attrs:{"is-open":_vm.showUpdatePasswordByHash,"body-class":"lg:max-w-[720px] lg:top-[-200px]","has-backdrop":true},on:{"close":function($event){return _vm.$router.push('/signin')}},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('UpdatePasswordByHashForm',{staticClass:"p-4 lg:p-8"})]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }