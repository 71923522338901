
import {computed, defineComponent, useContext} from '@nuxtjs/composition-api'
import Icon from '~/components/ui/icons/Icon.vue'

export default defineComponent({
  name: 'PaymentList',
  components: {
    Icon
  },
  props: {
    hasColor: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: '14'
    },
    customClasses: {
      type: String
    }
  },
  setup(props) {
    const {$currentShop} = useContext()

    const iconsToLoad = computed(() => {
      if (props.hasColor) {
        return $currentShop.paymentProviders.map((icon: string) => icon + '-color')
      }
      else {
        return $currentShop.paymentProviders
      }
    })
    return {
      iconsToLoad
    }
  }
})
