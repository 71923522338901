
import {computed, defineComponent, ref} from '@nuxtjs/composition-api';
import {LinkAttributes} from '~/@types/rich-text-renderer-types';
export default defineComponent({
  name: 'SbLink',
  props: {
    attrs: {
      type: Object as () => LinkAttributes,
      required: true
    }
  },
  setup(props) {

    return {
      linkType: props.attrs.linktype,
      href: computed(() => {
        const anchor = props.attrs.anchor ? `#${props.attrs.anchor.replace(/^#/, '')}` : ''

        if(props.attrs.href && props.attrs.href.startsWith('mailto')) {
          return props.attrs.href
        }

        if(props.attrs.href && props.attrs.href.startsWith('www') ) {
          return `https://${props.attrs.href}${anchor}`
        }

        if(props.attrs.href && (props.attrs.href.startsWith('http') || props.attrs.href.startsWith('/'))) {
          return `${props.attrs.href}${anchor}`
        }

        return `/${props.attrs.href}${anchor}`
      }),
      target: computed(() => {
        if(props.attrs.linktype === 'asset' || (props.attrs.href && props.attrs.href.startsWith('http'))) {
          return '_blank'
        }

        return props.attrs.target || ''
      })
    };
  }
});
