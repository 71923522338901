import {Product} from '@scayle/storefront-nuxt2'
import {computed, unref, useContext, useRoute, useStore} from '@nuxtjs/composition-api';
import {getDeepestCategoryForTracking} from '../helpers'
import {
  TrackSelectItemEventParams,
  TrackViewItemListEventParams,
  TrackViewItemParams,
  TrackingEvent,
  TrackingPayload,
  ProductListData,
  ProductViewData
} from '../types'
import useCommonTrackingData from '../useCommonTrackingData';
import {useTrackingCurrency} from '../useTrackingCurrency';
import {useTrackExperimentEvent} from '~/composables/optimizely/useTrackExperimentEvent'

const collectProductListItems = (
  items: Product[],
  options: {listName: string; listId: string}
) => {
  return items.map(
    (product: Product, idx: number): ProductListData => ({
      product,
      list: {
        name: options.listName,
        id: options.listId,
        index: idx + 1
      }
    })
  )
}

const useProductEvents = (
  track: (event: TrackingEvent, payload: TrackingPayload) => any
) => {

  const currencyCode = useTrackingCurrency()
  const route = useRoute()
  const store = useStore()
  const {$helpers} = useContext();

  const pageType = store.getters.pageType
  const pageId = computed(() => store.getters.pageId || unref(route).params.id || unref(route).params.category || '')

  const commonTrackingData = useCommonTrackingData();
  const {trackExperimentEvent} = useTrackExperimentEvent()

  return {
    trackSelectItem: ({
      product,
      category,
      listingMetaData,
      index = -1, // index starts from 1
      variant,
      quantity = 1,
      soldOut = false,
      source,
      section
    }: TrackSelectItemEventParams & {section?: string}) => {
      const destUrl = $helpers.route.getProductDetailRoute(product);

      const payload: any = {
        product,
        destinationUrl: destUrl,
        destination: `product|${product.id}`,
        quantity,
        ...unref(commonTrackingData)
      }

      if (category) {
        payload.category = {
          name: category?.name || '',
          id: category?.id?.toString() || ''
        }
      }
      if (listingMetaData) {
        payload.list = {
          name: listingMetaData.name,
          id: listingMetaData.id
        }
      }

      if (variant) {
        payload.variant = variant
      }

      if (index > -1) {
        payload.list.index = index
      }

      if(source) {
        payload.source = source
      }
      else if(section && pageType && pageId) {
        payload.source = `${pageType}|${unref(pageId)}||${section}`
      }


      if (soldOut) {
        payload.sold_out = soldOut
      }

      track('select_item', {
        ...payload,
        currencyCode
      })

      trackExperimentEvent({
        eventName: 'product_click',
        quantity,
        value: variant?.price.withTax ?? 0
      })
    },
    trackViewItemList: ({
      items,
      listingMetaData,
      productIndex = -1,
      paginationOffset = 0,
      source,
      category,
      positionOffset = -1
    }: TrackViewItemListEventParams) => {
      const itemsToTrack =
        items?.map(
          (product: Product & {index: number}): ProductViewData => ({
            product,
            category: category || {
              name: getDeepestCategoryForTracking(product.categories).name,
              id: getDeepestCategoryForTracking(product.categories).id
            },
            list: {
              name: listingMetaData.name,
              id: listingMetaData.id,
              index:
                productIndex > -1
                  ? productIndex
                  : paginationOffset + (product.index + 1)
            },
            position:
              positionOffset > -1
                ? positionOffset + product.index + 1
                : undefined,
            destinationUrl: $helpers.route.getProductDetailRoute(product as Product),
            destination: `product|${product.id}`,
            source:
              source ||
              `category|${
                getDeepestCategoryForTracking(product.categories).id || ''
              }`,
            ...unref(commonTrackingData)
          })
        ) || []

      track('view_item_list', {
        items: itemsToTrack,
        currencyCode
      })
    },

    trackViewItem: ({product, quantity = 1, variant, openlyAccessible}: TrackViewItemParams) => {
      track('view_item', {
        product,
        quantity,
        variant,
        list: {
          index: 1,
          name: '',
          id: ''
        },
        currencyCode,
        openly_accessible: openlyAccessible,
        ...unref(commonTrackingData)
      })
    },
    collectProductListItems
  }
}

export default useProductEvents
