import {Context} from "@nuxt/types";
import {Inject} from "@nuxt/types/app";
import cookieConsent from "~/helpers/cookieConsent";

export default (context: Context, _inject: Inject) => {
  cookieConsent('GTM', ['ga'], new Map())
    .optIn(() => {
      context.$gtm.init(context.$config.gtm.id);
    })

}
