/**
 * Base User type with gender field only
 */
export interface GenderMixin {
  gender: 'f' | 'm' | 'd'
}
/**
 * We support 3 genders - f - female, m - make, and (new) d - diverse
 * Users cannot be of type - k - kids, this is only a top category.
 *
 * The diverse gender type needs to be redirected to the female category
 *
 * @param {GenderMixin | undefined} user
 * @returns {('f'|'m')}
 */
export const getGenderParamFromUser = (user: GenderMixin | undefined) => (user?.gender === 'm' ? 'm' : 'f')

/**
 * Returns phone number in 0000/1234567890 format
 * @param phone in +00 1234 567890 format {@link phoneNumberFormats}
 * @returns formatted phone number string
 */
export const getCheckoutFormattedPhone = (
  phone: string | undefined
): string | undefined => {
  if (!phone) {
    return phone
  }
  const inputPhoneParts = phone.split(' ')

  if (inputPhoneParts.length === 1) {
    return inputPhoneParts[0].includes('+') ? inputPhoneParts[0].replace('+', '00') : '00' + inputPhoneParts[0]
  }

  const countryCode = inputPhoneParts.shift();

  return `${countryCode!.includes('+') ? countryCode!.replace('+', '00') : '00' + countryCode}/${inputPhoneParts.map(part => part.trim()).join('')}`
}
