import { CustomStoreConfig } from "../lib/type"
import { lvPaymentProviders } from '../lib/paymentProviders'
import { domains } from "../lib/domains"
import {appKeys} from "../lib/app-keys"

const LV: CustomStoreConfig = {
  code: 'lv',
  shopId: 5214,
  domain: domains.lv,
  appKeys,
  locale: 'lv-LV',
  currency: 'EUR',
  internationalPrefix: '',
  paymentProviders: lvPaymentProviders,
  orderPrefix: 'aolv',
  lowest30DaysPriceEnabled: true,
  allProductsHaveOwnStockAttribute: true,
  enableReturns: true,
  showDeliveryEstimates: false
}

export default LV
