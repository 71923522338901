import { ref, readonly } from '@nuxtjs/composition-api'

type HeaderScrollLinkState = 'current' | 'upcoming'

const activeTab = ref<HeaderScrollLinkState>('current')

const useHeaderScrollLinks = () => {
  const setActiveTab = (state: HeaderScrollLinkState) => {
    activeTab.value = state
  }
  const resetActiveTab = () => {
    activeTab.value = 'current'
  }

  return {
    activeTab: readonly(activeTab),
    setActiveTab,
    resetActiveTab,
  }
}

export default useHeaderScrollLinks
