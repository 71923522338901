import {ProductImageType} from '@scayle/storefront-nuxt2'
import {GridOptions} from '~/@types/view'
export const state = () => ({
  view: 'bust',
  grid: GridOptions.Double
})
export type ViewState = ReturnType<typeof state>
export const getters = {
  viewType: (state: ViewState) => state.view,
  gridType: (state: ViewState) => state.grid
}
export const mutations = {
  SET_SELECTED_VIEW(state: ViewState, view: ProductImageType) {
    state.view = view
  },
  SET_SELECTED_GRID(state: ViewState, grid: GridOptions) {
    state.grid = grid
  }
}
export const actions = {
  setSelectedView(context: any, view: ProductImageType) {
    context.commit('SET_SELECTED_VIEW', view)
  },
  setSelectedGrid(context: any, grid: GridOptions) {
    context.commit('SET_SELECTED_GRID', grid)
  }
}
