import {useRpc} from '@scayle/storefront-nuxt2'
import {computed, unref, useContext} from '@nuxtjs/composition-api'
import {AVAILABLE_SHOP_IDS, SHOP_IDS} from 'store-config'

export const useOrders = (key = 'useOrders') => {
  const {$currentShop} = useContext()
  const {data, fetch, fetching} = useRpc('getOrders', key)

  return {data: computed(() => {
     return (unref(data) || []).filter(orderItem => orderItem.shopId === $currentShop.shopId)
    }),
    fetch,
    fetching
  }
}

/**
 * Count orders whether user made them on the AY Core shops or the AY Outlet shops
 *
 * @param {String} key defaults to 'useOrders'
 * @returns {'outlet_order_number': number, 'core_order_number': number}
 */
export const useOrdersCountByShopGroup = (key = 'useOrders') => {
  const {data, fetch, fetching} = useRpc('getOrders', key)

  return {
    data: computed(() => {
     return (unref(data) || []).reduce((acc, order) => {

      if(SHOP_IDS.includes(order.shopId as AVAILABLE_SHOP_IDS)) {
        return {
          ...acc,
          outletOrderNumber: acc.outletOrderNumber + 1
        }
      }

      return {
        ...acc,
        coreOrderNumber: acc.coreOrderNumber + 1
      }
     }, {
      outletOrderNumber: 0,
      coreOrderNumber: 0
     })
    }),
    fetch,
    fetching
  }
}
