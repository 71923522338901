// import {HashAlgorithm} from '@scayle/storefront-core'

export type APP_KEYS = {
  wishlistKey: string
  basketKey: string
  hashAlgorithm: 'md5'
}

export const appKeys: APP_KEYS = {
  wishlistKey: 'wishlist_{shopId}_{userId}',
  basketKey: 'basket_{shopId}_{userId}',
  hashAlgorithm: 'md5' // HashAlgorithm.MD5
}
