import {useContext} from '@nuxtjs/composition-api'
import {TrackingEvent, TrackingPayload} from './types'
import {mapTrackingDataForEvent, mapProductToTrackingPayload} from './helpers'
import {
  useBasketEvents,
  useShopEvents,
  useProductEvents,
  useUserActionEvents,
  useBasicEvents,
  useFilterEvents,
} from './events'

/**
 * Higher order composable which serves as a single entry point to unify different ecommerce tracking events
 */
const useTrackingEvents = () => {
  const {$gtm} = useContext()

  const track = (event: TrackingEvent, payload: TrackingPayload) => {
    const gtmEvent = mapTrackingDataForEvent(event, payload)
    $gtm.push(gtmEvent)
  }


  /**
   * NOTE: Important considerations:
   * sub-composable(s) such as useBasketEvents, etc should not be utilized directly
   * instead make use of the higher order composable (useTrackingEvents) to keep things clean
   * new events should be added to relevant sub-composable to ensure DRY code and separation of concerns
   */

  return {
    ...useShopEvents(track),
    ...useBasketEvents(track),
    ...useProductEvents(track),
    ...useUserActionEvents(track),
    ...useBasicEvents(track),
    ...useFilterEvents(track),
    // ...useCheckoutEvents(), // use directly
    // ...usePurchaseEvents(), // use directly
    // ...useAbTestExperimentEvent(), // use directly
    mapProductToTrackingPayload,
    track
  }
}

export default useTrackingEvents
