
import {computed, defineComponent, useStore} from '@nuxtjs/composition-api'
import Toast from '~/components/ui/toast/Toast.vue'
import {useHeaderOffset} from '~/composables/ui/useHeaderOffset'
import useDevice from '~/composables/ui/useDevice'
import {IToast} from '~/store/toasts'

export default defineComponent({
  name: 'Toasts',
  components: {
    Toast
  },
  setup() {
    const store = useStore()
    const {headerOffset} = useHeaderOffset()
    const {isDesktop} = useDevice()

    const toasts = computed<IToast[]>(
      () => store.getters['toasts/toasts']
    )

    const topOffset = computed(() => {
      // on mobile and tablet toast should to stick to bottom
      if (!isDesktop) {
        return 'auto'
      }

      // probably this is desktop and SSR - toast just sticks to top
      if (!headerOffset) {
        return 0
      }

      // apparently this is a really tiny desktop screen
      if (typeof window !== 'undefined' && window.innerWidth < 1024) {
        return 'auto'
      }

      return `${headerOffset.value}px`
    })

    const removeToast = (id: string) => {
      store.dispatch('toasts/removeToastById', id)
    }

    return {
      toasts,
      topOffset,
      removeToast
    }
  }
})
