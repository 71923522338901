
import {computed, defineComponent, onMounted, PropType, ref, unref, useContext, useStore, watch} from '@nuxtjs/composition-api'
import {ValidationObserver} from 'vee-validate'
import {AxiosError} from 'axios'
import {useLog} from '@scayle/storefront-nuxt2'
import Input from '~/components/ui/inputs/Input.vue'
import {useSession} from '~/composables/useSession'
import {useLastLoggedInUser} from '~/composables/useLastLoggedInUser'
import {useAuthenticationCallback} from '~/composables/useAuthenticationCallback'
import {SignInEvent} from '~/@types/log'
import {getErrorCode} from '~/helpers/tracking'
import type {IToastWithoutId} from '~/store/toasts'

interface User {
  email: string | null
  password: string | null
}

const EMPTY_USER: User = {
  email: null,
  password: null
}

export default defineComponent({
  name: 'SignInForm',
  components: {
    Input,
    ValidationObserver
  },
  props: {
    passwordForgetCallbackLink: {
      type: String as PropType<string>,
      default: '/signin'
    },
    skipPageReloadAfterLogin: {
      type: Boolean as PropType<boolean>,
      default: false
    }
  },
  setup(props) {
    const {$monitor, $network} = useContext()
    const log = useLog('SignInForm')
    const {lastLoggedInUser, setLastLoggedInUser} = useLastLoggedInUser()
    const {loginData, login} = useSession()
    const store = useStore()
    const {authenticated, authenticationFailed, trackAuthenticationSubmit} = useAuthenticationCallback('login', 'email', SignInEvent, "login")
    // const isLoggedIn = computed(() => {
    //   return !!(store.state as any).accessToken
    // })
    const isFetchingAndRefreshingUser = ref<boolean>(false)

    const editableUser = ref<User>(EMPTY_USER)

    const serverError = ref('')

    const toastMessages: Record<string, IToastWithoutId> = {
      success: {
        type: 'success',
        content: {
          title: 'login_page.signin_form.status.success.title',
          subtitle: 'login_page.signin_form.status.success.subtitle'
        }
      },
      error: {
        type: 'error',
        content: {
          subtitle: 'login_page.signin_form.status.error.title'
        }
      },
      genericError: {
        type: 'error',
        content: {
          subtitle: 'login_page.signin_form.status.generic_error.title'
        }
      }
    }

    const httpErrorMessages: Record<number, string> = {
      400: '400_bad_request',
      401: '401_unauthorized',
      404: '404_not_found'
    }

    const discardChanges = () => {
      editableUser.value = EMPTY_USER
    }

    const onSubmit = async () => {
      if(!editableUser.value.email || !editableUser.value.password) {
        return;
      }

      trackAuthenticationSubmit('login_page.signin_form.title')

      try {
        isFetchingAndRefreshingUser.value = true
        await $network.circuitBreaker('useSession - oauthLogin', login({...editableUser.value}))
        await authenticated({
          accessToken: loginData.value?.oauth?.access_token,
          skipPageReload: props.skipPageReloadAfterLogin
        })
        store.dispatch('toasts/enqueueToast', toastMessages.success)
        isFetchingAndRefreshingUser.value = false
      }
      catch (error) {
        isFetchingAndRefreshingUser.value = false
        await authenticationFailed(editableUser.value.email!)

        $monitor.criticalEvent(SignInEvent,{
          success: false,
          code: getErrorCode(error),
          message: 'User has failed to login',
          email: editableUser.value.email
        })

        if(error instanceof AxiosError && error.response?.status === 404) {
          await store.dispatch('toasts/removeToastById', store.getters['toasts/getLastToastId'])
          await store.dispatch('toasts/enqueueToast', toastMessages.error)
        }
        else {
          await store.dispatch('toasts/removeToastById', store.getters['toasts/getLastToastId'])
          await store.dispatch('toasts/enqueueToast', toastMessages.genericError)
        }

        if (error instanceof AxiosError) {
          if (error.response?.status && [400, 401, 404].includes(error.response?.status)) {
            serverError.value = `login_page.signin_form.status.error.${httpErrorMessages[error.response.status]}`
            return undefined;
          }
        }

        // not clear what the error is, just rollback changes and induce user to try again implicitly
        discardChanges()

        log.error(
          'v1/auth/login',
          {
            extra: {
              error
            }
          }
        )
      }
    }

    watch(lastLoggedInUser, () => {
      if(lastLoggedInUser.value.email !== editableUser.value.email) {
        editableUser.value = {email: lastLoggedInUser.value.email, password: ''}
      }
    })

    onMounted(() => {
      setLastLoggedInUser()
      if(lastLoggedInUser.value.email !== editableUser.value.email) {
        editableUser.value = {email: lastLoggedInUser.value.email, password: ''}
      }
    })

    return {
      user: editableUser,
      onSubmit,
      isFetchingAndRefreshingUser,
      serverError,
      passwordMinLength: 6
    }
  }
})
