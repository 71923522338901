import {useContext, useStore} from '@nuxtjs/composition-api'
import {TOAST_COOKIE_NAME} from '~/helpers/constants'

export const useToasts = () => {
  const {$cookies} = useContext()
  const store = useStore()

  const setToastsFromCookies = () => {
    let toastsCookie = $cookies.get(TOAST_COOKIE_NAME)

    if (!Array.isArray(toastsCookie)) {
      if (toastsCookie === undefined || !toastsCookie) {
        toastsCookie = []
      }
      else {
        toastsCookie = [toastsCookie]
      }
    }

    if (toastsCookie.length) {
      store.dispatch('toasts/setToasts', toastsCookie)
      $cookies.remove(TOAST_COOKIE_NAME)
    }
  }

  return {
    setToastsFromCookies
  }
}
