import Vue from 'vue';
import {
  SbDocument,
  SbHeading,
  SbParagraph,
  SbQuote,
  SbOrderedList,
  SbUnorderedList,
  SbListItem,
  SbCodeBlock,
  SbHorizontalRule,
  SbBreak,
  SbImage
} from '../elements/blocks';
import {Block} from '~/@types/rich-text-renderer-types';

export type BlockResolvers = Partial<Record<keyof typeof Block, typeof Vue>>

export const defaultBlockResolvers: BlockResolvers = {
  // @ts-ignore
  [Block.DOCUMENT]: SbDocument,
  [Block.HEADING]: SbHeading,
  [Block.PARAGRAPH]: SbParagraph,
  [Block.QUOTE]: SbQuote,
  [Block.OL_LIST]: SbOrderedList,
  [Block.UL_LIST]: SbUnorderedList,
  [Block.LIST_ITEM]: SbListItem,
  [Block.CODE]: SbCodeBlock,
  [Block.HR]: SbHorizontalRule,
  [Block.BR]: SbBreak,
  [Block.IMAGE]: SbImage
};
