import { CustomStoreConfig } from "../lib/type"
import { bgPaymentProviders } from "../lib/paymentProviders"
import { domains } from "../lib/domains"
import {appKeys} from "../lib/app-keys"

const BG: CustomStoreConfig = {
  code: 'bg',
  shopId: 5211,
  domain: domains.bg,
  appKeys,
  locale: 'bg-BG',
  currency: 'BGN',
  internationalPrefix: '',
  paymentProviders: bgPaymentProviders,
  orderPrefix: 'aobg',
  lowest30DaysPriceEnabled: true,
  allProductsHaveOwnStockAttribute: true,
  enableReturns: true,
  showDeliveryEstimates: false
}

export default BG
