import { onMounted, ref, watch, unref, useRoute } from "@nuxtjs/composition-api"

export const useAppRedirectLink = () => {
  const route = useRoute()
  const intent = 'ayo://'

  const getRedirectLink = () => {
      let hostname = `aboutyou-outlet.de`

      if(!process.client || typeof window === 'undefined') {
        const cbdToken = `${unref(route).query['cbd']}`
        const pathname = `${unref(route).path}`

        return `${intent}${hostname}/${mapToAppRoute(pathname)}?cbd=${cbdToken}`
      }

      hostname = location.hostname;
      const query = new URLSearchParams(location.search)
      const link = `${intent}${hostname}/${mapToAppRoute(location.pathname)}?${mapToQueryString(
        query
      )}`

      return link
  }
  const redirectLink = ref(getRedirectLink())

  onMounted(() => {
    redirectLink.value = getRedirectLink()
  })

  watch(route, () => {
    redirectLink.value = getRedirectLink()
  })

  return redirectLink
}

/**
 * Flutter App has implicit path names for some routes
 * which does not correspond to the web naming
 * Look into the generated route paths
 *
 * @see `https://gitlab.com/aboutyou/retail/aysa/about-you-outlet-services/ayo-app/-/blob/93dad459abb8ea5abccc431a2890399f7a8961fd/lib/navigation/routes/app_router.gr.dart#L2049`
 *
 */
const mapToAppRoute = (locationPathName: string) => {
  const pathname = locationPathName.replace(/^\//, '')
  if (pathname.startsWith('checkout')) {
    return `order-success`
  }

  return pathname // remove forward slash
}

const mapToQueryString = (query: URLSearchParams) => {
  return query.toString()
}
