import {DealData} from '~/@types/cms/dealData'
import {indexBy} from '~/helpers/array'
import {isNowBetween, parseDateStringFromStoryblok} from '~/helpers/date'

export const state = () => ({
  deals: [] as DealData[],
  viewedDeals: [] as string[],
  expirations: [] as Array<any>
})

export type DealsState = ReturnType<typeof state>
type DealFilterFunction = (deal: DealData) => boolean

const dealBelongsToCategory =
  (category: string): DealFilterFunction =>
  deal =>
    deal.default_full_slug.split('/')[1] === category

const dealHasSlug =
  (slug: string): DealFilterFunction =>
  deal =>
    deal.slug + '' === slug

const dealHasNotSlugs =
  (slugs: string[]): DealFilterFunction =>
  deal =>
    !slugs.includes(deal.slug + '')

const dealIsVisible = (): DealFilterFunction => deal => {
  const {announcementDate, endDate} = deal.content
  return isNowBetween(announcementDate, endDate)
}

const dealIsActive = (): DealFilterFunction => deal => {
  const {startDate, endDate} = deal.content
  return isNowBetween(startDate, endDate)
}

const dealIsUpcoming = (): DealFilterFunction => deal => {
  const {announcementDate, startDate} = deal.content
  return isNowBetween(announcementDate, startDate)
}

export const getters = {
  deals: (state: DealsState) => state.deals,
  viewedDeals: (state: DealsState) => state.viewedDeals,

  dealWithSlug: (state: DealsState) => (slug: string) =>
    state.deals.find(dealHasSlug(slug)),

  visibleDeals: (state: DealsState) => (category: string) =>
    state.deals.filter(dealBelongsToCategory(category)).filter(dealIsVisible()),

  activeDeals: (state: DealsState) => (category: string) =>
    state.deals.filter(dealBelongsToCategory(category)).filter(dealIsActive()),

  upcomingDeals: (state: DealsState) => (category: string) =>
    state.deals
      .filter(dealBelongsToCategory(category))
      .filter(dealIsUpcoming()),

  furtherDealsInCategory:
    (state: DealsState) => (category: string, slug: string) =>
      state.deals
        .filter(dealBelongsToCategory(category))
        .filter(dealIsActive())
        .filter(dealHasNotSlugs([...state.viewedDeals, slug])),
  expirations: (state: DealsState) => {
    const now = new Date()

    const activeDeals = state.expirations.filter(dealData => {
      const startDate = parseDateStringFromStoryblok(dealData.startDate)
      return now > new Date(startDate!)
    })

    return indexBy(activeDeals, 'id')
  },
  upcoming: (state: DealsState) => {
    const now = new Date()

    const upcomingDeals = state.expirations.filter(dealData => {
      const startDate = parseDateStringFromStoryblok(dealData.startDate)
      return new Date(startDate!) > now
    })

    return indexBy(upcomingDeals, 'id')
  }
}

export const mutations = {
  SET_DEALS: (state: DealsState, deals: DealData[]) => (state.deals = deals),
  ADD_VIEWED_DEAL: (state: DealsState, id: string) => {
    if (state.viewedDeals.includes(id)) return
    state.viewedDeals.push(id)
  },
  ADD_DEAL_EXPIRATION: (state: DealsState, expirations: Array<any>) => {
    state.expirations.push(...expirations)
  },
  RESET_DEAL_EXPIRATIONS: (state: DealsState) => {
    state.expirations = []
  }
}

export const actions = {
  addViewedDeal(context: any, id: string) {
    context.commit('ADD_VIEWED_DEAL', id)
  },
  addDealExpirations: (context: any, expirations: Array<any>) => {
    context.commit('ADD_DEAL_EXPIRATION', expirations)
  }
}
