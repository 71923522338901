
import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'
import { NuxtLink } from '~/@types/NuxtLink'

export default defineComponent({
  name: 'Logo',
  props: {
    linkTo: {
      type: [Object, String] as PropType<string | NuxtLink>,
      default: '/',
    },
    color: {
      type: String as PropType<'black' | 'white'>,
      default: 'black',
    },
    size: {
      type: String as PropType<'small' | 'medium'>,
      default: 'small',
    },
  },
  setup({ color, size }) {
    return {
      fillColor: computed(() => (color === 'white' ? '#fff' : '#000')),
      height: computed(() => (size === 'small' ? '22' : '24')),
    }
  },
})
