import Vue from 'vue'
import {formatDateTime} from '~/helpers/date'

Vue.filter('formatDateToWDM', (dateStr: string, locale: string = 'de-DE') =>
  Intl.DateTimeFormat(locale, {
    weekday: 'short',
    day: '2-digit',
    month: '2-digit',
  }).format(new Date(dateStr))
)

Vue.filter('formatDateToDM', (dateStr: string, locale: string = 'de-DE') =>
  Intl.DateTimeFormat(locale, {
    day: '2-digit',
    month: '2-digit',
  }).format(new Date(dateStr))
)

Vue.filter('formatDateToDMY', (dateStr: string, locale: string = 'de-DE') =>
  Intl.DateTimeFormat(locale, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  }).format(new Date(dateStr))
)

Vue.filter('formatDateTime', formatDateTime)
