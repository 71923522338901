
import {
  defineComponent,
  ref,
  PropType,
  computed,
  onBeforeUnmount
} from '@nuxtjs/composition-api'
import {useHeaderOffset} from '~/composables/ui/useHeaderOffset'
import SlideInFromTopTransition from '~/components/ui/transitions/SlideInFromTopTransition.vue'
import Icon from '~/components/ui/icons/Icon.vue'
import useDevice from '~/composables/ui/useDevice'

const DEFAULT_DELAY_IN_MS = 4000

export interface ToastMessage {
  bg?: 'success' | 'error' | 'info'
  title?: string
  subtitle?: string
}

export default defineComponent({
  name: 'Toast',
  components: {
    Icon,
    SlideInFromTopTransition
  },
  props: {
    delayInMs: {
      type: Number,
      default: DEFAULT_DELAY_IN_MS
    },
    bg: {
      type: String as PropType<'success' | 'error' | 'info'>,
      default: 'success'
    },
    isAutoClose: {
      type: Boolean,
      default: true
    }
  },
  emits: ['onExpire', 'onClose'],
  setup(props, {emit}) {
    const {headerOffset} = useHeaderOffset()
    const timeout = ref(0)
    const {isDesktop} = useDevice()

    const topOffset = computed(() => {
      // on mobile and tablet toast should to stick to bottom
      if (!isDesktop) {
        return 'auto'
      }

      // probably this is desktop and SSR - toast just sticks to top
      if (!headerOffset) {
        return 0
      }

      // apparently this is a really tiny desktop screen
      if (typeof window !== 'undefined' && window.innerWidth < 1024) {
        return 'auto'
      }

      return `${headerOffset.value}px`
    })

    const textColor = computed(() => {
      return props.bg === 'error' ? 'white' : 'black'
    })

    const close = () => {
      clearTimeout(timeout.value)
      emit('onClose')
    }

    onBeforeUnmount(() => {
      clearTimeout(timeout.value)
    })

    if (props.isAutoClose && typeof window !== 'undefined') {
      timeout.value = window.setTimeout(() => {
        emit('onExpire')
      }, props.delayInMs)
    }

    return {
      headerOffset,
      close,
      textColor,
      topOffset
    }
  }
})
