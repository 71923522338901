import {useBreakpoint} from "./useBreakpoint";

export default () => {
  return useBreakpoint({
    md: 768,
    lg: 1024,
    xl: 1280,
    xxl: 1440
  })
}
