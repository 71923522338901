var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.disabled)?_c('div',[_vm._t("default")],2):_c('nuxt-link',{staticClass:"whitespace-no-wrap inline-flex items-center gap-2 leading-5 tracking-wide transition duration-200 ease-linear",class:{
    'text-gray-700': _vm.type === 'whisper',
    uppercase: _vm.type === 'loud',
    'font-bold uppercase': _vm.type === 'extra-loud',
    'hover:underline': _vm.hover === 'underline',
    'hover:text-shadow': _vm.hover === 'bold',
    'text-sm': _vm.type === 'small',
    'text-lg text-gray-500': _vm.type === 'normal-loud',
    'text-2xl text-gray-500': _vm.type === 'extra-normal-loud',
    'text-sm font-medium text-gray-600': _vm.type === 'normal',
    'text-white underline underline-offset-1': _vm.type === 'productListLink',
    'nuxt-link-active': _vm.isActive,
  },attrs:{"active-class":!_vm.onlyExactActive && _vm.highlightActive ? 'nuxt-link-active' : '',"exact-active-class":_vm.onlyExactActive && _vm.highlightActive ? 'nuxt-link-exact-active' : '',"to":_vm.to}},[_vm._t("default"),_vm._v(" "),_vm._t("badge",function(){return [_c('transition',{attrs:{"enter-class":"opacity-0","enter-active-class":"transition-all duration-200 ease-linear","enter-to-class":"opacity-100","leave-active-class":"transition-all duration-200 ease-linear","leave-class":"opacity-100","leave-to-class":"opacity-0"}},[(_vm.badge !== undefined)?_c('LinkBadge',[_vm._v(_vm._s(_vm.badge))]):_vm._e()],1)]},{"badge":_vm.badge})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }