
import {computed, defineComponent, PropType} from '@nuxtjs/composition-api'
import Icon from '~/components/ui/icons/Icon.vue'
import {Blok} from "~/@types/cms/blok";

export default defineComponent({
  components: {Icon},
  props: {
    blok: {
      type: Object as PropType<Blok>,
      default: () => {}
    },
    index: {
      type: Number,
      required: true
    },
    openedIndex: {
      type: Number,
      default: -1
    }
  },
  setup(props, {emit}) {
    const toggleAccordion = () => {
      const value = props.index === props.openedIndex ? -1 : props.index
      emit('change-accordion', value)
    }
    return {
      isOpen: computed(() => props.index === props.openedIndex),
      toggleAccordion
    }
  }
})
