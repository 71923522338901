
import {
  computed,
  defineComponent,
  onMounted,
  useContext,
  useRouter
} from '@nuxtjs/composition-api'
import {
  BasketItem,
  toCurrency,
  useLog,
} from '@scayle/storefront-nuxt2'
import Popover from '../ui/popovers/Popover.vue'
import useUiState from '~/composables/ui/useUiState'
import CustomLink from '~/components/ui/links/Link.vue'
import Icon from '~/components/ui/icons/Icon.vue'
import BasketCard from '~/components/basket/BasketCard.vue'
import {useBasket} from '~/composables/useBasket'

export default defineComponent({
  name: 'BasketPopover',
  components: {
    Popover,
    CustomLink,
    Icon,
    BasketCard
  },
  setup() {
    const {$currentShop} = useContext()
    const log = useLog('BasketPopover')
    const {
      removeItem,
      basketItems,
      fetch: fetchBasketWithDeals,
      summary: {totalPriceWithTax}
    } = useBasket()
    const {openBasketFlyout, closeBasketFlyout, isBasketFlyoutOpen} =
      useUiState()

    const router = useRouter()

    const basketCount = computed(() => basketItems.value?.length || 0)

    const onClickToBasket = () => {
      router.push('/basket')
      closeBasketFlyout()
    }

    const onClickToCheckout = () => {
      router.push('/checkout')
      closeBasketFlyout()
    }

    const onClickToDeals = () => {
      router.push('/')
      closeBasketFlyout()
    }

    const removeItemFromBasket = async (basketItem: BasketItem) => {
      try {
        await removeItem(basketItem)
      } catch (e) {
        log.error('removeItem', {
          extra: {
            error: e
          }
        })
      }
    }

    onMounted(async () => {
      try {
        await fetchBasketWithDeals()
      } catch (e) {
        log.error('fetchBasket', {
          extra: {
            error: e
          }
        })
      }
    })

    const hasItemsInBasket = computed(() => {
      return (basketItems.value?.length ?? 0) > 0
    })

    return {
      enableDoubleStrikePrice: computed(
        () => $currentShop.enableDoubleStrikePrice
      ),
      basketItems,
      hasItemsInBasket,
      totalPriceWithTax,
      basketCount,
      openBasketFlyout,
      closeBasketFlyout,
      isBasketFlyoutOpen,
      removeItem,
      onClickToBasket,
      removeItemFromBasket,
      onClickToDeals,
      onClickToCheckout,
      toCurrency
    }
  }
})
