import {BasicTrackingData, TrackingEvent, TrackingPayload} from '../types'

const useBasicEvents = (
  track: (event: TrackingEvent, payload: TrackingPayload) => any
) => ({
  // eslint-disable-next-line camelcase
  trackFeature: ({action, label, name, status, content_name, page_type, page_type_id}: BasicTrackingData) =>
    track('feature', {
      action,
      label,
      name,
      status,
      // eslint-disable-next-line camelcase
      content_name,
      // eslint-disable-next-line camelcase
      page_type,
      // eslint-disable-next-line camelcase
      page_type_id: page_type_id || ""
    })
})

export default useBasicEvents
