
import {
  computed,
  defineComponent,
  unref,
  useContext
} from '@nuxtjs/composition-api'
const ACTIVE_LINK_CSS = 'fat-underline font-bold'
const UNACTIVE_LINK_CSS = 'text-gray-500'

export default defineComponent({
  name: 'HeaderAllProductsLinks',
  setup() {
    const {i18n, route} = useContext()

    const scrollLinks = computed(() => {
      const isDealsPage = unref(route).name?.startsWith('deals')
      const isAllProductsPage = unref(route).name?.startsWith('all-products')
      return [
        {
          label: i18n.t('pages.deals.title'),
          to: `/deals/${unref(route).params.category}`,
          isActive: isDealsPage,
          class: {
            [ACTIVE_LINK_CSS]: isDealsPage,
            [UNACTIVE_LINK_CSS]: !isDealsPage
          }
        },
        {
          label: i18n.t('pages.all_products.title'),
          to: `/all-products/${unref(route).params.category}`,
          isActive: isAllProductsPage,
          class: {
            [ACTIVE_LINK_CSS]: isAllProductsPage,
            [UNACTIVE_LINK_CSS]: !isAllProductsPage
          }
        }
      ]
    })

    return {
      scrollLinks
    }
  }
})
