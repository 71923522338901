import {datadogRum} from '@datadog/browser-rum'

export default ({route, $config}: any) => {
  const pageType = route.meta.reduce(
    (pageType: string, meta: any) => meta.pageType || pageType,
    ''
  )

  if (process.client) {
    datadogRum.onReady(() => {
      datadogRum.startView({
        name: pageType,
        service: 'ay-outlet-web',
        version: $config.clientVersion
      })
    })
  }
}
