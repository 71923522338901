
import {BasketItemPrice} from '@aboutyou/backbone'
import {
  defineComponent,
  PropType,
  computed,
  useContext
} from '@nuxtjs/composition-api'
import {Price} from '@scayle/storefront-nuxt2'
import {totalAppliedReductions} from '~/helpers/reductions'

export default defineComponent({
  name: 'ProductReduction',
  props: {
    price: {
      type: Object as PropType<Price | BasketItemPrice>,
      required: true
    },
    showFinalReductionOnly: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'md'
    },
    showUpToDiscountPrefix: {
      type: Boolean,
      default: false
    }
  },

  setup(props) {
    const {i18n} = useContext()

    return {
      prices: computed(() => totalAppliedReductions(props.price)),
      prefix: computed(() => {
        return props.showUpToDiscountPrefix ? i18n.t('price.up_to') : ''
      })
    }
  }
})
