
import {defineComponent, type PropType} from '@nuxtjs/composition-api'
import {type Blok} from '~/@types/cms/blok'

export default defineComponent({
  props: {
    body: {
      type: Object as PropType<Blok>,
      default: () => {}
    },
  },
  setup(props) {
    return {
      content: (props.body as any)?.content || null
    }
  }
})
