import {Context} from "@nuxt/types";
import {Inject} from "@nuxt/types/app";
import {initialize, changeUser, showContentCards, openSession, subscribeToContentCardsUpdates, removeSubscription, Card} from "@braze/web-sdk";
import {ShopUser} from '@scayle/storefront-nuxt2'
import {decryptRC4} from "~/helpers/encryption";
import {isUserInContext} from '~/modules/log/util'
import cookieConsent from '~/helpers/cookieConsent'

const SHOW_ONLY_FIRST_CARD = true

export default (context: Context, inject: Inject) => {
  let didInitialize = false;

  const initBraze = () => {
    if(didInitialize) return;

    if (isUserInContext(context)) {

      initialize(decryptRC4(context.$config.BRAZE_API_KEY_SECURE, context.env.PRIVATE_ENCRYPT_SIGN_KEY), {
        baseUrl: context.$config.BRAZE_API_BASE_URL,
        enableLogging: ['staging', 'preview'].includes(context.$config.shopSystem) || !context.$config.isProduction,
        localization: context.$currentShop.locale.split('_')[0], // ISO 639-1
        openCardsInNewTab: true,
        openInAppMessagesInNewTab: true,
        appVersion: context.$config.clientVersion
      });


      const user: ShopUser = (context as any).$sharedRefsMap.get('useUser-data').value.user;

      changeUser(String(user.id))

      didInitialize = true;
    }

  }

  const consentHandler =
  cookieConsent('initBraze', ['targeting', 'bz'], new Map())
    .optIn(() => {
      initBraze()
    })

  let contentCardsState: Card[] = [];
  let cardSubscriptionId: string | undefined = "";

  inject('initBraze', consentHandler.invoke)
  inject('contentCards', {
    mount: (domId: string) => {
      consentHandler.optIn(() => {

        if(!didInitialize) {
          return;
        }

        showContentCards(document.getElementById(domId), contentCards => {
          if(SHOW_ONLY_FIRST_CARD) {
            return contentCards.slice(0, 1)
          }

          return contentCards;
        })

        cardSubscriptionId = subscribeToContentCardsUpdates(contentCards => {
          contentCardsState = [...contentCards.cards]
        })
        openSession()
      }, {alias: 'mountContentCards'})

      consentHandler.invoke()
    },
    unmount: () => {
      if(cardSubscriptionId) {
        removeSubscription(cardSubscriptionId);
      }

      consentHandler.optIn(() => {
        // replace mounting handler
      }, {alias: 'mountContentCards'})
    },
    // dismiss cards from top
    dismiss: () => {
      if(contentCardsState.length) {
        contentCardsState[0].dismissCard()
      }
    }
  })
}
