import {computed} from "@nuxtjs/composition-api";
import {Blok, MARGINS} from "~/@types/cms/blok";

export const useCmsMarginsMixin = (blok?: Blok) => {
  const marginClasses = computed(() => {
    return [MARGINS[blok?.margin_top ?? 'none']]
  })

  return {
    marginClasses
  }
}
