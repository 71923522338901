import {computed, unref, useContext, useMeta} from "@nuxtjs/composition-api";
import {sanitizeCanonical} from "@scayle/storefront-nuxt2";
import useUiState from "~/composables/ui/useUiState";
import { useIsPage } from "./useIsPage";
import type {MetaPropertyName, AttributeProperty} from 'vue-meta'

export const useLayoutMeta = () => {
  const {i18n, route, $currentShop}: any = useContext()
  const {isSideNavigationOpen} = useUiState()
  const {isCheckoutWithoutOsp} = useIsPage()

  const protocol = `https://`
  const sanitizedCanonical: string = sanitizeCanonical(
    `${protocol}${$currentShop.domain}${route.value.fullPath}`
  )

  const language = computed(() => {
    if(i18n.locale === 'si') {
      return 'sl'
    }

    return i18n.locale
  })

  // <meta name="googlebot" content="notranslate">
  const ospMeta = computed<any[]>(() => {
    if(unref(isCheckoutWithoutOsp)) {
      return [
        {
          name: 'googlebot',
          // use `value` instead of 'content`
          value: 'notranslate',
        }
      ]
    }
    return []
  })
  const noTranslateHtmlAttr = computed<AttributeProperty>(() => {
    if(unref(isCheckoutWithoutOsp)) {
      return {translate: 'no'}
    }

    return {} as AttributeProperty
  })

  return useMeta(
    {
      bodyAttrs: {
        /** HIDE SCROLLBAR WHILE NAVIGATION SLIDE IN IS OPEN */
        class: isSideNavigationOpen.value ? 'overflow-hidden' : ''
      },
      htmlAttrs: {
        lang: language.value,
        ...(unref(noTranslateHtmlAttr))
      },
      link: [
        {
          rel: 'canonical',
          hid: 'canonical',
          href: sanitizedCanonical
        }
      ],
      meta: unref(ospMeta)
    }
  )
}
