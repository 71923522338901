
import {
  computed,
  defineComponent,
  PropType,
  ref,
  unref,
  onUpdated
} from '@nuxtjs/composition-api'
import {Tab} from './types'

export default defineComponent({
  props: {
    tabsHeaderClass: {
      type: String,
      default: ''
    },
    tabsBodyClass: {
      type: String,
      default: ''
    },
    tabsFooterClass: {
      type: String,
      default: ''
    },
    tabs: {
      type: Array as PropType<Tab[]>,
      required: true
    },
    tabAreas: {
      type: Array as PropType<string[]>,
      default: () => ['header', 'body', 'footer']
    }
  },
  setup(props, {emit}) {
    const selectedTab = ref<Tab | null>(null)

    const setActiveTab = (tab: Tab) => {
      emit('change', tab)
      selectedTab.value = tab
    }

    const activeTab = computed(() => {
      return unref(selectedTab) ? unref(selectedTab) : props.tabs[0]
    })

    onUpdated(() => {
      const activeTabStillThere = selectedTab.value && props.tabs.find(t => t.type === selectedTab.value?.type) !== null

      if(!activeTabStillThere) {
        selectedTab.value = null
      }
    })

    return {
      setActiveTab,
      activeTab,
      getTabBindings(tab: Tab, index: number) {
        return {
          activeTab: selectedTab,
          isActive: activeTab.value?.type === tab.type,
          tab,
          activate: () => setActiveTab(tab),
          isFirstTab: index === 0,
          isLastTab: index === props.tabs.length - 1,
          tabIndex: index
        }
      }
    }
  }
})
