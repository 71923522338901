export const executeOnIdle = (func: () => void, timeout = 50): (() => void) => {
  if (
    process.client &&
    typeof window !== 'undefined' &&
    'requestIdleCallback' in window &&
    window.requestIdleCallback
  ) {
    const timer = window.requestIdleCallback(func, {timeout})
    return () => {
      window.cancelIdleCallback(timer)
    }
  }
  else {
    const timer = setTimeout(func, timeout)
    return () => {
      clearTimeout(timer)
    }
  }
}

interface Options {
  /**
   * calls debounce immediately
   */
  isImmediate: boolean;
}

export const debounce = <F extends (...args: any[]) => any>(
  func: F,
  waitMilliseconds = 50,
  options: Options = {
    isImmediate: false
  }
) => {
  let timeoutId: ReturnType<typeof setTimeout> | undefined;

  return function(this: any, ...args: any[]) {
    const context = this;

    const doLater = function() {
      timeoutId = undefined;
      if (!options.isImmediate) {
        func.apply(context, args);
      }
    };

    const shouldCallNow = options.isImmediate && timeoutId === undefined;

    if (timeoutId !== undefined) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(doLater, waitMilliseconds);

    if (shouldCallNow) {
      func.apply(context, args);
    }
  } as any;
}
