import rc4 from "crypto-js/rc4";
import type CryptoJS from "crypto-js/lib-typedarrays";
import hex from "crypto-js/enc-utf8";

export const encryptRC4 = (value: string, secret: string, debugLabel?: string) => {

  if(!value || !secret) {
    console.warn(`encryptio.ts#encryptRC4 - [${debugLabel}] - Invalid value or secret - value: '${value}'; secret: '${secret}'`);
  }

  return rc4.encrypt(value, secret);
}

export const decryptRC4 = (value: CryptoJS.lib.CipherParams, secret: string) => {
  if(!value || !secret) {
    console.warn("encryptio.ts#decryptRC4 - Invalid value or secret");
  }

  return rc4.decrypt(value, secret).toString(hex);
}

export const encryptSessionAccessTokenRC4 = (token: string | undefined, secret: string) => {
  return token;
  // if (!token) {
  //   return ''
  // }

  // return encryptRC4(token, secret)
}

export const decryptSessionAccessTokenRC4 = (token: string | CryptoJS.lib.CipherParams, secret: string) => {
  return token
  // if (typeof token === 'string') {
  //   return ''
  // }

  // return decryptRC4(token, secret)
}
