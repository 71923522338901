import {
  ProductCategory,
  Price,
  Value,
  toFloat,
  getItemQuantityFromOrder,
  getUniqueItemsFromOrder,
  getTotalAppliedReductions,
  Product,
  Order
} from '@scayle/storefront-nuxt2'
import {computed, unref, useContext} from '@nuxtjs/composition-api'
import {
  getDeepestCategoryForTracking,
  formatPriceWithCurrency,
  sumReductionsByCategory,
  sumReductionsFromAllOrderItemsPerCategory,
  getFirstCarrierKey,
  sha256Email
} from '../helpers'

import {OrderItemProduct, OrderItemVariant} from '../types'
import useCommonTrackingData from '../useCommonTrackingData'
import {useTrackingCurrency} from '../useTrackingCurrency'
import {useConcatenatedExperiments} from '~/composables/optimizely/useConcatenatedExperiments'
import {useOrdersCountByShopGroup} from '~/composables/useOrders'
import { useExperiment } from '~/composables/optimizely/useExperiment'

interface PriceWithUndiscounted extends Price {
  undiscountedWithOutTax?: number
  undiscountedWithTax?: number
}

const usePurchaseEvents = () => {
  const commonTrackingData = useCommonTrackingData();
  const concatenatedExperiments = useConcatenatedExperiments()
  const {trackExperimentEvent} = useExperiment()
  const ordersCount = useOrdersCountByShopGroup()
  const {$gtm} = useContext()
  const currency = useTrackingCurrency()

  return ({
    trackPurchaseEvent: (orderData: Order, email?: string) => {
      const defaultEmptyValue = ''
      const paymentType = orderData?.payment?.[0].key
      const moneyLocale = 'en-EN' // for money formatting analytics requires en-EN so that every shop value is formatted the same way

      // TODO: if giftcard is implemented this should change to the right amount
      // const giftcard = getGiftcardAmount({
      //   amount: 0,
      //   currency,
      //   locale: $currentShop.locale.replace('_', '-') || moneyLocale,
      // })
      const coupon = orderData.vouchers?.[0]?.code ?? defaultEmptyValue // only one voucher can be applied for now
      const items = getUniqueItemsFromOrder(orderData)?.map(
        (orderItem, index: number) => {
          const product = orderItem.product as unknown as Product &
            OrderItemProduct
          const variant = orderItem.variant as unknown as OrderItemVariant
          const price = orderItem.price as unknown as PriceWithUndiscounted
          const brandValue = product.attributes?.brand?.values as Value
          const {name: itemCategoryName, id: itemCategoryId} = getDeepestCategoryForTracking(
            orderItem?.product?.categories as ProductCategory[][]
          )
          return {
            item_id: String(product.id),
            item_name: product.name,
            price: toFloat(price?.withoutTax),
            price_gross: toFloat(price?.withTax),
            item_brand: brandValue?.label,
            item_brand_id: String(brandValue?.id || ""),
            item_variant: String(variant.id),
            quantity: String(
              getItemQuantityFromOrder(orderData, variant.id) || ''
            ),
            item_list_name: defaultEmptyValue,
            item_list_id: defaultEmptyValue,
            index: index + 1,
            item_category: itemCategoryName,
            item_category_id: itemCategoryId,
            carrier: getFirstCarrierKey(orderData),
            sale_discount: toFloat(
              sumReductionsByCategory(price?.appliedReductions, 'sale')
            ),
            campaign_discount: toFloat(
              sumReductionsByCategory(price?.appliedReductions, 'campaign')
            ),
            original_price: price.appliedReductions?.length > 0
              ? toFloat(
                getTotalAppliedReductions(price)?.absoluteWithTax +
                (price?.undiscountedWithTax || price?.withTax || 0)
              )
              : toFloat(price?.undiscountedWithTax || price?.withTax || 0)
          }
        }
      )

      const valueWithoutTaxAndFeesInCents = computed(() => orderData.cost.withoutTax -
        (orderData.cost.appliedFees?.reduce((totalFeeWithoutTax, fee) => {
          totalFeeWithoutTax += fee.amount.withoutTax
          return totalFeeWithoutTax
        }, 0) || 0))

      const valueWithoutTaxAndFees = computed(() => {
        return formatPriceWithCurrency(
          valueWithoutTaxAndFeesInCents.value,
          moneyLocale,
          currency
        )
      })

      const shippingCostWithoutTax = computed(() => {
        const deliveryFee = orderData.cost.appliedFees?.filter(
          fee => fee.category === 'delivery'
        )[0]
        return formatPriceWithCurrency(
          deliveryFee?.amount.withoutTax ?? 0,
          moneyLocale,
          currency
        )
      })

      const ecommerce = {
        transaction_id: String(orderData.id),
        customer_id: String(orderData.customer?.id || ''),
        eh: sha256Email(email),
        value: valueWithoutTaxAndFees.value,
        sale_reduction_with_tax: formatPriceWithCurrency(
          sumReductionsFromAllOrderItemsPerCategory(orderData.items, 'sale'),
          moneyLocale,
          currency
        ),
        campaign_reduction_with_tax: formatPriceWithCurrency(
          sumReductionsFromAllOrderItemsPerCategory(orderData.items, 'campaign'),
          moneyLocale,
          currency
        ),
        coupon_reduction_with_tax: formatPriceWithCurrency(
          sumReductionsFromAllOrderItemsPerCategory(orderData.items, 'voucher'),
          moneyLocale,
          currency
        ),
        coupon,
        coupon_code: coupon,
        // giftcard === "<amount><currency>" e.g. 10€ mentioned here: https://aboutyou.atlassian.net/wiki/spaces/AYC/pages/979207502/SFC+v2+Tracking+Integration
        giftcard: defaultEmptyValue,
        tax: formatPriceWithCurrency(
          orderData.cost.tax.vat?.amount ?? 0,
          moneyLocale,
          currency
        ),
        shipping: shippingCostWithoutTax.value,
        currency,
        payment_type: paymentType,
        items,
        abtest: concatenatedExperiments.value
      }

      const coreOrdersCount = unref(ordersCount.data).coreOrderNumber
      const outletOrdersCounts = unref(ordersCount.data).outletOrderNumber

      $gtm.push({
        event: 'purchase',
        ecommerce,
        ...unref(commonTrackingData),
        core_order_number: coreOrdersCount,
        outlet_order_number: outletOrdersCounts
      })

      trackExperimentEvent({
        eventName: 'purchase',
        quantity: items?.length ?? 0,
        revenue: Number(valueWithoutTaxAndFeesInCents.value),
        value: Number(valueWithoutTaxAndFees.value)
      })
    }
  })
}

export default usePurchaseEvents
