import { Product } from '@scayle/storefront-nuxt2'

export const state = () => ({
  viewedProducts: [] as Product[],
})

export type ProductsState = ReturnType<typeof state>

export const getters = {
  viewedProducts: (state: ProductsState) => state.viewedProducts,
}

export const mutations = {
  ADD_VIEWED_PRODUCT: (state: ProductsState, viewedProduct: Product) => {
    if (state.viewedProducts.find((product) => product.id === viewedProduct.id))
      return
    state.viewedProducts.unshift(viewedProduct)
  },
}

export const actions = {
  addViewedProduct: (context: any, viewedProduct: Product) => {
    context.commit('ADD_VIEWED_PRODUCT', viewedProduct)
  },
}
