
import { defineComponent, PropType } from '@nuxtjs/composition-api'
import Icon from '~/components/ui/icons/Icon.vue'
import Tooltip from '~/components/ui/tooltips/Tooltip.vue'

export default defineComponent({
  name: 'BannerLegalTextIcon',
  components: {
    Icon,
    Tooltip,
  },
  props: {
    body: Object as PropType<{ legalText: string }>,
  },
})
