
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  PropType,
  ref,
  useContext,
  useRoute
} from '@nuxtjs/composition-api'
import {useUserWithParams} from '@scayle/storefront-nuxt2'
import HeaderAllProductsLinks from './HeaderAllProductsLinks.vue'
import HeaderScrollLinks from './HeaderScrollLinks.vue'
import Logo from '~/components/logo/Logo.vue'
import HeaderCategories from '~/components/layout/headers/HeaderCategories.vue'
import BasketPopover from '~/components/basket/BasketPopover.vue'
import AccountPopover from '~/components/account/AccountPopover.vue'
import {GenderMixin, getGenderParamFromUser} from '~/helpers/user'

export default defineComponent({
  name: 'HeaderLoggedIn',
  components: {
    Logo,
    HeaderCategories,
    HeaderAllProductsLinks,
    BasketPopover,
    AccountPopover,
    HeaderScrollLinks
  },
  props: {
    isBannerActive: {
      type: Boolean as PropType<boolean>,
      required: true
    }
  },
  setup() {
    const route = useRoute()
    const scroll = ref(0)
    const {user} = useUserWithParams({})
    const isDealsOverviewPage = computed(() => {
      /**
       * We want to match only on DOP
       * DOP - deals-category_
       * DDP - deals-category-id_
       */
      return route.value.name?.startsWith('deals-category_')
    })

    const {$config} = useContext()
    const showAllProducts = computed(() => {
      return $config?.featFlags?.ALL_PRODUCTS_TAB
    })

    const isPermanentAssortmentPage = computed(() => {
      return route.value.name?.startsWith('all-products')
    })

    const link = computed(() => `/deals/${getGenderParamFromUser(user.value as GenderMixin)}`)
    const isCheckoutPage = computed(() => route.value.path === '/checkout')

    const onScroll = () => {
      scroll.value = window.scrollY
    }

    onMounted(() => {
      window.addEventListener('scroll', onScroll)
    })

    onUnmounted(() => {
      window.removeEventListener('scroll', onScroll)
    })

    return {
      scroll,
      isDealsOverviewPage,
      isPermanentAssortmentPage,
      isCheckoutPage,
      link,
      showAllProducts
    }
  }
})
