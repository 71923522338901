import express from 'express'
import {defineNuxtPlugin} from '@nuxtjs/composition-api'
import {getSession} from '@scayle/storefront-nuxt2'
import type {ExtendedSessionData} from '~/@types'

export default defineNuxtPlugin((context, inject) => {
  if (process.server) {
    const sessionDataFromReq = getSession<ExtendedSessionData>(context.req as any)

    const sessionData = sessionDataFromReq?.user?.id ? sessionDataFromReq : {
      ...sessionDataFromReq,
      user: undefined,
      accessToken: undefined
    }

    const query = (context.req as express.Request).query || {}
    const queryBasketKey = query['basketKey'] || query['basketId']
    const queryAccessToken = query['accessToken']
    const queryCampaignKey = query['campaignKey']
    const hideHeaderAndFooter = query['hideHeaderAndFooter']
    const redirectBackToApp =
      query['accessToken'] && query['hideHeaderAndFooter'] === 'true'

    const d = new Date()
    d.setHours(d.getHours() + 1)

    if (hideHeaderAndFooter === 'true') {
      context.store.commit('SET_HIDE_HEADER_AND_FOOTER', true)
      context.$cookies.set('ayo.hideHeaderAndFooter', 'true', {
        expires: d,
        maxAge: 3600
      })
    } else {
      context.store.commit(
        'SET_HIDE_HEADER_AND_FOOTER',
        context.$cookies.get('ayo.hideHeaderAndFooter', {}) || false
      )
    }

    if (
      queryAccessToken &&
      queryBasketKey &&
      queryCampaignKey &&
      !sessionData?.accessToken
    ) {
      context.store.commit('SET_QUERY_PARAMS', {
        accessToken: queryAccessToken,
        basketKey: queryBasketKey,
        campaignKey: queryCampaignKey
      })
    } else if (sessionData?.accessToken) {
      context.store.commit('SET_QUERY_PARAMS', {
        accessToken: sessionData?.accessToken
      })
    }

    if (redirectBackToApp) {
      context.store.commit('SET_REDIRECT_BACK_TO_APP', true)
      context.$cookies.set('ayo.redirectBackToApp', 'true', {
        expires: d,
        maxAge: 3600
      })
    } else {
      context.store.commit(
        'SET_REDIRECT_BACK_TO_APP',
        context.$cookies.get('ayo.redirectBackToApp')
      )
    }
  }
})
