var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"px-2 pb-4"},[_c('div',{class:{
      'border border-black bg-secondary-100 rounded-t rounded-b': !_vm.isModalMode
    }},[_c('div',{class:{
        'text-xl font-semibold pb-4': _vm.isModalMode,
        'cursor-pointer p-4 text-sm font-bold': !_vm.isModalMode
      }},[_vm._v("\n      "+_vm._s(_vm.$t('login_page.welcome_back.title', {username: _vm.lastLoggedInUser.firstName}))+"\n    ")]),_vm._v(" "),_c('div',{class:{
        'p-4': !_vm.isModalMode
      }},[_c('SignInForm',{attrs:{"password-forget-callback-link":_vm.passwordForgetCallbackLink,"skip-page-reload-after-login":_vm.isModalMode}}),_vm._v(" "),_c('button',{staticClass:"group relative text-gray-600 hover:text-black mt-4 text-sm",on:{"click":_vm.removeLastLoggedInUser}},[_c('span',[_vm._v(_vm._s(_vm.$t('login_page.welcome_back.not_user', {username: _vm.lastLoggedInUser.firstName})))])])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }