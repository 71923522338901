import {Context} from '@nuxt/types'
import {Inject} from '@nuxt/types/app'
import StoryblokClient from "storyblok-js-client";

declare module '@nuxt/types' {
  interface Context {
    $storyblok: {
      client: StoryblokClient,
      cache: {
        optionalFlushCache: (currentRequestTime: number) => Promise<void>
      }
    }
  }
}

let CACHE = new Date().getTime();

const FIFTEEN_MIN_CACHE_LIVE_TIME = 1000 * 60 * 15;

export default (_ctx: Context, inject: Inject) => {
  const Storyblok = new StoryblokClient({
    cache: {
      clear: "auto",
      type: "memory"
    }
  })

  const optionalFlushCache = async (currentRequestTime: number) => {
    if (currentRequestTime > new Date(CACHE + FIFTEEN_MIN_CACHE_LIVE_TIME).getTime()) {
      await Storyblok.flushCache();
      CACHE = currentRequestTime
    }
  }

  inject('storyblok', {
    client: Storyblok,
    cache: {
      optionalFlushCache
    }
  })
}
