import { CustomStoreConfig } from "../lib/type"
import { plPaymentProviders } from '../lib/paymentProviders'
import { domains } from "../lib/domains"
import {appKeys} from "../lib/app-keys"

const PL: CustomStoreConfig = {
  code: 'pl',
  shopId: 5210,
  domain: domains.pl,
  appKeys,
  locale: 'pl-PL',
  currency: 'PLN',
  internationalPrefix: '',
  paymentProviders: plPaymentProviders,
  orderPrefix: 'aopl',
  lowest30DaysPriceEnabled: true,
  allProductsHaveOwnStockAttribute: true,
  enableReturns: true,
  showDeliveryEstimates: false
}

export default PL
