import {
  ref,
  onMounted,
  onUnmounted
} from '@nuxtjs/composition-api'

const OBSERVER_CONFIG = {
  attributes: true,
  childList: true,
  subtree: true
};

export const useHeaderOffset = () => {
  const observer = ref<MutationObserver | null>(null);

  const headerElement = ref<HTMLElement | null>(null)
  const headerBannerElement = ref<HTMLElement | null>(null)

  const headerOffset = ref(0)
  const bannerHeight = ref(0)

  const updateCalculatedHeights = () => {
    if (headerElement.value !== null) {
      headerOffset.value = headerElement.value.getBoundingClientRect().bottom
    }

    if (headerBannerElement.value !== null) {
      bannerHeight.value = headerBannerElement.value.getBoundingClientRect().bottom
    }
  }

  onMounted(() => {
    headerElement.value = document.querySelector('header')
    headerBannerElement.value = document.querySelector('#header_banner')

    if (!headerElement.value) {
      return;
    }

    updateCalculatedHeights()

    const callback = () => {
      updateCalculatedHeights()
    };

    if (observer.value === null) {
      observer.value = new MutationObserver(callback);
      observer.value.observe(headerElement.value, OBSERVER_CONFIG);
    }
  })

  onUnmounted(() => {
    observer.value?.disconnect();
  })

  return {
    headerOffset,
    bannerHeight
  }
}
