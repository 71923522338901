export interface Reduction {
  type: 'absolute' | 'relative'
  category: 'sale' | 'campaign' | 'voucher'
  amount: {
    absoluteWithTax: number
    relative: number
  }
}

export interface MinimalPrice {
  withTax: number
  appliedReductions: Reduction[]
}

export const totalAppliedReductions = (price?: MinimalPrice) => {
  const appliedReductions = price?.appliedReductions || [];
  const priceWithTax = price?.withTax || 0;

  const {originalPrice, finalReduction} = appliedReductions.reduce(
    (acc, reduction) => {
      const originalPrice = acc.originalPrice + reduction.amount.absoluteWithTax
      return {
        originalPrice,
        finalReduction: Math.round((1 - priceWithTax/originalPrice) * 100)
      }
    },
    {
      originalPrice: priceWithTax,
      finalReduction: 0
    }
  )

  return {
    finalPrice: priceWithTax,
    finalReduction,
    originalPrice,
    reductions: appliedReductions.map(red => {
      return {amount:  Math.round(red.amount.relative * 100), category: red.category}
    }).sort((a, b) => {
      if (a.category === 'sale' && b.category !== 'sale') {
        return -1; // 'sale' comes first
      }
      else if (a.category !== 'sale' && b.category === 'sale') {
        return 1; // 'sale' comes first
      }
      else if (a.category === 'campaign' && b.category !== 'campaign') {
        return -1; // 'campaign' comes after 'sale'
      }
      else if (a.category !== 'campaign' && b.category === 'campaign') {
        return 1; // 'campaign' comes after 'sale'
      }
      else {
        return 0; // categories are the same
      }
    }),
    oldPrices: appliedReductions.reduce((acc, reduction) => {
      const prevPrice = acc.slice(-1)[0]; // get the last computed price
      return [...acc, prevPrice - reduction.amount.absoluteWithTax]
    }, [originalPrice]).slice(0, -1) // remove the final price
  }
}
