import { CustomStoreConfig } from "../lib"
import { hrPaymentProviders } from "../lib/paymentProviders"
import { domains } from "../lib/domains"
import {appKeys} from "../lib/app-keys"

const HR: CustomStoreConfig = {
  code: 'hr',
  shopId: 5207,
  domain: domains.hr,
  appKeys,
  locale: 'hr-HR',
  currency: 'EUR',
  // secondCurrency: 'HRK',
  // currencyConversion: 7.53450, // 1 EUR = 7,53450
  // secondaryCurrencyFractionDigits: 2,
  internationalPrefix: '385',
  paymentProviders: hrPaymentProviders,
  orderPrefix: 'aohr',
  enableReturns: true,
  showAllProducts: false,
  allProductsHaveOwnStockAttribute: true,
  lowest30DaysPriceEnabled: true,
  showDeliveryEstimates: false
}

export default HR
