
import {defineComponent} from '@nuxtjs/composition-api';
import {AnchorAttributes} from '~/@types/rich-text-renderer-types';
export default defineComponent({
  name: 'SbAnchor',
  props: {
    attrs: {
      type: Object as () => AnchorAttributes,
      required: true
    }
  },
  setup(props, _ctx) {
    return {
      id: props.attrs.id
    };
  }
});
