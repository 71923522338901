import {
  BasketData,
  BasketItem,
  Product,
  Variant
} from '@scayle/storefront-nuxt2'
import {unref} from '@nuxtjs/composition-api'
import useCommonTrackingData from '../useCommonTrackingData'
import {
  ProductActionData,
  TrackAddToBasketParams,
  TrackingEvent,
  TrackingPayload
} from '../types'
import {useTrackingCurrency} from '../useTrackingCurrency'
import { useExperiment } from '~/composables/optimizely/useExperiment'

const useBasketEvents = (
  track: (event: TrackingEvent, payload: TrackingPayload) => any
) => {
  const commonTrackingData = useCommonTrackingData()
  const {trackExperimentEvent} = useExperiment()
  const currencyCode = useTrackingCurrency()

  return {
    trackBasketItems: (items: BasketItem[] = []) => {
      const basketPayload: BasketData = {
        items: items || [],
        total_campaign_reduction_with_tax: 0.0,
        total_sale_reduction_with_tax: 0.0,
        total_with_tax: 0.0,
        total_without_tax: 0.0,
        ...unref(commonTrackingData)
      }

      items.forEach(basketItem => {
        basketItem.price.total.appliedReductions
          .filter(reduction => reduction.category === 'sale')
          .forEach(
            reduction =>
              (basketPayload.total_sale_reduction_with_tax +=
                reduction.amount.absoluteWithTax)
          )
        basketItem.price.total.appliedReductions
          .filter(reduction => reduction.category === 'campaign')
          .forEach(
            reduction =>
              (basketPayload.total_campaign_reduction_with_tax +=
                reduction.amount.absoluteWithTax)
          )
        basketPayload.total_with_tax += basketItem.price.total.withTax
        basketPayload.total_without_tax += basketItem.price.total.withoutTax
      })

      track('cart', basketPayload)
    },

    trackAddToBasket: ({
      product,
      quantity = 1,
      variant,
      index = 1,
      list
    }: TrackAddToBasketParams) => {
      track('add_to_cart', {
        product: {...product, index},
        variant,
        quantity,
        currencyCode,
        list,
        ...unref(commonTrackingData)
      } as ProductActionData)

      trackExperimentEvent({
        eventName: 'add2basket',
        quantity
      })
    },

    trackRemoveFromBasket: (
      product: Product,
      quantity: number,
      variant: Variant,
      index = 1
    ) => {
      track('remove_from_cart', {
        product: {...product, index},
        variant,
        quantity,
        currencyCode,
        ...unref(commonTrackingData)
      })
    },

    trackBasket: (items: BasketItem[]) => {
      track('cart', {currencyCode, items: items})
    }
  }
}
export default useBasketEvents
