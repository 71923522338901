import { CustomStoreConfig } from "../lib"
import { dePaymentProviders } from "../lib/paymentProviders"
import { domains } from "../lib/domains"
import {appKeys} from "../lib/app-keys"

const DE: CustomStoreConfig = {
  code: 'de',
  shopId: 5200,
  domain: domains.de,
  appKeys,
  locale: 'de-DE',
  currency: 'EUR',
  internationalPrefix: '49',
  paymentProviders: dePaymentProviders,
  orderPrefix: 'aode',
  hidePhoneNumber: true,
  enableReturns: false,
  showAllProducts: false,
  allProductsHaveOwnStockAttribute: true,
  lowest30DaysPriceEnabled: true,
  showDeliveryEstimates: false,
  enableSovendus: true,
  sovendusSourceNumber: 7795,
  sovendusMediumNumber: 2
}

export default DE
