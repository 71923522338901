
import {defineComponent, onMounted, PropType} from '@nuxtjs/composition-api'
import {useLastLoggedInUser} from "~/composables/useLastLoggedInUser";
import SignInForm from '~/components/login/SignInForm.vue'

export default defineComponent({
  name: 'LastLoggedInForm',
  components: {
    SignInForm
  },
  props: {
    passwordForgetCallbackLink: {
      type: String as PropType<string>,
      default: '/signin'
    },
    isModalMode: {
      type: Boolean as PropType<boolean>,
      default: false
    }
  },
  setup() {
    const {lastLoggedInUser, removeLastLoggedInUser, setLastLoggedInUser} = useLastLoggedInUser()

    onMounted(() => {
      setLastLoggedInUser()
    })

    return {
      lastLoggedInUser,
      removeLastLoggedInUser
    }
  }
})
