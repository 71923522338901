import {
  type FilterParams,
  type Product
} from '@scayle/storefront-nuxt2'
import type {Route} from 'vue-router'

import slugify from 'slugify'
import {getFirstAttributeValue} from '@aboutyou/backbone'

export {isExactActiveRoute} from '@scayle/storefront-nuxt2'

const KNOWN_MARKETING_PARAMS = [
  'utm_content',
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'gclid',
  'adjust_network',
  'adjust_campaign',
  'adjust_adgroup',
  'adjust_creative',
  'adj_t',
  'adj_campaign',
  'adj_adgroup',
  'adj_creative',
  'adj_criteo_click_id',
  'cto_pld',
  'adjust_t'
]


export const getStoreDetailRoute = (cityName: string) => {
  return {
    name: routes.stores.name,
    params: {
      slug: cityName
    }
  }
}

export const getSearchRoute = (term: string) => {
  return {
    name: 'search',
    query: {term}
  }
}

type Link =
  | 'search'
  | 'wishlist'
  | 'basket'
  | 'product'
  | 'stores'
  | 'trends'
  | 'checkout'
  | 'signin'
  | 'account'
  | 'order'
  | 'orderDetail'
  | 'user'
  | 'password'
  | 'bankData'
  | 'faq'
export type LinkList = Record<
  Link,
  {name: string; path: string; parameter?: string}
>

export const routes: LinkList = {
  search: {name: 'search', path: '/search'},
  wishlist: {name: 'wishlist', path: '/wishlist'},
  basket: {name: 'basket', path: '/basket'},
  product: {name: 'product-slug', path: '/product'},
  stores: {name: 'stores-slug', path: '/stores'},
  trends: {name: 'women-trends-slug', path: '/women/trends'},
  signin: {name: 'signin', path: '/signin'},
  account: {name: 'account', path: '/account'},
  checkout: {name: 'checkout', path: '/checkout'},
  order: {name: 'account-order', path: '/account/order'},
  user: {name: 'account-user', path: '/account/user'},
  password: {name: 'account-password', path: '/account/password'},
  bankData: {name: 'account-bank-data', path: '/account/bank-data'},
  orderDetail: {
    name: 'account-orders-detail-id',
    path: '/account/order',
    parameter: 'id'
  },
  faq: {name: 's-slug___en', path: '/s/faq'}
}

// TODO: unit test me
export const strapQueryDownToMarketingParams = (query: Route['query']) => {
  const whitelistedQuery: Route['query'] = {}

  let queryParam: keyof typeof query
  for (queryParam in query) {
    if (KNOWN_MARKETING_PARAMS.includes(queryParam)) {
      whitelistedQuery[queryParam] = query[queryParam]
    }
  }

  return whitelistedQuery
}

// TODO: unit test me
export const stringifyQuery = (query: Route['query']): string => {
  if (Object.keys(query).length === 0) {
    return ''
  }

  let queryString = ''

  for (const [key, value] of Object.entries(query)) {
    queryString += `${queryString.length === 0 ? '?' : ''}${key}=${value}&`
  }

  return queryString.slice(0, -1)
}

export const excludeMarketingAttributes = (where: FilterParams['where']) => {
  if (!where || !where.attributes) {
    return []
  }

  return where.attributes.filter(attribute => {
    return !(
      attribute.type === 'attributes' &&
      KNOWN_MARKETING_PARAMS.includes(String(attribute.key))
    )
  })
}

const slugifyOptions: Parameters<typeof slugify>[1] = {
  lower: true,
  trim: true,
  remove: /[*+~.()'"!:@/#?]/g,
}

export const getProductDetailRoute = (product: Product, id?: number) => {
  const brand =
  getFirstAttributeValue(product.attributes, 'brand')?.label || 'brand'
  const slug = getFirstAttributeValue(product.attributes, 'name')?.label || 'product'
  const idParam = `${id || product.id}`

  return `/p/${slugify(brand, slugifyOptions)}/${slugify(slug, slugifyOptions)}/${idParam}`;
}

// export default {
//   slugify,
//   getProductDetailRoute,
//   getSearchRoute,
//   isExactActiveRoute,
//   getStoreDetailRoute,
//   routes,
//   strapQueryDownToMarketingParams,
//   stringifyQuery,
//   excludeMarketingAttributes
// }
