import {PageType} from '@scayle/storefront-nuxt2'
import {domains} from 'store-config'
import {replaceUrlTemplateParams, getCurrentUrlWithLandingParams} from '~/helpers/query'
import {getContentClientGroup} from '~/helpers/tracking'
const cookie = require('cookie')

export const state = () => ({
  pageType: '' as PageType,
  pageId: '',
  hideHeaderAndFooter: false,
  localeDomains: domains,
  utmParams: [] as Array<{name: string, value: string}>,
  landingPage: '',
  accessToken: '',
  basketId: '',
  campaignKey: '',
  redirectBackToApp: false
})

export const mutations = {
  /**
   * @see ./middleware/page-type.ts
   */
  SET_PAGETYPE(state: any, pageType: string) {
    state.pageType = pageType
  },
  SET_PAGE_ID(state: any, params: any) {
    if(params.id) {
      state.pageId = params.id
    }
    else if(params.category) {
      state.pageId = params.category
    }
    else if(params.slug) {
      state.pageId = params.slug
    }
    else {
      state.pageId = ''
    }
  },
  SET_PARAMS(state: any, params:  Array<{name: string, value: string}>) {
    state.utmParams = params;
  },
  SET_LANDING_PAGE(state: any, landingPage: string) {
    state.landingPage = landingPage;
  },
  SET_HIDE_HEADER_AND_FOOTER(state: any, shouldHide: boolean) {
    state.hideHeaderAndFooter = shouldHide
  },
  SET_QUERY_PARAMS(state: any, {accessToken, campaignKey, basketKey}: {accessToken: string, campaignKey: string, basketKey: string}) {
    if(accessToken) {
      state.accessToken = accessToken
    }
    if(campaignKey) {
      state.campaignKey = campaignKey
    }
    if(basketKey) {
      state.basketKey = basketKey
    }
  },
  SET_REDIRECT_BACK_TO_APP(state: any, shouldRedirect: boolean) {
    state.redirectBackToApp = shouldRedirect
  }
}

export const getters = {
  pageType: (state: any) => state.pageType,
  pageId: (state: any) => state.pageId,
  getUrlWithTrackingParams: (state: any) => (deepLink: string, currentPage: string) => {
    const url = state.landingPage !== currentPage ? getCurrentUrlWithLandingParams(state.landingPage, currentPage) : state.landingPage;
    return replaceUrlTemplateParams(deepLink, state.utmParams, url);
  },
  getContentClientGroup: (state: any) => getContentClientGroup(state.pageId)
}

export const actions = {
  nuxtServerInit({dispatch, commit}: { dispatch: any, commit: any }, {req}: { req: any }) {
    if (req.headers.cookie) {
      const parsedCookies = cookie.parse(req.headers.cookie)

      if (parsedCookies['ayc-user']) {
        const user = JSON.parse(parsedCookies['ayc-user'])

        dispatch('auth/setUser', user, {root: true})
      }
    }

    const reqUrl = new URLSearchParams(req._parsedUrl.query);


    const params = Array.from(reqUrl.entries()).reduce((acc, pair) => {
      if(pair[0] && pair[1] && pair[0].startsWith('utm_')) {
        return [
          ...acc,
          {name: `${pair[0]}`, value: `${pair[1]}`}
        ]
      }
      return acc;
    }, [] as Array<{name: string, value: string}>);

    commit('SET_PARAMS', params)
    commit('SET_LANDING_PAGE', req.url)
  },
  setPageId(context: any, params: any) {
    context.commit('SET_PAGE_ID', params)
  }
}
