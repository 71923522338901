// allow smooth scrolling by linking to a hash anchor:
// ```
// <nuxt-link :to="{hash: 'anchor'}">Go to anchor</nuxt-link>
// ```
if (process.client) {
  if ('scrollRestoration' in history) {
    // Back off, browser, I got this...
    history.scrollRestoration = 'manual'
  }
}

const findEl = (hash, x = 0) => {
  return (
    document.querySelector(hash) ||
    new Promise(resolve => {
      if (x > 50) {
        return resolve(null)
      }
      setTimeout(() => {
        resolve(findEl(hash, ++x || 1))
      }, 100)
    })
  )
}

export default async (to, from, savedPosition) => {
  // Keep scroll position when updating filters
  if (from.name === 'deals-category-id___en' && to.name === from.name) {
    return
  }

  if (to.name.startsWith('signin')) {
    return {x: 0, y: 0, behavior: 'auto'}
  }

  // Always scroll back to top on ADP ( PDP ) and DDP
  if (to.name.startsWith('p-brand-slug')) {
    return {x: 0, y: 0, behavior: 'auto'}
  }

  if (savedPosition) {
    const nuxt = window.$nuxt
    return new Promise((resolve, reject) => {
      const rejectScrollRestore = () => {
        reject(
          new Error(
            'User started scrolling before restoring the scroll position'
          )
        )
      }

      let scrollAttempts = 0
      const maxScrollBackAttempts = 10

      const scrollBack = () => {
        const maxScrollHeight = document.body.clientHeight
        const savedScrollPos = savedPosition.y

        if (
          savedScrollPos > maxScrollHeight &&
          scrollAttempts < maxScrollBackAttempts
        ) {
          setTimeout(() => {
            scrollAttempts++
            scrollBack()
          }, 50)
        }
        else {
          window.removeEventListener('scroll', rejectScrollRestore)
          setTimeout(() => {
            resolve({
              ...savedPosition,
              behavior: 'auto'
            })
          }, 100)
        }
      }

      nuxt.$once('triggerScroll', scrollBack)
    })
  }

  if (to.hash) {
    const el = await findEl(to.hash)
    if (el && el instanceof HTMLElement) {
      if ('scrollBehavior' in document.documentElement.style) {
        return window.scrollTo({top: el.offsetTop, behavior: 'smooth'})
      }
      else {
        return window.scrollTo(0, el.offsetTop)
      }
    }
  }

  return {x: 0, y: 0, behavior: 'auto'}
}
