
import {defineComponent, computed} from '@nuxtjs/composition-api'
import useIcon from '~/composables/useIcon'
import usePaymentIcon from '~/composables/usePaymentIcon'

export default defineComponent({
  name: 'Icon',
  props: {
    icon: {
      type: String,
      required: true
    },
    size: {
      type: String,
      default: '5'
    },
    customClasses: {
      type: String
    },
    mobileSize: {
      type: String,
      default: () => undefined
    },
    isPaymentIcon: {
      type: Boolean,
      required: false,
      default: false
    },
    color: {
      type: String,
      default: ''
    },
    fill: {
      type: String,
      default: undefined
    }
  },
  setup(props) {
    const {getIconComponent} = useIcon()
    const {getPaymentIconComponent} = usePaymentIcon()

    return {
      iconComponent: getIconComponent(props.icon),
      iconPaymentComponent: getPaymentIconComponent(props.icon),
      mobileClasses: computed(() => {
        return props.customClasses ? props.customClasses : [`w-${props.mobileSize}`, `h-${props.mobileSize}`, `lg:w-${props.size}`, `lg:h-${props.size}`].join(' ')
      }),
      styleClasses: computed(() => {
        return props.customClasses ? props.customClasses : [`w-${props.size}`, `h-${props.size}`].join(' ')
      })
    }
  }
})
