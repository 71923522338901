var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ValidationProvider',{staticClass:"block",attrs:{"tag":"div","rules":_vm.generatedRules},scopedSlots:_vm._u([{key:"default",fn:function({errors}){return [_c('div',{staticClass:"relative"},[_c('input',_vm._b({directives:[{name:"mask",rawName:"v-mask",value:(_vm.mask),expression:"mask"}],staticClass:"peer w-full rounded-sm py-3 px-4 text-sm placeholder-gray-600 ring-0 transition-colors duration-200 ease-linear focus:border-b focus:border-black focus:bg-white focus:text-black focus:outline-none focus:ring-0",class:{
        'border-gray-500 text-black hover:border-black': _vm.value && !_vm.readonly,
        'bg-gray-100 text-gray-500 hover:border-black': !_vm.value,
        'border-gray-100': !_vm.value && !_vm.error && !errors[0],
        'border-red-450': errors[0] || _vm.error,
        'border-gray-500 text-gray-500': _vm.value && _vm.readonly
      },attrs:{"autocomplete":"autocomplete","type":_vm.inputType,"disabled":_vm.readonly,"required":_vm.required},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.reEmitInput($event)}}},'input',_vm.$props.name ? {name: _vm.$props.name} : {},false)),_vm._v(" "),_c('label',{staticClass:"pointer-events-none absolute z-10 font-bold transition-all ease-linear peer-focus:top-0 peer-focus:left-2 peer-focus:block peer-focus:bg-white peer-focus:px-2 peer-focus:text-2xs peer-focus:text-black",class:{
        'top-0 left-2 -translate-y-1/2 bg-white px-2 text-2xs text-black':
          _vm.value,
        'peer-hover:text-black': _vm.value && !_vm.readonly,
        'left-4 top-1/2 -translate-y-1/2 text-sm text-gray-500': !_vm.value,
        'text-gray-500': _vm.value && _vm.readonly,
        'text-red-450': _vm.value && (_vm.error || errors[0])
      }},[_vm._v("\n      "+_vm._s(_vm.label)+"\n    ")]),_vm._v(" "),(_vm.type === 'password' && _vm.value)?_c('div',{staticClass:"peer absolute right-3 top-1/2 z-10 -translate-y-1/2 cursor-pointer peer-focus:block flex items-center cursor-pointer",on:{"click":function($event){_vm.showPassword = !_vm.showPassword}}},[(_vm.type === 'password')?_c('Icon',{staticClass:"cursor-pointer peer-focus:block",class:{hidden: !_vm.showPassword},attrs:{"icon":"ic-eye-closed","size":"7"}}):_vm._e(),_vm._v(" "),(_vm.type === 'password')?_c('Icon',{staticClass:"cursor-pointer peer-focus:block",class:{hidden: _vm.showPassword},attrs:{"icon":"ic-eye","size":"7"}}):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.value && !_vm.readonly && _vm.type !== 'password')?_c('Icon',{staticClass:"peer absolute right-3 top-1/2 z-10 hidden -translate-y-1/2 cursor-pointer peer-focus:block",class:{'hover:block peer-hover:block': _vm.value, hidden: !_vm.value},attrs:{"icon":"icons-ic-cross-fill","size":"7"},nativeOn:{"click":function($event){return _vm.reset()}}}):_vm._e(),_vm._v(" "),(_vm.type !== 'password' && (_vm.error || errors[0]))?_c('Icon',{staticClass:"absolute right-[14px] top-1/2 z-10 -translate-y-1/2 cursor-pointer peer-focus:hidden",class:{'hover:hidden peer-hover:hidden': _vm.value},attrs:{"icon":"icons-ic-info-fill-warning","size":"6"}}):_vm._e()],1),_vm._v(" "),(errors || _vm.error)?_c('div',{staticClass:"ml-4 mt-1 text-2xs text-red-450"},[_vm._v("\n    "+_vm._s(errors[0] ? _vm.$t(errors[0], {count: _vm.errorCountSymbols}) : _vm.$t(_vm.error))+"\n  ")]):_vm._e()]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }