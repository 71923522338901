import UiHelpers from './ui'
import * as RouteHelpers from './route'
import ImageHelpers from './image'
import AttributeHelpers from './attribute'
import ProductHelpers from './product'
import DatesHelper from './date'

export default {
  ui: UiHelpers,
  route: RouteHelpers,
  image: ImageHelpers,
  attribute: AttributeHelpers,
  product: ProductHelpers,
  dates: DatesHelper
}
