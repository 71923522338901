
import {
  watch,
  unref,
  useRoute,
  ref,
  computed,
  defineComponent,
  useStore,
} from '@nuxtjs/composition-api'
import {useIsPage} from '~/composables/useIsPage'
import RedirectLink from './RedirectLink.vue'
import { useContext } from '@nuxtjs/composition-api'
import { useAppRedirectLink } from './useAppRedirectLink'

const RedirectToApp = defineComponent({
  components: {
    RedirectLink
  },
  setup() {
    const {$cookies} = useContext()
    const {isCheckoutWithoutOsp} = useIsPage()
    const route = useRoute()
    const store = useStore()
    const continueInTheBrowser = ref(false)
    const pendingRedirect = computed(() => {
      return (store.state as any).redirectBackToApp && !unref(isCheckoutWithoutOsp) && !unref(continueInTheBrowser)
    })
    const redirectUrl = useAppRedirectLink()

    if (process.client) {
      const redirectGuard = () => {
        if (unref(pendingRedirect)) {
          const headTag = document.body.parentNode?.children[0]

          if (headTag) {
            const metaRefresh = document.createElement('meta')
            metaRefresh.setAttribute('http-equiv', 'refresh')
            metaRefresh.setAttribute('content', `1s; url=${redirectUrl.value}`)

            headTag?.appendChild(metaRefresh)
          }

          // pendingRedirect.value = true
          setTimeout(() => {
            try {
              location.href = unref(redirectUrl)
            } catch (_) {
            }
          }, 300)
        }
      }

      watch(route, redirectGuard)
      redirectGuard()
    }

    return {
      pendingRedirect,
      allowBrowser: () => {
        $cookies.remove('ayo.redirectBackToApp')
        continueInTheBrowser.value = true
      }
    }

  }
})


export default RedirectToApp
