import { CustomStoreConfig } from "../lib"
import { huPaymentProviders } from "../lib/paymentProviders"
import { domains } from "../lib/domains"
import {appKeys} from "../lib/app-keys"

const HU: CustomStoreConfig = {
  code: 'hu',
  shopId: 5209,
  domain: domains.hu,
  appKeys,
  locale: 'hu-HU',
  currency: 'HUF',
  internationalPrefix: '36',
  paymentProviders: huPaymentProviders,
  orderPrefix: 'aohu',
  enableReturns: false,
  allProductsHaveOwnStockAttribute: true,
  lowest30DaysPriceEnabled: true,
  showDeliveryEstimates: false,
  currencyFractionDigits: 0
}

export default HU
