
import {defineComponent, useContext, useRouter} from '@nuxtjs/composition-api'

import Popover from '../ui/popovers/Popover.vue'
import Icon from '~/components/ui/icons/Icon.vue'
import Link from '~/components/ui/links/Link.vue'
import useUiState from '~/composables/ui/useUiState'
import {useIsCODEligible} from '~/composables/useCOD'
import {useAuthenticationCallback} from '~/composables/useAuthenticationCallback'

export default defineComponent({
  name: 'AccountPopover',
  components: {
    Popover,
    Icon,
    Link
  },
  setup() {
    const {$device} = useContext()
    const router = useRouter()
    const {openAccountFlyout, closeAccountFlyout, isAccountFlyoutOpen} =
      useUiState()
    const {logout} = useAuthenticationCallback()

    const {isCodEligible} = useIsCODEligible()

    const goToAccountArea = () => {
      if ($device.isDesktop) {
        router.push('/account/order')
      }
 else {
        router.push('/account')
      }
    }

    return {
      logout,
      openAccountFlyout,
      closeAccountFlyout,
      isAccountFlyoutOpen,
      goToAccountArea,
      isCodEligible
    }
  }
})
