
import { defineComponent, PropType } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'HeadlineBadge',
  props: {
    badge: {
      type: [Number, String] as PropType<number | string>,
      required: true,
    },
  },
})
