import {AxiosError} from 'axios';
import {hmac} from '@scayle/storefront-core/dist/utils/hash'

type ErrorWithHeaders = {
  config: {headers: any; [k: string]: any}
  [n: string]: any
}

const isErrorWithConfig = (
  error: object | ErrorWithHeaders
): error is ErrorWithHeaders => {
  return (
    !error ||
    error.constructor !== Object ||
    !('config' in error) ||
    !error.config.headers
  )
}

export const sanitizeErrorObject = (error: object) => {
  if (!isErrorWithConfig(error)) {
    return error
  }

  return {
    ...error,
    config: {
      ...error?.config,
      headers: {
        ...error.config.headers,
        Authorization: undefined
      }
    }
  }
}

export const encodeBase64 = (string: string) => {
  if (process.server) {
    return Buffer.from(string).toString('base64')
  }

  return btoa(string)
}

export const decodeBase64 = (string: string) => {
  if (process.server) {
    return Buffer.from(string, 'base64').toString('utf-8')
  }

  return atob(string)
}

/**
 * It is important to convert error to the format that catch in rpcCall expects,
 * rpcCall waits for code and message be present in the error otherwise it falls back to 500
 */
export const convertErrorForRpcCall = (error: any, httpStatuses: Array<number>): void | never => {
  if (error instanceof AxiosError) {
    if (
      error.response?.status && httpStatuses.includes(error.response.status)
    ) {
      error.response = {
        ...error.response,
        data: {
          ...error.response.data,
          code: error.response.status,
          message: error.response.data
        }
      }

      throw error
    }
  }
}

export const createAxiosError = (status: number, message: string, url: string) => {
  return new AxiosError(
    message,
    String(status),
    {
      url
    },
    null,
    {
      data: {
        code: status,
        message
      },
      status,
      statusText: message,
      headers: {},
      config: {}
    }
  )
}

/**
 * verifyToken from @scayle/storefront-core can't be used since it works only on server-side with Buffer
 * @param token
 * @param secret
 */
export const verifyTokenSensitive = async (
  token: string,
  secret: string
): Promise<string | undefined> => {
  const [encodedPayload, signature] = token.split('.', 2)
  const expectedSignature = encodeBase64(await hmac(encodedPayload, secret))
  if (expectedSignature === signature) {
    return decodeBase64(encodedPayload)
  }
}
