import { CustomStoreConfig } from "../lib"
import { slPaymentProviders} from '../lib/paymentProviders'
import { domains } from "../lib/domains"
import {appKeys} from "../lib/app-keys"

const SI: CustomStoreConfig = {
  code: 'si',
  shopId: 5206,
  domain: domains.si,
  appKeys,
  locale: 'sl-SI',
  currency: 'EUR',
  internationalPrefix: '386',
  paymentProviders: slPaymentProviders,
  orderPrefix: 'aosi',
  showAllProducts: false,
  allProductsHaveOwnStockAttribute: true,
  lowest30DaysPriceEnabled: true,
  showDeliveryEstimates: false
}

export default SI
