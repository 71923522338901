export const INVALID_PASSWORD_MSG = 'myaccount.password_form.errors.invalid_new_password'
export const PASSWORD_MIN_LEN = 6

export function checkPassword(password: string): boolean {

  // Turn off for now
  return true

  // Includes capital letter
  if(!password.match(/[A-Z]/g)) {
    return false
  }
  // Includes special characters
  if(!password.match(/[!?.<>`~\-@#$%^&*()_[\]~/>,|}{+]/g)) {
    return false
  }

  // Includes a digit
  if(!password.match(/\d/g)) {
    return false
  }

  return true
}

export function checkLength(password: string): boolean {
  return password.length >= PASSWORD_MIN_LEN
}
