
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'SlideInFromBottomTransition',

  setup(props, context) {
    const enter = () => {
      context.emit('enter')
    }
    const afterEnter = () => {
      context.emit('after-enter')
    }
    const leave = () => {
      context.emit('leave')
    }
    const afterLeave = () => {
      context.emit('after-leave')
    }

    return {
      enter,
      afterEnter,
      leave,
      afterLeave,
    }
  },
})
