
import {
  computed,
  defineComponent,
  useContext,
  useRoute
} from '@nuxtjs/composition-api'
import {onFetchAsync, useUserWithParams} from '@scayle/storefront-nuxt2'
import HeaderLoggedIn from '~/components/layout/headers/HeaderLoggedIn.vue'
import HeaderLoggedOut from '~/components/layout/headers/HeaderLoggedOut.vue'
import {useCmsStories} from '~/composables/useCms'
import Banner from '~/components/ui/Banner.vue'
import FadeInTransition from '~/components/ui/transitions/FadeInTransition.vue'
import {isNowBetween} from '~/helpers/date'
import {useHeaderOffset} from '~/composables/ui/useHeaderOffset'

export default defineComponent({
  name: 'IssueLayout',
  components: {
    HeaderLoggedIn,
    HeaderLoggedOut,
    Banner,
    FadeInTransition
  },
  setup() {
    const {i18n} = useContext()
    const {isLoggedIn} = useUserWithParams({})
    const {data: bannerData, fetchByFolder} = useCmsStories('banners')
    const route = useRoute()

    useHeaderOffset()

    const bannerForActiveLocale = computed(() => {
      if (!bannerData || !bannerData.value) return null
      return bannerData.value.find((banner: any) =>
        banner.content.countries.includes(i18n.locale)
      )
    })

    const bannerDates = computed(() => {
      return {
        start: bannerForActiveLocale.value.content.start_date_time,
        end: bannerForActiveLocale.value.content.end_date_time
      }
    })

    const showBanner = computed(() => {
      if (!bannerForActiveLocale.value) return false
      return isNowBetween(bannerDates.value.start, bannerDates.value.end)
    })

    onFetchAsync(async () => {
      await fetchByFolder({
        folder: 'banners',
        callingComponent: 'issue.vue (Layout)',
        route: route.value.fullPath
      })
    })

    return {
      bannerForActiveLocale,
      showBanner,
      isLoggedIn
    }
  }
})
