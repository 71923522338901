import {CacheOptions, useRpc} from '@scayle/storefront-nuxt2'
import {computed, unref} from '@nuxtjs/composition-api'

export const cmsCacheKeys = {
  deals: 'deals',
  appProducts: 'allProducts'
}

export const useDatasource = (datasourceName: string, key = 'useDatasource', mockData?: {datasource_entries: Array<{
  id: number,
  name: string,
  value: any,
  dimension_value: null
}>}) => {
  const {data, fetch, fetching} = useRpc('getCmsDatasource', key)

  const fetchByDatasourceName = async ({
    callingComponent,
    route,
    cacheOptions
  }: {
    callingComponent: string
    route: string
    cacheOptions?: CacheOptions
  }) => {
    try {
      return await fetch({
        datasource: datasourceName,
        callingComponent,
        route,
        cacheOptions
      })
    }
    catch (e) {
      data.value = null
    }
  }

  return {data: computed(() => mockData?.datasource_entries || unref(data)), fetchByDatasourceName, fetching}
}
