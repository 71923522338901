import {defineNuxtPlugin} from '@nuxtjs/composition-api'

declare module '@nuxt/types' {
  interface Context {
    $openProducts: Map<`product-is-opened-${number}`, boolean>
  }
}

/**
 * This plugin does the same what ssrRef would do, putting value in window.__NUXT__,
 * but since this is in context, the value is available in all components and middleware.
 *
 * In Nuxt3 this plugin is replaced with a single simple useState.
 */
export default defineNuxtPlugin((ctx, inject) => {
  if (process.server) {
    const $openProducts = new Map()
    ctx.beforeNuxtRender(({nuxtState}) => {
      nuxtState.$openProducts = $openProducts
    })
    inject('openProducts', $openProducts)
  }
  else {
    inject('openProducts', (window as any).__NUXT__.$openProducts || new Map())
  }
})
