import Vue from 'vue';
import {
  SbBold,
  SbStrong,
  SbStrike,
  SbUnderline,
  SbItalic,
  SbCode,
  SbLink,
  SbStyled,
  SbAnchor
} from '../elements/marks';
import {Mark} from '~/@types/rich-text-renderer-types';

export type MarkResolvers = Partial<Record<keyof typeof Mark, typeof Vue>>

export const defaultMarkResolvers: MarkResolvers = {
  // @ts-ignore
  [Mark.BOLD]: SbBold,
  [Mark.STRONG]: SbStrong,
  [Mark.STRIKE]: SbStrike,
  [Mark.UNDERLINE]: SbUnderline,
  [Mark.ITALIC]: SbItalic,
  [Mark.CODE]: SbCode,
  [Mark.LINK]: SbLink,
  [Mark.STYLED]: SbStyled,
  [Mark.ANCHOR]: SbAnchor
};
