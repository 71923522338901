import { computed, reactive, useContext } from '@nuxtjs/composition-api'
import { Category } from '@scayle/storefront-nuxt2'

const uiState = reactive({
  sideNavigationOpen: false,
  flyoutMenuOpen: false,
  flyoutMenuCategory: {
    name: '',
    path: '',
    slug: '',
    id: 1,
    children: [] as Array<Category>,
  },
  searchFlyout: false,
  mobileSearchIsActive: false,
  basketFlyout: false,
  accountFlyout: false,
  selectedSideNavigationEntry: undefined as Category | undefined,
})
export default () => {
  const { $device } = useContext()
  const isSideNavigationOpen = computed(() => uiState.sideNavigationOpen)
  const closeSideNavigation = () => {
    uiState.sideNavigationOpen = false
  }
  const openSideNavigation = () => {
    uiState.sideNavigationOpen = true
  }

  const selectSideNavigationEntry = (entry: Category) => {
    uiState.selectedSideNavigationEntry = entry

    if (entry.depth > 3 || entry.children?.length === 0) {
      uiState.sideNavigationOpen = false
    }
  }

  const isFlyoutMenuOpen = computed(() => uiState.flyoutMenuOpen)
  const flyoutMenuCategory = computed(() => uiState.flyoutMenuCategory)

  const closeFlyoutMenu = (event: MouseEvent, force: boolean = false) => {
    const relatedTarget = event.relatedTarget as Element
    if (
      ![
        'flyout-menu',
        'flyout-menu-items-container',
        'flyout-overscroll-container',
        'flyout-menu-items-container-content',
      ].includes(relatedTarget?.id) ||
      force
    ) {
      uiState.flyoutMenuOpen = false
      uiState.flyoutMenuCategory = {
        name: '',
        path: '',
        slug: '',
        id: 1,
        children: [],
      }
    }
  }
  const openFlyoutMenu = ({ name, path, slug, id, children }: Category) => {
    if (children?.length === 0) {
      uiState.flyoutMenuOpen = false
      return
    }

    uiState.flyoutMenuOpen = true
    uiState.flyoutMenuCategory = {
      name,
      path,
      slug,
      id,
      children: children || [],
    }
  }

  const isSearchFlyoutOpen = computed(() => uiState.searchFlyout)

  const openSearchFlyout = () => {
    uiState.searchFlyout = true
  }

  const closeSearchFlyout = () => {
    uiState.searchFlyout = false
  }

  const openBasketFlyout = (duration = 0) => {
    if (!$device.isMobile) {
      uiState.basketFlyout = true
    }
    if (duration !== 0) {
      window.setTimeout(function () {
        closeBasketFlyout()
      }, duration)
    }
  }

  const closeBasketFlyout = () => {
    uiState.basketFlyout = false
  }

  const openAccountFlyout = (duration = 0) => {
    if (!$device.isMobile) {
      uiState.accountFlyout = true
    }
    if (duration !== 0) {
      window.setTimeout(function () {
        closeAccountFlyout()
      }, duration)
    }
  }

  const closeAccountFlyout = () => {
    uiState.accountFlyout = false
  }

  const mobileSearchIsActive = computed(() => uiState.mobileSearchIsActive)

  const setMobileSearchIsActive = (value: boolean) =>
    (uiState.mobileSearchIsActive = value)

  return {
    isSideNavigationOpen,
    closeSideNavigation,
    openSideNavigation,
    selectSideNavigationEntry,
    isFlyoutMenuOpen,
    flyoutMenuCategory,
    closeFlyoutMenu,
    openFlyoutMenu,
    isSearchFlyoutOpen,
    closeSearchFlyout,
    openSearchFlyout,
    mobileSearchIsActive,
    setMobileSearchIsActive,
    selectedSideNavigationEntry: computed(
      () => uiState.selectedSideNavigationEntry
    ),
    isBasketFlyoutOpen: computed(() => uiState.basketFlyout),
    openBasketFlyout,
    closeBasketFlyout,
    openAccountFlyout,
    closeAccountFlyout,
    isAccountFlyoutOpen: computed(() => uiState.accountFlyout),
  }
}
