import {Context} from "@nuxt/types"
import {Inject} from "@nuxt/types/app";
import {AxiosError} from "axios";

declare module '@nuxt/types' {
  interface Context {
    $network: {
      session: {
        errorHandler: (error: AxiosError) => void;
      }
      circuitBreaker: <T>(key: string, promise: Promise<T>) => Promise<T>
    }
  }
}

const HTTP_STATUS_UNAUTHORIZED = 401
const HTTP_STATUS_FORBIDDEN = 403

const CIRCUIT_BREAKER_TIMEOUT = 5000 // majority of requests hang up to 40 - 50 sec

export default function ({$cookies, store, $log}: Context, inject: Inject) {
  function errorHandler(axiosError: any) {
    if([HTTP_STATUS_UNAUTHORIZED, HTTP_STATUS_FORBIDDEN].includes(axiosError.response.status)) {
      $cookies.remove('ayc-user')
      $cookies.remove('$session')
      store.dispatch('auth/showErrorMessage')
    }
  }

  function circuitBreaker<T>(key: string, promise: Promise<T>): Promise<T> {
    const timeout = CIRCUIT_BREAKER_TIMEOUT;

    let timeoutId: number;

    const timeoutPromise = new Promise<T>((_resolve, reject) => {
      // @ts-ignore
      timeoutId = setTimeout(() => {
        const reason = `Request timed out for [${key}]`;
        $log.error(reason)
        reject(new Error(reason));
      }, timeout)
    });

    const result = Promise.race([promise, timeoutPromise]);

    // If the fetch promise was faster than the timeout, cancel the timeout
    if (!result.finally) { // Check if the Promise.prototype.finally() method is available
      // @ts-ignore
      clearTimeout(timeoutId);
    }
    else {
      result.finally(() => clearTimeout(timeoutId));
    }

    return result;
  }

  inject('network', {
    session: {
      errorHandler
    },
    circuitBreaker
  })
}
