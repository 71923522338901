
import {onMounted,useContext,defineComponent} from '@nuxtjs/composition-api'
import Logo from '~/components/logo/Logo.vue'

export default defineComponent({
  name: 'PlanningLayout',
  components: {
    Logo
  },
  middleware: (ctx) => {
    if(ctx.$config.shopSystem === 'production') {
      ctx.redirect('/deals/f')
    }
  },
  setup() {
    const {$config} = useContext()

    return {
      isProduction: $config.shopSystem === 'production'
    }
  },
  head: {}
})
