
import {defineComponent, ref} from '@nuxtjs/composition-api';
import {StyledAttributes} from '~/@types/rich-text-renderer-types';
export default defineComponent({
  name: 'SbStyled',
  props: {
    attrs: {
      type: Object as () => StyledAttributes,
      required: true
    }
  },
  setup({attrs}) {
    const elementClass = ref(attrs.class);
    return {
      elementClass
    };
  }
});
