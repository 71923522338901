import {
  getAppliedReductionsByCategory,
  getFirstAttributeValue,
  getPrice,
  Product,
  toFloat,
  TrackingCategory,
  Order,
  WishlistResponseData,
  Variant,
  Price,
  ProductCategory,
  AppliedReduction,
  getTotalAppliedReductions,
  BasketData
} from '@scayle/storefront-nuxt2'

// TODO: import from storefront library once it works on client and not on NodeJS only
import sha256 from 'crypto-js/sha256'
import isEqual from 'lodash/isEqual'
import {
  CustomerData,
  CustomerInfo,
  ProductActionData,
  ProductInfo,
  ProductListData,
  ProductViewData,
  TrackingEvent,
  TrackingPayload,
  AdditionalInfo,
  ViewInfo,
  AdditionalTrackingEvent,
  MultipleActionData
} from '../types'
import productHelpers from '~/helpers/product'

const getCommonData = (payload: any) => ({
  ...(payload.content_name ? {content_name: payload.content_name} : {}),
  ...(payload.page_type ? {page_type: payload.page_type} : {}),
  ...(payload.page_type_id ? {page_type_id: payload.page_type_id} : {})
})

/**
 * Checks if event is one of: AdditionalTrackingEvent = 'cart' | 'wishlist' | 'search' | 'filter_flyout' | 'filter_slider'
 */
export function isAdditionalTrackingEvent(
  event: TrackingEvent
): event is AdditionalTrackingEvent {
  return ['cart', 'wishlist'].includes(<AdditionalTrackingEvent>event)
}

/**
 * Checks if event is one of: 'view_promotion', 'select_promotion'
 */
export function isPromotionTrackingEvent(event: TrackingEvent): boolean {
  return ['view_promotion', 'select_promotion'].includes(event)
}

/**
 * Checks if payload.items is not undefined
 */
export function isProductImpressionsData(
  data: TrackingPayload | MultipleActionData
): data is MultipleActionData {
  return (<MultipleActionData>data).items !== undefined
}

export const mapProductToTrackingPayload = (
  product: Product,
  variant?: Variant
): ProductInfo => {
  const price = variant
    ? getPrice(variant)
    : productHelpers.getLowestPriceFromVariantsInStock(product)

  const getFloatedReducedPriceForCategoryOrNull = (price: Price, type: 'sale' | 'campaign') => {
    const floatPrice = toFloat(getAppliedReductionsByCategory(price, type)[0]?.amount.absoluteWithTax)
    return isNaN(floatPrice) ? 0.0 : floatPrice
  }

  const displayPrice = toFloat(price.withTax)
  const displayPriceWithoutTax = toFloat(price.withoutTax)
  const saleDiscount = getFloatedReducedPriceForCategoryOrNull(price, 'sale')
  const campaignDiscount = getFloatedReducedPriceForCategoryOrNull(price, 'campaign')
  const originalPrice = price.appliedReductions?.length > 0
    ? toFloat(
      getTotalAppliedReductions(price)?.absoluteWithTax +
      (price?.withTax || 0)
    )
    : toFloat(price?.withTax || 0)


  return {
    item_id: product.id.toString(),
    item_name: getFirstAttributeValue(product.attributes, 'name')!.label,
    price: displayPrice,
    price_without_tax: displayPriceWithoutTax,
    sale_discount: saleDiscount,
    campaign_discount: campaignDiscount,
    original_price: originalPrice,
    bi_price: `${displayPrice}|${saleDiscount}|${campaignDiscount}|${originalPrice}`,
    item_brand: getFirstAttributeValue(product.attributes, 'brand')?.label!,
    item_brand_id: getFirstAttributeValue(
      product.attributes,
      'brand'
    )?.id!.toString()!
  }
}
// TODO: we are overfetching the basket items to provide the variantId `product.variants?.[0]?.id.toString()`
// instead, provide the variantId from another source and remove basket overfetching
// Alternatively, we can already compute the tracking info on the backend
// @see `apps/aboutyou-outlet-v1/rpcMethods/basket.ts#resolvedWith`
const mapAdditionalInfo = (
  data: ProductActionData | ProductListData | ProductViewData
): AdditionalInfo | ViewInfo => {
  const {product, list} = data
  const {name, id} = data.category ||
    getDeepestCategoryForTracking(product.categories) || {name: '', id: ''}

  let mappedPayload: AdditionalInfo | ViewInfo = {
    item_category: name,
    item_category_id: id,
    item_variant: product.variants?.[0]?.id.toString() || '',
    item_list_name: list?.name || 'TBD',
    item_list_id: list?.id || 'TBD'
  }

  if (list?.index && list.index > -1) {
    mappedPayload.index = list.index
  }

  const {
    source = '',
    destination = '',
    destinationUrl = '',
    quantity = 1,
    variant,
    openly_accessible = '0'
  } = data as ProductViewData & ProductActionData

  if (source) {
    mappedPayload = {...mappedPayload, source}
  }

  if (destination) {
    mappedPayload = {...mappedPayload, destination}
  }

  if (destinationUrl) {
    mappedPayload = {...mappedPayload, destination_url: destinationUrl}
  }

  if (quantity > -1) {
    mappedPayload = {...mappedPayload, quantity: quantity.toString()}
  }

  if (product.isSoldOut) {
    mappedPayload = {...mappedPayload, sold_out: product.isSoldOut}
  }

  if (variant) {
    mappedPayload = {...mappedPayload, item_variant: variant.id.toString()}
  }

  if ('index' in product && (product as any).index > -1) {
    mappedPayload = {...mappedPayload, index: product.index}
  }

  mappedPayload = {...mappedPayload, openly_accessible}

  return mappedPayload
}
/**
 * Calculate total cart price after discounts
 *
 * Example items data:
 * ```
 * {
 *    "item_id": "8909430",
 *    "item_name": "Hemd 'Baran'",
 *    "price": 17.45,               // <-- We need this price
 *    "original_price": 69.9,       // <-- ! Not this one
 *    "sale_discount": 35,
 *    "campaign_discount": 17.45,
 *    "bi_price": "17.45|35|17.45|69.9",
 *    "item_brand": "ABOUT YOU x Alvaro Soler",
 *    "item_brand_id": "169989",
 *    "item_category": "Casual Hemden",
 *    "item_category_id": "23687",
 *    "item_variant": "53578696",
 *    "item_list_name": "TBD",
 *    "item_list_id": "TBD",
 *    "quantity": "1"
 * }
 * ```
 *
 * @param items
 * @returns
 */
const getTotalPriceInfo = (
  items: {
    quantity: number
    // eslint-disable-next-line camelcase
    campaign_discount: number
    // eslint-disable-next-line camelcase
    sale_discount: number
    price: number
    price_without_tax: number
  }[]
) => {
  const total = {
    total_campaign_reduction_with_tax: 0.0,
    total_sale_reduction_with_tax: 0.0,
    total_with_tax: 0.0,
    total_without_tax: 0.0
  }

  items.forEach(item => {
    total.total_campaign_reduction_with_tax +=
      item.campaign_discount * item.quantity
    total.total_sale_reduction_with_tax += item.sale_discount * item.quantity
    total.total_with_tax += item.price * item.quantity
    total.total_without_tax += item.price_without_tax * item.quantity
  })

  Object.keys(total).forEach(
    key =>
      ((total as any)[key] = Number(((total as any)[key] as number).toFixed(2)))
  )

  return total
}

export const mapCustomerInfoToTrackingPayload = ({
  method,
  eh,
  customer_id: customerId,
  status
}: CustomerData): CustomerInfo => {
  const mappedPayload: CustomerInfo = {
    method,
    eh: eh || '',
    status
  }
  if (customerId) {
    mappedPayload.customer_id = customerId
  }
  return mappedPayload
}

// @todo refactor
export const mapTrackingDataForEvent = (
  event: TrackingEvent,
  payload: TrackingPayload
) => {
  let data = {}
  if (isPromotionTrackingEvent(event)) {
    data = {
      ecommerce: payload
    }
  }
  else if (
    isAdditionalTrackingEvent(event) &&
    isProductImpressionsData(payload)
  ) {
    const items = (payload as BasketData).items.filter(item => item?.product && item?.variant).map(payload => ({
      ...mapProductToTrackingPayload(payload.product),
      ...mapAdditionalInfo(payload)
    }))

    // @ts-ignore
    data.items = items
    const totalPrice = getTotalPriceInfo(
      items.map(item => ({
        price: item.price,
        price_without_tax: item.price_without_tax,
        quantity: item.quantity ? parseInt(item.quantity!) : 1,
        campaign_discount: item.campaign_discount,
        sale_discount: item.sale_discount
      }))
    )

    data = {
      ...data,
      ...totalPrice
    }
  }
  // begin_checkout
  // view_item_list
  else if (isProductImpressionsData(payload)) {
    const currency = 'currencyCode' in payload && payload.currencyCode
    data = {
      ecommerce: {
        ...(currency ? {currency} : {}),
        items: payload.items.map(payload => ({
          ...mapProductToTrackingPayload(payload.product),
          ...mapAdditionalInfo(payload)
        }))
      }
    }
  }
  else if ('product' in payload) {
    const currency = (payload as typeof payload & { currencyCode: string })
      .currencyCode

    data = {
      ecommerce: {
        ...(currency ? {currency} : {}),
        items: [
          {
            ...mapProductToTrackingPayload(
              payload.product,
              'variant' in payload ? payload.variant : undefined
            ),
            ...mapAdditionalInfo(payload)
          }
        ]
      }
    }
  }
 else {
    data = {
      ...payload
    }
  }

  return {
    event,
    ...data,
    ...getCommonData(payload)
  }
}

/**
 *
 * @param categories
 * @returns most specific category for a product
 */
function getDeepestCategory(
  categories: ProductCategory[][] = []
): ProductCategory | null {
  if (!categories.length) return null
  let depth = {index: 0, value: 0}
  categories.forEach((category, index) => {
    if (category.length > depth.value) {
      depth = {index, value: category.length}
    }
    if (category.length === depth.value) {
      depth.index = index
    }
  })

  return categories[depth.index][categories[depth.index].length - 1]
}

export function getDeepestCategoryForTracking(
  categories: ProductCategory[][] = []
): TrackingCategory {
  const {categoryName, categoryId} = getDeepestCategory(categories) || {
    categoryName: '',
    categoryId: ''
  }
  return {
    name: categoryName,
    id: (categoryId || '').toString()
  }
}

/**
 * Compares data of type WishlistResponseData (wishlist or basket)
 * @param oldData previous data state (eg wishlist data {items, key})
 * @param newData new data state (eg wishlist data {items, key})
 * @returns boolean if a tracking significant change is detected
 */
export function didWishlistOrBasketDataChange(
  oldData: WishlistResponseData | null,
  newData: WishlistResponseData | null
) {
  return !isEqual(
    {
      items: oldData?.items.map(item => ({
        productId: item.product?.id,
        variantId: item.variant?.id,
        price: item.product?.priceRange,
        quantity: (item as any).quantity,
        soldOut: item.product?.isSoldOut
      })),
      key: oldData?.key
    },
    {
      items: newData?.items.map(item => ({
        productId: item.product?.id,
        variantId: item.variant?.id,
        price: item.product?.priceRange,
        quantity: (item as any).quantity,
        soldOut: item.product?.isSoldOut
      })),
      key: newData?.key
    }
  )
}

// same as formatPrice from @scayle/storefront-nuxt2 without using the useContext
export const formatPriceWithCurrency = (
  value: number,
  locale: string,
  currency: string
): string => {
  const currencyFractionDigits = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency
  }).resolvedOptions().maximumFractionDigits

  return toFloat(value).toLocaleString(locale, {
    minimumFractionDigits: currencyFractionDigits,
    useGrouping: false
  })
}

export const sumReductions = (reductions: AppliedReduction[]) => {
  if (!reductions) return 0
  return reductions.reduce((sum: number, reduction: AppliedReduction) => {
    return sum + reduction.amount.absoluteWithTax
  }, 0)
}

export const sumReductionsByCategory = (
  reductions?: AppliedReduction[],
  category?: AppliedReduction['category']
) => {
  if (!reductions) return 0
  return sumReductions(
    reductions.filter(reduction => reduction.category === category)
  )
}

export const sumReductionsFromAllOrderItemsPerCategory = (
  orderItems: Order['items'],
  category: AppliedReduction['category']
) => {
  if (!orderItems) return 0
  return orderItems.reduce((sum: number, orderItem: any) => {
    return (
      sum +
      sumReductionsByCategory(orderItem?.price?.appliedReductions, category)
    )
  }, 0)
}

export const getFirstCarrierKey = (orderData: any) => {
  return orderData.packages?.[0]?.carrierKey
}

// gift card is not handled yet, so for now is just a placeholder
export const getGiftcardAmount = ({
  amount,
  currency,
  locale
}: {
  amount: number
  currency: string
  locale: string
}) => {
  return new Intl.NumberFormat(locale.replace('_', '-'), {
    style: 'currency',
    currency,
    maximumFractionDigits: 0
  }).format(amount)
}

export const sha256Email = (email?: string) => {
  if (!email) {
    return ''
  }

  return sha256(email.trim().toLowerCase()).toString()
}
