import { CustomStoreConfig } from "../lib/type"
import { ltPaymentProviders } from '../lib/paymentProviders'
import { domains } from "../lib/domains"
import {appKeys} from "../lib/app-keys"

const LT: CustomStoreConfig = {
  code: 'lt',
  shopId: 5213,
  domain: domains.lt,
  appKeys,
  locale: 'lt-LT',
  currency: 'EUR',
  internationalPrefix: '',
  paymentProviders: ltPaymentProviders,
  orderPrefix: 'aolt',
  lowest30DaysPriceEnabled: true,
  allProductsHaveOwnStockAttribute: true,
  enableReturns: true,
  showDeliveryEstimates: false
}

export default LT
