const sanitizeDomain = (url: string): string => {
  if (url.includes('www')) {
    return url.replace('www.', '')
  }
  return url
}

const getOneTrustId = (
  isLive: boolean,
  domain: string,
  oneTrustIds: any,
  domains: any
): string => {
  const sanitizedDomain = sanitizeDomain(domain)
  if (isLive) {
    switch (sanitizedDomain) {
      case domains.de:
        return oneTrustIds.de
      case domains.at:
        return oneTrustIds.at
      case domains.cz:
        return oneTrustIds.cz
      case domains.sk:
        return oneTrustIds.sk
      case domains.hu:
        return oneTrustIds.hu
      case domains.ro:
        return oneTrustIds.ro
      case domains.sl:
        return oneTrustIds.sl
      case domains.hr:
        return oneTrustIds.hr
      case domains.bg:
        return oneTrustIds.bg
      case domains.lt:
        return oneTrustIds.lt
      case domains.lv:
        return oneTrustIds.lv
      case domains.el:
        return oneTrustIds.gr
      case domains.pl:
          return oneTrustIds.pl
      default:
        return oneTrustIds.default
    }
  }
  switch (sanitizedDomain) {
    case domains.de:
      return `${oneTrustIds.de}-test`
    case domains.at:
      return `${oneTrustIds.at}-test`
    case domains.cz:
      return `${oneTrustIds.cz}-test`
    case domains.sk:
      return `${oneTrustIds.sk}-test`
    case domains.hu:
      return `${oneTrustIds.hu}-test`
    case domains.ro:
      return `${oneTrustIds.ro}-test`
    case domains.sl:
      return `${oneTrustIds.sl}-test`
    case domains.hr:
      return `${oneTrustIds.hr}-test`
    case domains.el:
      return `${oneTrustIds.gr}-test`
    case domains.bg:
      return `${oneTrustIds.bg}-test`
    case domains.pl:
      return `${oneTrustIds.pl}-test`
    case domains.lt:
      return `${oneTrustIds.lt}-test`
    case domains.lv:
      return `${oneTrustIds.lv}-test`
    default:
      return `${oneTrustIds.default}-test`
  }
}

const getLanguageKey = (lang: string) => {
  switch (lang) {
    case 'cz':
      return 'cs'
    case 'at':
      return 'de'
    case 'sk':
      return 'sk'
    case 'hu':
      return 'hu'
    case 'ro':
      return 'ro'
    case 'sl':
    case 'si':
      return 'sl'
    case 'hr':
      return 'hr'
    case 'lt':
      return 'lt'
    case 'lv':
        return 'lv'
    case 'bg':
      return 'bg'
    case 'pl':
      return 'pl'
    case 'el':
      return 'el'
    default:
      return 'de'
  }
}

const ONE_TRUST_SCRIPT_SRC = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'

const selectors = ['category-host-list-handler', 'ot-gv-list-handler']

const addOneTrustScript = (
  isLive: boolean,
  domain: string,
  oneTrustIds: any,
  domains: any,
  lang: string
): void => {
  if (document.querySelector(`script[src="${ONE_TRUST_SCRIPT_SRC}"]`)) {
    return;
  }

  let retries = 0;
  const openPressCenterByHash = () => {
    if(location.hash === '#press-center') {
      // Optanon is not initialized, retry after 300ms
      if(!window.Optanon) {
        if(retries < 4) {
          retries++;
          setTimeout(() => openPressCenterByHash(), 300)
        }
      } else {
        window.Optanon.ToggleInfoDisplay()
      }
    }

    if(location.hash === '#partners-list') {

      selectors.forEach(selector => {
        const partersListOpener = document.getElementsByClassName(selector)
        if(partersListOpener.length) {
          window.Optanon.ToggleInfoDisplay()
          // @ts-ignore
          partersListOpener[0]?.click()
        }
      })

    }
  }


  window.addEventListener("hashchange", openPressCenterByHash)


  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.src = ONE_TRUST_SCRIPT_SRC
  script.setAttribute('data-language', getLanguageKey(lang))
  script.onload = () => {
    // wait for the script to load and check if check if we need to open the press center
    setTimeout(() => {
      openPressCenterByHash()
    // arbritrary number, just in case the script needs some time to boot
    }, 300)
  }

  script.setAttribute(
    'data-domain-script',
    getOneTrustId(isLive, domain, oneTrustIds, domains)
  )

  const script2 = document.createElement('script')
  script2.type = 'text/javascript'
  script2.src = '/js/oneTrust.js'

  document.head.appendChild(script)
  document.head.appendChild(script2)
}

export default addOneTrustScript
