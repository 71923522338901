import {computed, unref, useRoute} from '@nuxtjs/composition-api'

/**
 * TODO: use in other components and pages
 */
export const useIsPage = () => {
  const route = useRoute()

  const isPDPPage = computed(() => !!route.value.name?.startsWith('p-brand-slug-id'))
  const isServicePage = computed(() => !!route.value.name?.startsWith('s-slug___'))
  const isCheckout = computed(() => !!route.value.name?.startsWith('checkout'))
  // also starts with checkout
  const isOsp = computed(() => !!route.value.name?.includes('success') )
  const isDDPage = computed(() => !!route.value.name?.startsWith('deals-category-id_'))
  const isAllProductsPage = computed(() => !!route.value.name?.startsWith('all-products-_'))
  const isLandingPage = computed(() => route.value.fullPath === '/')
  const isCheckoutWithoutOsp = computed(() => {
    return unref(isCheckout) && !unref(isOsp)
  })

  return {
    isLandingPage,
    isPDPPage,
    isDDPage,
    isServicePage,
    isCheckout,
    isAllProductsPage,
    isOsp,
    isCheckoutWithoutOsp
  }
}
