export const phoneNumberFormats = {
  'en-EN': {mask: '+## #### ######', placeholder: '+49 **** ******'},
  'de-DE': {mask: '+## ### ########', placeholder: '+49 *** ********'},
  'de-AT': {mask: '+## ## ### ## ##', placeholder: '+41 ** *** ** **'},
  'de-CH': {mask: '+## # ### ## ##', placeholder: '+43 * *** ** **'},
  'fr-CH': {mask: '+## ## ########', placeholder: '+31 ** ********'},
  'fr-BE': {mask: '+## ### ## ##', placeholder: '+32 *** ** **'},
  'nl-BE': {mask: '+## ### ## ##', placeholder: '+32 *** ** **'},
  'nl-NL': {mask: '+## ## ########', placeholder: '+31 ** ********'},
  'cs-CZ': {mask: '+420 ### ######', placeholder: '+420 *** ******'},
  'sk-SK': {mask: '+421 #### ### ###', placeholder: '+421 **** *** ***'},
  'sl-SI': {mask: '+386 ### ### ###', placeholder: '+386 *** *** ***'},
  'ro-RO': {mask: '+40 #### ### ###', placeholder: '+40 **** *** ***'},
  'hu-HU': {mask: '+36 ## ### ####', placeholder: '+36 ** *** ****'},
  'hr-HR': {mask: '+385 ### ### ####', placeholder: '+385 *** *** ****'},
  'bg-BG': {mask: '+359 ### ### ###', placeholder: '+359 *** *** ***'},
  'pl-PL': {mask: '+48 ### ### ###', placeholder: '+48 *** *** ***'},
  'lt-LT': {mask: '+370 #### #####', placeholder: '+370 **** *****'},
  'lv-LV': {mask: '+371 ## ### ###', placeholder: '+371 ** *** ***'},
  'el-GR': {mask: '+30 ### ### ####', placeholder: '+30 *** *** ****'}
}

export const dateOfBirthFormats = {
  'en-EN': {mask: '##.##.####', placeholder: 'dd.mm.yyyy'},
  'de-DE': {mask: '##.##.####', placeholder: 'dd.mm.yyyy'},
  'de-AT': {mask: '##.##.####', placeholder: 'dd.mm.yyyy'},
  'de-CH': {mask: '##.##.####', placeholder: 'dd.mm.yyyy'},
  'fr-CH': {mask: '##.##.####', placeholder: 'dd.mm.yyyy'},
  'fr-BE': {mask: '##/##/####', placeholder: 'dd/mm/yyyy'},
  'nl-BE': {mask: '##/##/####', placeholder: 'dd/mm/yyyy'},
  'nl-NL': {mask: '##.##.####', placeholder: 'dd.mm.yyyy'},
  'sk-SK': {mask: '##.##.####', placeholder: 'dd.mm.yyyy'},
  'sl-SI': {mask: '##.##.####', placeholder: 'dd.mm.yyyy'},
  'ro-RO': {mask: '##.##.####', placeholder: 'dd.mm.yyyy'},
  'hu-HU': {mask: '####.##.##', placeholder: 'yyyy.mm.dd'},
  'hr-HR': {mask: '##.##.####', placeholder: 'dd.mm.yyyy'},
  'bg-BG': {mask: '##.##.####', placeholder: 'dd.mm.yyyy'},
  'pl-PL': {mask: '##.##.##', placeholder: 'dd.mm.yy'},
  'lt-LT': {mask: '#### ## ##', placeholder: 'yyyy mm dd'},
  'lv-LV': {mask: '##.##.####', placeholder: 'dd.mm.yyyy'},
  'el-GR': {mask: '##/##/####', placeholder: 'dd/mm/yyyy'},
}

/**
 * @see {@link https://en.wikipedia.org/wiki/International_Bank_Account_Number#IBAN_formats_by_country}
 * @see {@link https://www.iban.com/structure}
 */
export const ibanFormats = {
  'en-EN': {mask: 'UKXX XXXX XXXX XXXX XXXX XX', placeholder: '', testRegExp: /^UK[\dX]{20}$/gi, symbols: 22},
  'de-DE': {mask: 'DEXX XXXX XXXX XXXX XXXX XX', placeholder: '', testRegExp: /^DE[\dX]{20}$/gi, symbols: 22},
  'de-AT': {mask: 'ATXX XXXX XXXX XXXX XXXX', placeholder: '', testRegExp: /^AT[\dX]{18}$/gi, symbols: 20},
  'de-CH': {mask: 'CHXX XXXX XXXX XXXX XXXX X', placeholder: '', testRegExp: /^CH[\dX]{19}$/gi, symbols: 21},
  'fr-CH': {mask: 'CHXX XXXX XXXX XXXX XXXX X', placeholder: '', testRegExp: /^CH[\dX]{19}$/gi, symbols: 21},
  'fr-BE': {mask: 'BEXX XXXX XXXX XXXX', placeholder: '', testRegExp: /^BE[\dX]{14}$/gi, symbols: 16},
  'nl-BE': {mask: 'BEXX XXXX XXXX XXXX', placeholder: '', testRegExp: /^BE[\dX]{14}$/gi, symbols: 16},
  'nl-NL': {mask: 'NLXX XXXX XXXX XXXX XX', placeholder: '', testRegExp: /^NL[\dX]{16}$/gi, symbols: 18},
  'cs-CZ': {mask: 'CZXX XXXX XXXX XXXX XXXX XXXX', placeholder: '', testRegExp: /^CZ[\dX]{22}$/gi, symbols: 24},
  'sk-SK': {mask: 'SKXX XXXX XXXX XXXX XXXX XXXX', placeholder: '', testRegExp: /^SK[\dX]{22}$/gi, symbols: 24},
  'sl-SI': {mask: 'SIXX XXXX XXXX XXXX XXX', placeholder: '', testRegExp: /^SI[\dX]{16,25}$/gi, minSymbols: 18, maxSymbols: 25},
  'ro-RO': {mask: 'ROXX XXXX XXXX XXXX XXXX XXXX', placeholder: '', testRegExp: /^RO[0-9A-Z]{22,34}$/gi, minSymbols: 24, maxSymbols: 34},
  'hu-HU': {mask: 'HUXX XXXX XXXX XXXX XXXX XXXX XXXX', placeholder: '', testRegExp: /^HU[\dX]{26}$/gi, symbols: 28},
  'hr-HR': {mask: 'HRXX XXXX XXXX XXXX XXXX X', placeholder: '', testRegExp: /^HR[\dX]{19}$/gi, symbols: 21},
  'bg-BG': {mask: 'BGXX XXXX XXXX XXXX XXXX XX', placeholder: '', testRegExp: /^BG[0-9A-Z]{20}$/gi, symbols: 22},
  'el-GR': {mask: 'GRXX XXXX XXXX XXXX XXXX XXX', placeholder: '', testRegExp: /^GR[\dX]{25}$/gi, symbols: 27},
  'pl-PL': {mask: 'PLXX XXXX XXXX XXXX XXXX XXXX XXXX', placeholder: '', testRegExp: /^PL[\dX]{26}$/gi, symbols: 28},
  'lt-LT': {mask: 'LTXX XXXX XXXX XXXX XXXX', placeholder: '', testRegExp: /^LT[\dX]{18}$/gi, symbols: 20},
  'lv-LV': {mask: 'LVXX XXXX XXXX XXXX XXXX X', placeholder: '', testRegExp: /^LV[0-9A-Z]{19}$/gi, symbols: 21},
}
