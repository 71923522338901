import {computed, useContext} from '@nuxtjs/composition-api'
import {useBankAccount} from '~/composables/useBankAccount'
import {Locale} from '~/helpers/locale'
import {COD_LOCALES} from '~/helpers/constants'

export const useIsCODEligible = () => {
  const {isEligibleForBankAccountData} = useBankAccount()
  const {$currentShop} = useContext()

  const isCodEligible = computed(() => {
    return (
      isEligibleForBankAccountData.value && COD_LOCALES.includes($currentShop.locale as Locale)
    )
  })

  return {
    isCodEligible
  }
}
