var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.loading)?_c('Skeleton',{attrs:{"type":"headline"}}):_c(_vm.tag,{tag:"component",staticClass:"flex items-center gap-2 font-semibold leading-tight",class:{
    uppercase: _vm.isUppercase,
    'text-3xl': _vm.is3Xl,
    'text-2xl': _vm.is2Xl,
    'text-xl': _vm.isXl,
    'text-lg': _vm.isLg,
    'text-base': _vm.isMd,
    'text-sm': _vm.isSm,
    'text-xs': _vm.isXs,
  }},[_vm._t("default"),_vm._v(" "),_vm._t("badge",function(){return [(_vm.badge)?_c('HeadlineBadge',{attrs:{"badge":_vm.badge}}):_vm._e()]},{"badge":_vm.badge})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }