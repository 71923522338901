import Vue from 'vue'
import VueRichTextRenderer from './rich-text-renderer-plugin'
import StoryblokImage from '~/components/cms/media/StoryblokImage'
import BannerLegalTextIcon from '~/components/cms/banners/BannerLegalTextIcon.vue'
import CmsButton from '~/components/cms/CmsButton.vue'
import GrayBox from '~/components/cms/GrayBox.vue'
import OneTrustId from '~/components/cms/OneTrustId.vue'

Vue.use(VueRichTextRenderer, {
  resolvers: {
    blocks: {
      image: StoryblokImage
    },
    components: {
      BannerLegalTextIcon,
      CmsButton,
      GrayBox,
      OneTrustId
    }
  }
})
