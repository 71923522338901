import { unref } from '@nuxtjs/composition-api'
import {FilterActions, TrackingEvent, TrackingPayload} from '../types'
import useCommonTrackingData from '../useCommonTrackingData'

const SORT_MAP: Record<string, string> = {
  'price_desc': 'price_high', // high -> low
  'price_asc': 'price_low', // low -> high
  'topseller': 'popular',
  'reduction_desc': 'highest_reduction'
}

const VIEW_MAP: Record<string, string> = {
  'model': 'model',
  'bust': 'product'
}

const useFilterEvents = (
  track: (event: TrackingEvent, payload: TrackingPayload) => any
) => {
    const commonTrackingData = useCommonTrackingData()

    return ({
      trackFilter: (action: Omit<FilterActions, 'sort'>, values: Array<string | number>) => {
        track('filter_flyout', {
          action: action as string,
          label: values.join('|'),
          ...unref(commonTrackingData)
        })
      },
      trackSort: (value: string) => {
        if (Object.keys(SORT_MAP).includes(value)) {
          track('filter_flyout', {
            action: 'sort',
            label: SORT_MAP[value],
            ...unref(commonTrackingData)
          })
        }
        else {
          console.warn('invalid sort value found ', value)
        }
      },
      trackView: (value: string) => {
        if (Object.keys(VIEW_MAP).includes(value)) {
          track('filter_flyout', {
            action: 'view',
            label: VIEW_MAP[value],
            ...unref(commonTrackingData)
          })
        }
        else {
          console.warn('invalid view value found', value)
        }
      }
    })
  }

export default useFilterEvents
