import {computed, useContext, useRoute} from '@nuxtjs/composition-api'
import {useIsPage} from '~/composables/useIsPage'

export const useIsPubliclyOpenedPage = () => {
  const {$openProducts} = useContext()
  const route = useRoute()
  const {isPDPPage} = useIsPage()
  const isOpenedProduct = computed(() => $openProducts.has(`product-is-opened-${+route.value.params.id}`))

  return {
    isPubliclyOpenedPage: computed(() => isPDPPage.value && isOpenedProduct.value)
  }
}
