
import {defineComponent, onBeforeMount, useRoute, useStore} from '@nuxtjs/composition-api'

export default defineComponent({
  layout: 'issue',
  props: ['error'],
  meta: {
    pageType: 'error'
  },
  setup() {
    const route = useRoute()
    const store = useStore()

    const setPageId = () => store.dispatch('setPageId', route?.value.params)

    onBeforeMount(() => {
      setPageId()
    })
  }
})
