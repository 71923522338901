import {useContext, useRoute, useRouter, useStore} from '@nuxtjs/composition-api'
import {useLog, useRpc, useUserFacet, useUserWithParams} from '@scayle/storefront-nuxt2'
import useTrackingEvents from "~/composables/tracking/useTrackingEvents";
import {extendRumWithUser} from "~/modules/log/loggers/datadog/datadog-rum";
import {GenderMixin, getGenderParamFromUser} from "~/helpers/user";
import {SignTracking} from "~/composables/tracking/events/useUserActionEvents";
import {MonitorEvent} from '~/@types/log'
import {useSession} from '~/composables/useSession'
import {encryptSessionAccessTokenRC4} from '~/helpers/encryption'
import {useLastLoggedInUser} from '~/composables/useLastLoggedInUser'
import cookieConsent from '~/helpers/cookieConsent'
import { useExperiment } from './optimizely/useExperiment';

type LoginSource = 'login' | 'sign_up' | 'reset_password' | 'guest_login'

interface AuthneticatedProps {
  accessToken?: string
  skipPageReload?: boolean
}

export const useAuthenticationCallback = (
  page: LoginSource = 'login',
  method: SignTracking['method'] = 'email',
  event: MonitorEvent = 'SignIn',
  pageType?: string
) => {
  const context = useContext()
  const route = useRoute()
  const router = useRouter()
  const {trackAuthenticated, trackFeature, trackLogout} = useTrackingEvents()
  const {trackExperimentEvent} = useExperiment()
  const {refresh} = useUserFacet()
  const store = useStore()
  const {user, refresh: fetchUser} = useUserWithParams({})
  const {revokeToken} = useSession()
  const log = useLog('useAuthenticationCallback')
  const {fetch: flagUserAsExisting} = useRpc('oauthFlagExisting', 'oauthFlagExisting')

  const {setLastLoggedInUser} = useLastLoggedInUser()

  const ayoUserConsent = cookieConsent('ayo-user', ['functional', '7perf'], new Map())
    .optIn(() => {
      if (user.value) {
        context.$cookies.set('ayc-user', JSON.stringify({
          gender: user.value.gender,
          isAuthenticated: true
        }), {
          path: '/',
          sameSite: 'lax',
          secure: true,
          maxAge: 60 * 60 * 24 * 30 // 30 days
        })
      }
    })

  const authenticated = async (config: AuthneticatedProps) => {
    await fetchUser()
    await refresh()

    if (!user.value) {
      return;
    }

    setLastLoggedInUser()

    // @ts-ignore
    extendRumWithUser(context)

    // context.$session.accessToken = encryptSessionAccessTokenRC4(config.accessToken, context.env.PRIVATE_ENCRYPT_SIGN_KEY)
    store.commit('SET_QUERY_PARAMS', {accessToken: config.accessToken})


    const trackingUserData: Omit<SignTracking, 'event'> = {
      customer_id: user.value.id,
      method,
      status: 'successful',
      content_name: route.value.path,
      page_type: pageType || store.getters.pageType
    }

    trackAuthenticated({
      event: page,
      ...trackingUserData
    }, user.value.email)

    const isExistingOutletUser = user.value.customData && (user.value.customData as any)?.AYO_customer_type === 'Existing'
    const firstRegShopId = user.value.customData && (user.value.customData as any)?.firstRegShopId
    const hasOutletOrders = user.value.orderSummary?.find(val => val.shopId === context.$currentShop.shopId)

    const trackAsLoginOrSignUp = isExistingOutletUser || hasOutletOrders ? 'login_v1' : 'sign_up_v1'

    trackAuthenticated({
      event: trackAsLoginOrSignUp,
      ...trackingUserData
    }, user.value.email)

    if(!isExistingOutletUser) {
      try {
        // Write to user custom-data field
        await flagUserAsExisting({firstRegShopId})
      } catch(e) {
        // silence is golden
        // There is actually nothing here we should do if this fails as it is already logged on the BE
      }
    }


    if (page === 'sign_up') {
      trackExperimentEvent({
        eventName: 'registration'
      })
    }

    context.$monitor.criticalEvent(event,{
      success: true,
      code: 200,
      message: `User has successfully ${page}`,
      email: user.value.email,
      userId: user.value.id
    })

    ayoUserConsent.invoke()

    if (config.skipPageReload) {
      return;
    }

    // user is going through regular sign in flow,
    // there's no page to be redirected to
    // user will be redirected to deals overview page
    if (route.value.path === '/signin') {
      const gender = getGenderParamFromUser(user.value as GenderMixin)
      const queryString = context.$helpers.route.stringifyQuery(
        context.$helpers.route.strapQueryDownToMarketingParams(route.value.query)
      )
      await refreshUserAndRedirect(`/deals/${gender}${queryString}`)
    }
      // user sees the sign in form on some page other than the signin
    // after the log in user should stay on same page and see the content
    else {
      await refreshUserAndRedirect(route.value.fullPath)
    }
  }

  const authenticationFailed = (email: string) => {
    trackAuthenticated({
      event: page,
      method: 'email',
      status: 'error',
      content_name: route.value.path,
      page_type: store.getters.pageType
    }, email)
  }

  const refreshUserAndRedirect = async (redirectTo: string) => {
    // We are coming from inside an AuthGuard and have to reload
    // the page in order to trigger the onFetchAsync hook
    // (it only gets triggered on the client when the URL changes)
    if (router.currentRoute.fullPath === redirectTo) {
      window.location.reload()
    }
    else {
      await router.push(redirectTo)
    }
  }

  const trackAuthenticationSubmit = (label: string) => {
    trackFeature({
      action: 'submit',
      label: context.i18n.t(label),
      status: 'successful',
      name: 'form_submit',
      content_name: route.value.path,
      page_type: store.getters.pageType
    })
  }

  const logout = async () => {
    if (user.value) {
      trackLogout(user.value.id, user.value?.email)
    }

    try {
      await context.$network.circuitBreaker('useSession - oauthRevokeToken', revokeToken({
        userId: user.value?.id
      }))
    }
    catch (error) {
      log.error(
        'LOGOUT: v1/oauth/tokens',
        {
          message: 'error when revoking an access token',
          error
        }
      )
    }
    finally {
      context.$cookies.remove('ayc-user', {
        path: '/',
        maxAge: 0
      })
      context.$cookies.remove('ayc-user', {
        path: '/deals',
        maxAge: 0
      })
      context.$cookies.remove('$session')
    }

    router.push(context.$authConfig?.redirect.logout ?? '/')
  }

  return {
    authenticated,
    authenticationFailed,
    trackAuthenticationSubmit,
    logout
  }
}
