export function indexBy<T = object, R = { [k in keyof T]: T }>(
  arr: Array<T>,
  key: keyof T
) {
  return arr.reduce((acc, item: T) => {
    return {
      ...acc,
      [`${item[key]}`]: item
    }
  }, {} as R)
}

export function groupBy<T extends any>(items: T[], getItemGroup: (item: T) => string | number) {
  return items.reduce((acc, item) => {
    const groupName = getItemGroup(item);
    const groupItems: T[] = acc[groupName] || [] as T[];

    return {
      ...acc,
      [groupName]: [...groupItems, item]
    }

  }, {} as Record<string | number, T[]>)
}

export function diff<T>(a: T[], b: T[]): T[] {
  const diffArray = []

  for(let i = 0; i < Math.max(a.length, b.length); i++) {
    if(a[i] === b[i]) {
      continue
    }

    if(i < a.length && !b.includes(a[i])) {
      diffArray.push(a[i])
    }

    if(i < b.length && !a.includes(b[i])) {
      diffArray.push(b[i])
    }
  }

  return diffArray
}


export const arraysAreEqual = (a: any[], b: string | any[]) => {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index])
  )
}

// export const last = (a: any[]) => a[a.length - 1];
