import Vue from "vue";
import {Context} from "@nuxt/types";
import {Log, LogEntry, ShopUser} from "@scayle/storefront-nuxt2";

export const getDatadogEnvironment = (shopSystem: string) => {
  switch (shopSystem) {
    case 'production':
      return 'ayou-live';
    case 'preview':
      return 'ayou-test';
    case 'staging':
      return 'ayou-prev'
    default:
      return 'local'
  }
}

export const isUserInContext = (context: Context): boolean => {
  if ('$sharedRefsMap' in (context as any)) {
    if ((context as any).$sharedRefsMap.has('useUser-data')) {
      const user: ShopUser | null = (context as any).$sharedRefsMap.get('useUser-data').value.user;

      if (user !== null) {
        return true
      }
    }
  }

  return false;
}

export const enrichErrorData = (context: Context, data: LogEntry['data']) => {
  if (!data) {
    return data;
  }

  let dataClone: object = {...data}

  if ('$sharedRefsMap' in (context as any)) {
    if ((context as any).$sharedRefsMap.has('useUser-data')) {
      const user: ShopUser | null = (context as any).$sharedRefsMap.get('useUser-data').value.user;

      if (user !== null) {
        dataClone = {
          ...dataClone,
          customerId: user.id
        }
      }
    }
  }

  return dataClone;
}

export const cleanErrorData = (data: LogEntry['data']): LogEntry['data'] => {
  if (!data) {
    return data;
  }

  const dataClone = {...data}

  if ('vm' in dataClone) {
    // @ts-ignore
    delete dataClone.vm;
  }

  return dataClone;
}

export const addClientSideErrorHandling = (log: Log) => {
  Vue.config.warnHandler = function (msg, vm, trace) {
    log.space('warnHandler').warn(msg, {vm, trace})
  }

  Vue.config.errorHandler = (error, vm, info) => {
    log
      .space('errorHandler')
      .error(error, {vm, info, component: (vm as any)?._name})
  }

  window.onunhandledrejection = (event: PromiseRejectionEvent) => {
    let message = '';

    try {
      message = event?.reason?.toJSON()
    }
    catch (e) {
      message = event.reason?.message || event
    }

    log
      .space('onunhandledrejection')
      .error('Unhandled rejection', message)
  }
}
