import { ComputedRef, computed, unref, watch } from "@nuxtjs/composition-api";
import {BasketItem, BasketWith, sharedRef, useBasketWithParams} from "@scayle/storefront-nuxt2";
import useTrackingEvents from "./tracking/useTrackingEvents";
import { BapiProduct, ExistingItemHandling, Variant } from "@aboutyou/backbone";
import { type NormalizedBasketItem } from "~/rpcMethods/helpers/normalize";

const DEFAULT_WITH_LISTING: BasketWith = {
  items: {
    product: {
      attributes: 'all' as 'all',
      advancedAttributes: 'all' as 'all',
      variants: {
        attributes: 'all' as 'all',
        advancedAttributes: 'all' as 'all',
        lowestPriorPrice: true
      },
      images: 'all' as 'all',
      categories: {
        properties: 'all' as 'all'
      },
      priceRange: true,
      lowestPriorPrice: true
    },
    variant: {
      attributes: 'all' as 'all',
      lowestPriorPrice: true
    }
  }
}

const useTrackedBasket = () => {
  const basket = useBasketWithParams({with: {...DEFAULT_WITH_LISTING} } )
  const {trackBasket, trackAddToBasket, trackRemoveFromBasket} = useTrackingEvents()


  return {
    ...basket,
    fetch: async () => {
      await basket.fetch()
      trackBasket(basket.data.value?.items ?? [])
    },
    removeItem: async (basketItem: BasketItem) => {
      await basket.removeItem({variantId: basketItem.variant.id})

      trackRemoveFromBasket(basketItem.product, basketItem.quantity, basketItem.variant)
      trackBasket(unref(basket.data)?.items || [])
    },
    updateQuantity: async (quantity: number, basketItem: BasketItem) => {
      await basket.addItem({
        variantId: basketItem.variant.id,
        quantity,
        // preserve custom data if any,
        customData: basketItem?.customData as any || {},
        existingItemHandling: ExistingItemHandling.ReplaceExisting
      })

      if (basketItem?.quantity! > quantity) {
        trackRemoveFromBasket(
          basketItem.product,
          basketItem!.quantity - quantity,
          basketItem.variant
        )
      } else {
        trackAddToBasket({
          product: basketItem.product,
          quantity: quantity - basketItem!.quantity,
          variant: basketItem.variant
        })
      }
    },
    addItem: async (product: BapiProduct | ComputedRef<BapiProduct>, variant: Variant | ComputedRef<Variant>, dealId?: string | ComputedRef<string>) => {

      const trackinAyOutletDealId = unref(dealId) ? {
        customData: {
          ayOutletDeal: unref(dealId)
        }
      } : {}

      await basket.addItem({
        variantId: unref(variant).id,
        quantity: 1,
        ...trackinAyOutletDealId
      })

      trackAddToBasket({
        product: unref(product),
        variant: unref(variant),
      })

      trackBasket(basket.data.value?.items ?? [])
    }
  }
}

export const useBasket = () => {

  const basket = useTrackedBasket()
  const ongoingFetch = sharedRef<null | ReturnType<typeof basket['fetch']>>(null, 'basketAwaitQueue')


  /**
   * Basket Fetch method that prevents multiple basket requests at the same time, shares
   * the same data accross views. One such case is when we fetch the basket from the BasketFlyout
   * and the default layout
   *
   * @see useBasketWithParams
   */
  const resolveFetch = async () => {
    if(!ongoingFetch.value) {
      ongoingFetch.value = basket.fetch().finally(() => {
        ongoingFetch.value = null // unset shared Promise
      })
    }

    return await ongoingFetch.value
  }

  const totalPriceWithTax = computed(() => {
    return unref(basket.data)?.cost.withTax
  })

  const totalBasketReducedPrice = computed(() => {
    return unref(basket.data)?.cost.appliedReductions.reduce((acc, reduction) => {
      return acc + reduction.amount.absoluteWithTax
    }, 0)
  })

  return {
    ...basket,
    fetch: resolveFetch,
    basketItems: computed(() => {
      return (unref(basket.data)?.items || []) as Array<BasketItem & NormalizedBasketItem>
    }),
    summary: {
      totalPriceWithTax,
      totalBasketReducedPrice
    },
  }
}
