
import {computed, defineComponent, PropType, ref, useContext, useRoute} from '@nuxtjs/composition-api'
import Modal from '~/components/ui/modal/Modal.vue'
import {useLastLoggedInUser} from '~/composables/useLastLoggedInUser'
import SignUpForm from '~/components/login/SignUpForm.vue'
import SignInForm from '~/components/login/SignInForm.vue'
import LastLoggedInForm from '~/components/login/LastLoggedInForm.vue'
import LoginFooter from '~/components/login/LoginFooter.vue'
import ForgottenPasswordForm from '~/components/login/ForgottenPasswordForm.vue'
import UpdatePasswordByHashForm from '~/components/login/UpdatePasswordByHashForm.vue'
import Icon from '~/components/ui/icons/Icon.vue'

export default defineComponent({
  name: 'AuthenticationModal',
  components: {
    Modal,
    SignUpForm,
    SignInForm,
    LastLoggedInForm,
    LoginFooter,
    ForgottenPasswordForm,
    UpdatePasswordByHashForm,
    Icon
  },
  props: {
    showHeadline: {
      type: Boolean as PropType<boolean>,
      default: true
    }
  },
  setup() {
    const {store} = useContext()
    const route = useRoute()
    const {lastLoggedInUser} = useLastLoggedInUser()
    const selectedForm = ref<'signin' | 'signup'>('signin')

    const showAuthenticationModal = computed(() => {
      return store.getters['auth/showLoginModal']
    });

    const hideAuthenticationModal = () => {
      store.dispatch('auth/hideLoginModal')
    }

    return {
      lastLoggedInUser,
      showForgottenPassword: computed(() => !!route.value.query['forgotten-password'] && !route.value.query.hash),
      showUpdatePasswordByHash: computed(() => !!route.value.query.hash),
      showAuthenticationModal,
      hideAuthenticationModal,
      passwordForgetCallbackLink: computed(() => route.value.path),
      selectedForm
    }
  }
})
