
import {defineComponent, computed, PropType} from '@nuxtjs/composition-api'
import Headline from '~/components/ui/headlines/Headline.vue'
import useBreakpoints from '~/composables/ui/useBreakpoints'
import {useCmsMarginsMixin} from "~/composables/cms/useCmsMarginsMixin";
import {Blok} from "~/@types/cms/blok";

export default defineComponent({
  components: {
    Headline,
  },
  props: {
    blok: {
      type: Object as PropType<Blok>,
      default: () => {}
    }
  },
  setup(props) {
    const breakpoint = useBreakpoints()
    const isMobile = computed(() => !breakpoint.lg.value)
    const {marginClasses} = useCmsMarginsMixin(props.blok)

    return {
      isMobile,
      marginClasses
    }
  },
})
