import {OptimizelyState, TrackExperimentOptions} from '~/@types/ab-tests'
import axios from 'axios'
import {GetterTree, ActionTree} from 'vuex'

export const EXPERIMENTS_SEPARATOR = '|'
export const EXPERIMENT_VARIANT_SEPARATOR = ':'

const identity = <T>(i: T) => i

type State = ReturnType<typeof state>

export const state = () =>
  ({
    experiments: {},
    activatedExperiments: []
  } as OptimizelyState)

export const mutations = {
  INIT_EXPERIMENTS: (state: State, payload: State) => {
    if(payload && payload.experiments && payload.activatedExperiments) {
      state.experiments = payload.experiments
      state.activatedExperiments = payload.activatedExperiments
    }

  },
  ACTIVATE_EXPERIMENT: (state: State, payload: string) => {
    if(payload) {
      state.activatedExperiments = [...state.activatedExperiments, payload]
    }
  }
}

export const getters: GetterTree<State, any> = {
  featureKeys: (state) => {
    return Object.values(state.experiments).map(exp => exp.flagKey)
  },
  /**
   *
   * @param {State}
   * @returns string ab_ayo:ayo-2|ab_reco:aro-c`
   * @example
   * `ab_ayo:ayo-2|ab_reco:aro-c`
   */
  concatenateExperiments: (state) => {
    return Object.values(state.experiments).reduce(
      (acc, experiment) => {
        if(!experiment.ruleKey || !state.activatedExperiments.includes(experiment.ruleKey)) {
          return acc
        }

        if (experiment.ruleKey && experiment.variationKey) {
          return [
            acc,
            `${experiment.ruleKey}${EXPERIMENT_VARIANT_SEPARATOR}${experiment.variationKey}`
          ].filter(identity).join(EXPERIMENTS_SEPARATOR)
        }

        return acc
      },
      ''
    )
  }
}


export const actions: ActionTree<State, any> = {
  activate: async (
    context,
    experimentKey: string
  ) => {
    const allowedExperiments = Object.keys(context.state.experiments)

    if(context.getters['featureKeys']?.includes(experimentKey)) {
      return null
    }

    if (!allowedExperiments.includes(experimentKey)) {
      return null
    }

    if (context.state.activatedExperiments.includes(experimentKey)) {
      return null
    }

    await axios.post('/ab/activate', {experimentKey})
    context.commit('ACTIVATE_EXPERIMENT', experimentKey)

    const {ruleKey, variationKey, flagKey} = context.state.experiments[experimentKey]

    return {
      ruleKey,
      variationKey,
      flagKey
    }
  },
  trackEvent: async (_, payload: TrackExperimentOptions) => {
    await axios.post('/ab/event', payload)
  }
}
