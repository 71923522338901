export const MARGINS = {
  none: '',
  xs: 'mt-2',
  sm: 'mt-4',
  md: 'mt-8',
  lg: 'mt-12',
  xl: 'mt-16',
  '2xl': 'mt-20',
  '3xl': 'mt-24',
  '4xl': 'mt-28'
};

export interface Blok {
  margin_top?: keyof typeof MARGINS
  is_containered?: boolean
  _uid: string
  headline: string
  product_ids?: string
}

export const STORYBLOK_CACHE_KEY = 'STORYBLOK'
