import {defineNuxtPlugin} from "@nuxtjs/composition-api";
import type {MonitorContext} from "~/@types/log";
import {logCriticalEvents} from "~/modules/log/loggers/datadog/datadog-logs";
import {setCustomAttribute, trackCustomEvent} from '~/modules/log/loggers/datadog/datadog-rum'

/**
 * This plugin assumes that both Datadog LOGS and Datadog RUM ha been initiated before.
 */
export default defineNuxtPlugin((context, inject) => {
  inject('monitor', {
    // critical events are usually tracked more often
    criticalEvent: (eventName, eventOptions) => logCriticalEvents(context, eventName, eventOptions),
    // custom events are tracked using RUM which is less often
    customAction: (eventName, eventOptions) => trackCustomEvent(context, eventName, eventOptions),
    // set custom attribute to the user tracked using RUM, so it is possible later to filter users by specific attribute name
    setCustomAttribute: (attributeName, attributeValue) => setCustomAttribute(attributeName, attributeValue)
  } as MonitorContext)
})
