import {useRpc} from '@scayle/storefront-nuxt2'

export const useSession = (key = 'useSession') => {
  const {data: loginData, fetch: login, fetching: isLoggingIn} = useRpc('oauthLogin', key)
  const {data: registerData, fetch: register, fetching: isRegistering} = useRpc('oauthRegister', key)
  // const {data: guestLoginData, fetch: guestLogin, fetching: isGuestLoggingIn} = useRpc('oauthGuestLogin', key)
  const {data: refreshTokenData, fetch: refreshToken, fetching: isRefreshingToken} = useRpc('refreshAccessToken', key)
  const {data: isTokenRevoked, fetch: revokeToken, fetching: isRevokingToken} = useRpc('oauthRevokeToken', key)
  const {data: isPasswordEmailSent, fetch: forgetPassword, fetching: isSendingForgetPasswordEmail} = useRpc('oauthForgetPassword', key)
  const {data: passwordResetLoginData, fetch: resetPasswordByHash, fetching: isResettingPasswordByHash} = useRpc('updatePasswordByHash', key)

  return {
    loginData,
    login,
    isLoggingIn,
    registerData,
    register,
    isRegistering,
    // guestLoginData,
    // guestLogin,
    // isGuestLoggingIn,
    refreshTokenData,
    refreshToken,
    isRefreshingToken,
    isTokenRevoked,
    revokeToken,
    isRevokingToken,
    isPasswordEmailSent,
    forgetPassword,
    isSendingForgetPasswordEmail,
    passwordResetLoginData,
    resetPasswordByHash,
    isResettingPasswordByHash
  }
}
