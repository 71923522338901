import {Context} from '@nuxt/types/app'
import {isNowBetween} from '~/helpers/date'
import {decryptRC4} from "~/helpers/encryption";
import {TOAST_COOKIE_NAME} from '~/helpers/constants'

function showErrorToastMessage(
  context: Context,
  error: Error,
  dealId: number | string
) {
  context.$log.error(`Error fetching deal with id ${dealId}`, error)
  const infoMessage = {
    content: {
      title: 'infoMessages.something_went_wrong'
    },
    type: 'error'
  }
  context.app.$cookies.set(TOAST_COOKIE_NAME, JSON.stringify(infoMessage))
}

const activeDealsMiddleware = async (context: Context) => {
  const dealId = context.route.params.id
  const dealCategory = context.route.params.category

  try {
    const options = {
      version: context.$config.STORYBLOK_VERSION,
      token: decryptRC4(context.$config.STORYBLOK_ACCESS_TOKEN_SECURE, context.env.PRIVATE_ENCRYPT_SIGN_KEY)
    }

    await context.$storyblok.cache.optionalFlushCache(new Date().getTime())

    const {data} = await context.$storyblok.client.get(
      `cdn/stories/deals/${dealCategory}/${dealId}`,
      options
    )

    const {startDate, endDate} = data.story.content

    const dealActive = isNowBetween(startDate, endDate)

    if (!dealActive && context.$config.isLive) {
      const infoMessage = {
        content: {
          title: 'infoMessages.deal_not_available'
        },
        type: 'info'
      }

      context.app.$cookies.set(TOAST_COOKIE_NAME, JSON.stringify(infoMessage))

      context.redirect(
        `/deals/${dealCategory}`,
        context.$helpers.route.strapQueryDownToMarketingParams(
          context.route.query
        )
      )
    }
  }
  catch (error: unknown) {
    showErrorToastMessage(context, error as Error, dealId)
    context.redirect(
      `/deals/${dealCategory}`,
      context.$helpers.route.strapQueryDownToMarketingParams(
        context.route.query
      )
    )
  }
}

export default activeDealsMiddleware
