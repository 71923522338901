
import {defineComponent, ref} from '@nuxtjs/composition-api';
import {ImageAttributes} from '~/@types/rich-text-renderer-types';
export default defineComponent({
  name: 'SbImage',
  props: {
    attrs: {
      type: Object as () => ImageAttributes,
      required: true
    }
  },
  setup({attrs}) {
    const src = ref(attrs.src);
    const alt = ref(attrs.alt);
    const title = ref(attrs.title);
    return {
      src,
      alt,
      title
    };
  }
});
