import {extend} from 'vee-validate'
import {required} from 'vee-validate/dist/rules'
import {phoneValidator} from '@scayle/storefront-nuxt2';
import {ibanFormats} from "~/helpers/maskedInput";
import {INVALID_PASSWORD_MSG, checkPassword} from '~/helpers/password';

extend('required', {
  ...required,
  message: 'myaccount.validation.required'
})

extend('phone', {
  message: 'myaccount.user.phone_error',
  validate: value => phoneValidator(value)
})

extend('iban-length', {
  message: 'myaccount.validation.iban_length',
  validate: (value, {locale}) => {
    const format = ibanFormats[locale];
    const hasExactLength = format.symbols
    const hasRangeLength = format.minSymbols && format.maxSymbols
    const nonWhiteValue = value.replace(/\s/g, '').trim();

    if(hasExactLength && nonWhiteValue.length < format.symbols) {
      return false
    }

    if(hasRangeLength) {
      return nonWhiteValue.length >= format.minSymbols && nonWhiteValue.length <= format.maxSymbols
    }

    return true
  },
  params: ['locale']
})

extend('min', {
  message: 'myaccount.validation.password_length',
  validate: (value, {length}) => !(value.replace(/\s/g, '').trim().length < +length),
  params: ['length']
})

extend('iban-format', {
  message: 'myaccount.validation.iban_format',
  validate: (value, {locale}) => {
    ibanFormats[locale].testRegExp.lastIndex = 0
    return ibanFormats[locale].testRegExp.test(value.replace(/\s/g, '').trim())
  },
  params: ['locale']
})

extend('email', {
  message: 'myaccount.validation.email',
  validate: email => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/gi.test(email)
})

extend('alpha_num_special', {
  message: INVALID_PASSWORD_MSG,
  validate: checkPassword
})
