import { CustomStoreConfig } from "../lib"
import { czPaymentProviders } from "../lib/paymentProviders"
import { domains } from "../lib/domains"
import {appKeys} from "../lib/app-keys"

const CZ: CustomStoreConfig = {
  code: 'cz',
  shopId: 5202,
  domain: domains.cz,
  appKeys,
  locale: 'cs-CZ',
  currency: 'CZK',
  currencyFractionDigits: 0,
  internationalPrefix: '420',
  paymentProviders: czPaymentProviders,
  orderPrefix: 'aocz',
  showAllProducts: false,
  allProductsHaveOwnStockAttribute: true,
  lowest30DaysPriceEnabled: true,
  showDeliveryEstimates: false,
  enableReturns: true
}

export default CZ
