import {sharedRef, useUserWithParams} from '@scayle/storefront-nuxt2'
import {onUnmounted} from '@nuxtjs/composition-api'
import {decodeBase64, encodeBase64} from '~/rpcMethods/util'
import cookieConsent from '~/helpers/cookieConsent'

export interface LastLoggedInUser {
  firstName: string
  email: string
}

const LAST_LOGGED_IN_USER_KEY = 'ay_outlet_last_logged_in_user'
export const NO_LAST_LOGGED_IN_USER: LastLoggedInUser = {
  firstName: '',
  email: ''
}
const NO_LAST_LOGGED_IN_USER_STRING: string = encodeBase64(JSON.stringify(NO_LAST_LOGGED_IN_USER))

const getLastLoggedInUser = (): LastLoggedInUser => {
  if (
    process.client &&
    typeof window !== 'undefined' &&
    'localStorage' in window &&
    window.localStorage
  ) {
    return JSON.parse(decodeBase64(window.localStorage.getItem(LAST_LOGGED_IN_USER_KEY) || NO_LAST_LOGGED_IN_USER_STRING))
  }

  return NO_LAST_LOGGED_IN_USER;
}

const setLastLoggedInUser = (user: LastLoggedInUser) => {
  if (
    process.client &&
    typeof window !== 'undefined' &&
    'localStorage' in window &&
    window.localStorage
  ) {
    return window.localStorage.setItem(LAST_LOGGED_IN_USER_KEY, encodeBase64(JSON.stringify(user)))
  }
}

const deleteLastLoggedInUser = () => {
  if (
    process.client &&
    typeof window !== 'undefined' &&
    'localStorage' in window &&
    window.localStorage
  ) {
    return window.localStorage.removeItem(LAST_LOGGED_IN_USER_KEY)
  }
}

export const useLastLoggedInUser = () => {
  const {user, isLoggedIn} = useUserWithParams({})
  const lastLoggedInUser = sharedRef(NO_LAST_LOGGED_IN_USER, LAST_LOGGED_IN_USER_KEY)
  const cookieHandlers = sharedRef(new Map(), 'cookieConsentHandlers')

  const consent = cookieConsent('lastLoggedInUser', ['functional', 'am'], cookieHandlers.value)
    .optIn(() => {
      lastLoggedInUser.value = getLastLoggedInUser()

      if (isLoggedIn.value) {
        setLastLoggedInUser({
          firstName: user.value!.firstName,
          email: user.value!.email || ''
        })
      }

    }, {alias: 'lastLoggedInConsent'})

  return {
    lastLoggedInUser,
    getLastLoggedInUser,
    setLastLoggedInUser: () => {
      consent.invoke()
    },
    removeLastLoggedInUser: () => {
      lastLoggedInUser.value = NO_LAST_LOGGED_IN_USER
      deleteLastLoggedInUser()
    }
  }
}
