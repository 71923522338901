import { render, staticRenderFns } from "./AccountPopover.vue?vue&type=template&id=0b876511&"
import script from "./AccountPopover.vue?vue&type=script&lang=ts&"
export * from "./AccountPopover.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.10.2_babel-core@7.0.0-bridge.0_cache-loader@4.1.0_css-loader@5.2.7_lodash@4.17._tghpweuumx2toccl6ohhwmtffe/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports