
import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'
import HeadlineBadge from '~/components/ui/headlines/HeadlineBadge.vue'
import Skeleton from '~/components/ui/skeletons/Skeleton.vue'

export default defineComponent({
  name: 'Headline',
  components: {
    Skeleton,
    HeadlineBadge,
  },
  props: {
    loading: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    isUppercase: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    badge: {
      type: [Number, String] as PropType<number | string>,
      default: 0,
    },
    size: {
      type: String as PropType<string>,
      default: '2xl',
      validator: (val: string) =>
        ['3xl', '2xl', 'xl', 'lg', 'md', 'sm', 'xs'].includes(val),
    },
    tag: {
      type: String as PropType<string>,
      default: 'h1',
      validator: (val: string) =>
        ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'div'].includes(val),
    },
  },
  setup(props) {
    return {
      is3Xl: computed(() => props.size === '3xl'),
      is2Xl: computed(() => props.size === '2xl'),
      isXl: computed(() => props.size === 'xl'),
      isLg: computed(() => props.size === 'lg'),
      isMd: computed(() => props.size === 'md'),
      isSm: computed(() => props.size === 'sm'),
      isXs: computed(() => props.size === 'xs'),
    }
  },
})
