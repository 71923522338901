import {sharedRef, useRpc, useUserWithParams} from '@scayle/storefront-nuxt2'
import {Ref, computed, ref, unref} from '@nuxtjs/composition-api'
import {AxiosError} from "axios";
import {
  BankAccount
} from '~/rpcMethods'

export const useBankAccount = (key = 'useBankAccount') => {
  const {user} = useUserWithParams({});
  const {data, fetch, fetching} = useRpc('getBankAccount', `${key}-${unref(user)?.id}-bankAccount`)
  const {data: updateBankAccountData, fetch: updateBankAccountRpc} = useRpc('updateBankAccountData', `${key}-${unref(user)?.id}-updateBankAccountData`)
  const {data: deleteBankAccountData, fetch: removeBankAccountRpc} = useRpc('removeBankAccount', `${key}-${unref(user)?.id}-removeBackAccount`)
  const isModificationInProgress = ref(false)
  const isEligibleForBankAccountData = sharedRef<boolean | null>(
    null,
    `is-eligible-for-bank-account-data-${unref(user)?.id}`
  )

  const modifyBankDataAfter = (asyncMethod: Promise<void>, responseData: Ref) => {
    if(isModificationInProgress.value) {
      return asyncMethod
    }

    isModificationInProgress.value = true

    asyncMethod.then(() => {
      if(responseData.value?.bankAccount) {
        data.value = responseData.value.bankAccount
      } else if(responseData.value) {
        data.value = responseData.value
      }
    }).finally(() => {
      isModificationInProgress.value = false;
    })

    return asyncMethod
  }

  const hasBankAccountData = computed(() => {
    return data.value && Object.values(data.value).every(Boolean)
  })

  const updateBankAccount = (payload: BankAccount): Promise<void> => {
    return modifyBankDataAfter(updateBankAccountRpc(payload), updateBankAccountData);
  }

  const deleteBankAccount = (): Promise<void> => {
    return modifyBankDataAfter(removeBankAccountRpc(), deleteBankAccountData);
  }

  const setEligibleForBankAccountData = (isEligible: boolean) => {
    isEligibleForBankAccountData.value = isEligible
  }

  const fetchAndUpdateEligibilityStatus = async () => {
    if (!fetching.value) {
      try {
        await fetch()
        setEligibleForBankAccountData(!!(data.value?.eligible))
      }
      catch (error) {
        if (error instanceof AxiosError) {
          // apparently user is not eligible to show bank account form
          setEligibleForBankAccountData(false)
        }
      }
    }
  }

  return {
    bankAccount: data,
    fetch,
    fetchAndUpdateEligibilityStatus,
    fetching,
    hasBankAccountData,
    updateBankAccount,
    deleteBankAccount,
    isModificationInProgress,
    isEligibleForBankAccountData,
    setEligibleForBankAccountData
  }
}
