import {Context} from "@nuxt/types";

export const Add2BasketEvent = 'Add2Basket' as const;
export const SignInEvent = 'SignIn' as const;
export const RegisterEvent = 'Register' as const;
export const SendResetPasswordEmailEvent = 'SendResetPasswordEmail' as const;
export const UpdatePasswordByHashEvent = 'UpdatePasswordByHash' as const;
export const OSPEvent = 'OSPEvent' as const;
export const DeleteUserEvent = 'DeleteUserEvent' as const;

interface AddProduct {
  success: boolean;
  code: number;
  message?: string;
  productId?: number;
  statusText?: string;
  variantId?: number;
  userId?: number;
}

interface LoggingUser {
  success: boolean
  code: number;
  message?: string;
  email?: string
  userId?: number;
}

interface OSPData extends LoggingUser {
  orderId: string
}

interface DeleteUserData extends LoggingUser {

}

export type EventOptions = {
  [Add2BasketEvent]: AddProduct;
  [SignInEvent]: LoggingUser;
  [RegisterEvent]: LoggingUser;
  [SendResetPasswordEmailEvent]: LoggingUser;
  [UpdatePasswordByHashEvent]: LoggingUser;
  [OSPEvent]: OSPData;
  [DeleteUserEvent]: DeleteUserData;
};

export type MonitorEvent = keyof EventOptions;

export type MonitorHandler = <MonitorEventOptions extends EventOptions[MonitorEvent]>(
  context: Context,
  eventName: MonitorEvent | string,
  eventOptions: MonitorEventOptions | Record<string, string>
) => void;

export interface MonitorContext {
  // critical events are usually tracked more often
  criticalEvent: <MonitorEvents extends EventOptions[MonitorEvent]>(eventName: MonitorEvent | string, eventOptions: MonitorEvents) => void,
  // custom events are tracked using RUM which is less often
  customAction: <MonitorEvents extends EventOptions[MonitorEvent]>(eventName: MonitorEvent | string, eventOptions: MonitorEvents) => void
  // set custom attribute to the user tracked using RUM, so it is possible later to filter users by specific attribute name
  setCustomAttribute: (attributeName: string, attributeValue: string | number) => void
}
