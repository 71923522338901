
import {
  computed,
  defineComponent,
  PropType,
  useContext,
  ref,
  unref
} from '@nuxtjs/composition-api'
import {ValidationProvider} from 'vee-validate'
import {mask} from 'vue-the-mask'
import Icon from '~/components/ui/icons/Icon.vue'
import {
  dateOfBirthFormats,
  ibanFormats,
  phoneNumberFormats
} from '~/helpers/maskedInput'

const getIbanLength = (format: {
  symbols: number | undefined
  minSymbols: number | undefined
  maxSymbols: number | undefined
}) => {
  if (format.symbols) {
    return format.symbols
  }

  if (format.maxSymbols && format.minSymbols) {
    return `${format.minSymbols}-${format.maxSymbols}`
  }

  return 0
}

export default defineComponent({
  name: 'FormInput',
  components: {
    Icon,
    ValidationProvider
  },
  directives: {
    // only apply mask directive if a mask is given
    // if we always apply the mask we run into errors if no mask is given
    // so we avoid duplicated inputs in the template
    mask: (el, binding, vnode, oldVnode) => {
      if (!binding.value) return
      mask(el, binding, vnode, oldVnode)
    }
  },
  props: {
    label: {
      type: String as PropType<string>,
      required: true
    },
    value: {
      type: String as PropType<string | null>,
      default: null
    },
    type: {
      type: String as PropType<string>,
      default: 'text'
    },
    readonly: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    required: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    rules: {
      type: String as PropType<string>,
      default: ''
    },
    error: {
      type: String as PropType<string>,
      default: ''
    },
    maskType: {
      type: String as PropType<string>,
      default: ''
    },
    autocomplete: {
      type: String as PropType<string>,
      default: ''
    },
    name: {
      type: String,
      default: ''
    }
  },
  setup(props, {emit}) {
    const {$currentShop} = useContext()
    // TODO: move iban validation etc in a separate component, as it forces us to import the iban formats
    const ibanLengthSymbols = getIbanLength(
      (ibanFormats as any)[$currentShop.locale]
    )

    const showPassword = ref<boolean>(false)
    const inputType = computed(() => {
      return showPassword.value ? 'text' : props.type || 'text'
    })

    const mask = computed(() => {
      let mask
      switch (props.maskType) {
        case 'iban':
          mask = ibanFormats
          break
        case 'phoneNumber':
          mask = phoneNumberFormats
          break
        case 'dateOfBirth':
          mask = dateOfBirthFormats
          break
      }

      return mask ? (mask as any)[$currentShop.locale]?.mask || mask : ''
    })

    const generatedRules = computed(() => {
      if (props.rules) {
        return props.rules
      }

      if (props.required && props.maskType) {
        if (props.maskType === 'iban') {
          return `required|iban-length:${$currentShop.locale}|iban-format:${$currentShop.locale}`
        }
      }

      if (props.required) {
        return 'required'
      }

      if (props.maskType === 'iban') {
        return `iban-length:${$currentShop.locale}|iban-format:${$currentShop.locale}`
      }

      return ''
    })

    const reset = () => {
      emit('input', '')
    }

    const errorCountSymbols = computed(() => {
      if (generatedRules.value.includes('iban-length')) {
        return unref(ibanLengthSymbols)
      }

      const matchMinLength = generatedRules.value.match(/min:(\d+)/)

      if (matchMinLength) {
        return matchMinLength[1]
      }

      return 0
    })

    return {
      reset,
      mask,
      generatedRules,
      ibanLengthSymbols,
      showPassword,
      inputType,
      errorCountSymbols,
      reEmitInput: (event: Event) => {
        emit('input', (event.target as HTMLInputElement)?.value)
      }
    }
  }
})
