
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'FadeInTransition',
  props: {
    appear: {
      type: Boolean,
      default: true,
    },
    duration: {
      type: String,
      default: 'duration-200',
    },
  },
  setup(props, context) {
    const enter = () => {
      context.emit('enter')
    }
    const afterEnter = () => {
      context.emit('after-enter')
    }
    const leave = () => {
      context.emit('leave')
    }
    const afterLeave = () => {
      context.emit('after-leave')
    }

    return {
      enter,
      afterEnter,
      leave,
      afterLeave,
    }
  },
})
