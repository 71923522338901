
import {
  computed,
  defineComponent,
  PropType,
  ref,
} from '@nuxtjs/composition-api'
import ClickOutside from 'vue-click-outside'
import Icon from '~/components/ui/icons/Icon.vue'

export default defineComponent({
  name: 'QuantityDropdown',
  directives: {
    ClickOutside,
  },
  components: { Icon },
  props: {
    availableQuantity: {
      type: Number as PropType<number>,
      default: undefined,
    },
    maxSelectItems: {
      type: Number as PropType<number>,
      default: 9,
    },
    quantity: {
      type: Number as PropType<number>,
      default: 1,
    },
  },

  setup(props) {
    const isDropdownVisible = ref(false)

    return {
      availableQuantities: computed(() =>
        props.availableQuantity < props.maxSelectItems
          ? props.availableQuantity
          : props.maxSelectItems
      ),
      isDropdownVisible,
      close: () => (isDropdownVisible.value = false),
    }
  },
})
