// converts `2022-11-16 07:00` to `2022/11/16 07:00 GTM`
export function parseDateStringFromStoryblok(
  dateString: string | number | undefined | null
): string | null {
  if (!dateString) return null
  if (typeof dateString === 'number') return new Date(dateString).toUTCString()

  const dateStringWithoutDash = `${dateString}`.replace(/-/g, '/')
  const result = dateStringWithoutDash.includes('GMT')
    ? dateStringWithoutDash
    : `${dateStringWithoutDash} GMT` // sets the timezone as UTC

  if (isValidDate(new Date(result))) {
    return result
  }

  if (isValidDate(new Date(dateStringWithoutDash))) {
    return dateStringWithoutDash
  }

  return null
}

export const isValidDate = (date: Date) => {
  return `${date}` !== 'Invalid Date'
}

export function isNowBetween(starDate?: string, endDate?: string) {
  const now = new Date()

  const parsedDateStart = parseDateStringFromStoryblok(starDate)
  const parsedDateEnd = parseDateStringFromStoryblok(endDate)

  // Ensures no false values
  if (!parsedDateStart || !parsedDateEnd) {
    return false
  }

  const start = new Date(parsedDateStart)
  const end = new Date(parsedDateEnd)

  return isValidDate(start) && isValidDate(end) && start <= now && now < end
}

export const getUTCNow = () => {
  return toStoryblokDate()
}

export const toStoryblokDate = (d: Date = new Date()) => {
  const year = d.getUTCFullYear()
  const month = `${d.getUTCMonth() + 1}`.padStart(2, '0')
  const date = `${d.getUTCDate()}`.padStart(2, '0')
  const hours = `${d.getUTCHours()}`.padStart(2, '0')
  const minutes = `${d.getUTCMinutes()}`.padStart(2, '0')

  return `${year}-${month}-${date} ${hours}:${minutes}`
}

export const DOPT_DEFAULT: Parameters<typeof Intl.DateTimeFormat>[1] = {
  hour: "2-digit",
  minute: "2-digit",
  year: 'numeric',
  month: '2-digit',
  day: '2-digit'
};

// DOPT = DATE_OPTIONS, but short
export const DOPT_NOTIME: Parameters<typeof Intl.DateTimeFormat>[1] = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit'
};

export const DOPT_NOTIME_NOYEAR: Parameters<typeof Intl.DateTimeFormat>[1] = {
  month: '2-digit',
  day: '2-digit'
};

export const formatDateTime = (dateString: Date | string | undefined | null, locale = 'de-DE', dateOptions = DOPT_DEFAULT) => {

  if(!dateString) {
    return;
  }

  const dateToFormat = typeof dateString === 'string' ? new Date(dateString) : dateString;

  if(locale === 'hu-HU') {
    const [year, month, date = '', time = ''] =  Intl.DateTimeFormat(locale, dateOptions)
    .format(dateToFormat)
    // format "2023. 07. 27. 6.30" into "2023.07.27 6:30"
    .split('. ')

    if(time) {
      return `${year}.${month}.${date} ${time}`
    }

    return `${year}.${month}.${date}`.replace(/(\.+)$/, '')

  }

  return Intl.DateTimeFormat(locale, dateOptions)
    .format(dateToFormat)
    .replace(',', '')
    .replace(/\.$/, '')
}

export default {
  formatDateTime
}
